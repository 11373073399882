import articleimage from '../../../../assets/Artwork/arttwo.png';
import { AiOutlineHeart } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { GET_LIKED_ARTICLES } from '../../../../constants';
import { Link } from 'react-router-dom';

export const MostLikedArticle = () => {
  const [likedArticles, setLikedArticles] = useState<likedArticlesType[]>([]);
  function getLikedArticles() {
    axios({
      method: 'POST',
      url: GET_LIKED_ARTICLES,
    }).then(({ data }) => {
      setLikedArticles(data.data);
    });
  }
  useEffect(() => {
    getLikedArticles();
  }, []);
  return (
    <div className="kpi-cards">
      {likedArticles.length > 0 ? (
        likedArticles.map((item: likedArticlesType) => (
          <Link to={{ pathname: `/article/${item.articleId}` }}>
            <div className="d-flex align-items-sm-center my-7">
              <div className="artwork-image-Wrapper">
                <img src={item.imageUrl} />
              </div>
              <div className="d-flex flex-row-fluid flex-wrap align-items-center">
                <div className="flex-grow-1 me-2 box">
                  <span className="fs-6  d-block text-muted article-desc">
                    {item.articleTitle}
                  </span>
                  {/* <span className="fw-bolder fs-6 my-1 d-block">{item.auther}</span> */}
                </div>
                <div className="d-flex flex-row-fluid flex-wrap align-items-center justify-content-end gap-3">
                  <AiOutlineHeart className="fs-2x text-muted" />
                  <div>
                    <span className=" fs-4 fw-bold m-0 p-0 ">
                      {item.bookmarkedCount}
                    </span>
                    <p className=" fs-8 m-0 p-0">Likes</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <h6>NO DATA</h6>
        </div>
      )}
    </div>
  );
};
type likedArticlesType = {
  articleId: number;
  imageUrl: string;
  articleTitle: string;
  bookmarkedCount: number;
};
