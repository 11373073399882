import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import './DropDown.scss';

export const DropDown = ({ DropdownItem, title, id }: DropDownProps) => {
  return (
    <DropdownButton className="drop-down-sec" variant="" title={title}>
      {DropdownItem.map((item, index) => {
        if (item.onClickFn) {
          return (
            <Dropdown.Item className="card-icons">
              <div
                className="d-flex align-items-center gap-3"
                onClick={() => {
                  item.onClickFn && item.onClickFn(id);
                }}
              >
                {item.iconss}
                <p className="mb-0 text-dark">{item.value}</p>
              </div>
            </Dropdown.Item>
          );
        } else {
          return (
            <Dropdown.Item className="card-icons">
              <Link
                to={{ pathname: item.linkto + `/${id}`, state: { id: id } }}
                className="text-dark"
              >
                <div className="d-flex align-items-center gap-3">
                  {item.iconss}
                  <p className="mb-0 text-dark">{item.value}</p>
                </div>
              </Link>
            </Dropdown.Item>
          );
        }
      })}
    </DropdownButton>
  );
};
interface DropDownProps {
  DropdownItem: {
    value: string;
    iconss: any;
    linkto: string;
    onClickFn: Function | null;
  }[];
  title: any;
  id: number | null;
}
