import { useLocation, Link } from 'react-router-dom';
import { breadcrumbType } from '../../MasterLayout';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export function MenuInner({ breadcrumbData, setBreadcrumbData }: propType) {
  const location = useLocation();
  return (
    <>
      <ol className="breadcrumb text-muted fs-6 fw-bold">
        {breadcrumbData.map((item: breadcrumbType) => {
          if (item.to) {
            return (
              <li className="breadcrumb-item  ">
                <Link to={item.to}>
                  <h6 className="fw-bolder mb-0 fs-5">{item.title}</h6>
                </Link>
              </li>
            );
          } else {
            return (
              <li className="breadcrumb-item  ">
                <h6 className="fw-bolder mb-0 fs-5">{item.title}</h6>
              </li>
            );
          }
        })}
        {/* <li className="breadcrumb-item  ">
          <h6 className="fw-bolder mb-0 fs-5">
            {location.pathname.replace('/', '').toUpperCase()}
          </h6>
        </li>
        <li className="breadcrumb-item  text-muted">
          <a>Artwork Details</a>
        </li>
        <li className="breadcrumb-item  text-muted ">Current</li> */}
      </ol>
    </>
  );
}
