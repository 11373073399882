export const ZIP_FILE_EXTENSIONS: string = '.zip, .tz';
export const EXCEL_FILE_EXTENSIONS: string = '.xlsx';

//URLs
// const BASE_URL: string = 'https://localhost:55144/api';
// const BASE_URL: string = 'http://10.0.1.242:8088/api';
const BASE_URL: string = 'https://api.artvisor.com/api';

export const LOGIN_REQUEST_URL = BASE_URL + '/admin/login';

// --------------------------------------DASHBOARD------------------------------------------------------
export const GET_ARTWORK_SALES_COUNT =
  BASE_URL + '/dashboard/admin/get-artwork-sales-count';
export const GET_ARTWORK_STATUS_COUNT =
  BASE_URL + '/dashboard/admin/get-artwork-status-count';
export const GET_CLIENTS_COUNT = BASE_URL + '/dashboard/admin/get-client-count';
export const GET_LIKED_ARTWORKS =
  BASE_URL + '/dashboard/admin/get-most-liked-artworks';
export const GET_LIKED_ARTICLES =
  BASE_URL + '/dashboard/admin/get-most-liked-articles';
export const GET_LIKED_ARTISTS =
  BASE_URL + '/dashboard/admin/get-most-liked-artists';
export const GET_RECENT_INQUIRIES =
  BASE_URL + '/dashboard/admin/get-new-inquires';
export const GET_RECENT_RESERVATIONS =
  BASE_URL + '/dashboard/admin/get-new-reservations';
export const GET_CLIENTS = BASE_URL + '/dashboard/admin/get-clients';
// --------------------------------------USERS/CLIENTS--------------------------------------------------
export const GET_ALL_USERS_URL = BASE_URL + '/users/get-all';
export const GET_USER_FILTERS_URL = BASE_URL + '/users/admin/get-filters';
export const GET_USER_DETAILS_URL = BASE_URL + '/admin/get-user-detail/';
export const GET_USER_INFO_URL = BASE_URL + '/users/get-user';
export const GET_ALL_ADMIN_USERS_URL = BASE_URL + '/users/admin/get-all-admin';
export const DELETE_ADMIN_USER_URL = BASE_URL + '/user/delete/';
export const DEACTIVATE_CLIENT_URL = BASE_URL + '/user/admin/deactivate/';
export const GET_ADMIN_USER_DETAILS_URL =
  BASE_URL + '/admin/get-admin-user-detail/';
export const ADD_ADMIN_USER_DATA_URL = BASE_URL + '/users/admin/add-admin-user';
export const UPDATE_ADMIN_USER_DATA_URL =
  BASE_URL + '/users/admin/update-admin-user';
export const GET_CLIENT_EDIT_DETAILS_URL = BASE_URL + '/admin/get-user-detail/';
export const ADD_CLIENTS_DATA_URL =
  BASE_URL + '/users/admin/update-client-user';
export const GET_USER_SURVEY_URL = BASE_URL + '/admin/survey/get-survey';
export const UPDATE_USER_SURVEY_URL =
  BASE_URL + '/client/survey/save-all-questions';
export const APPROVE_CLIENT_URL = BASE_URL + '/admin/approve-client/';
// --------------------------------------ARTWORKS-------------------------------------------------------
export const GET_ALL_ARTWORKS_URL = BASE_URL + '/artwork/admin/get-artwork';
export const GET_ARTWORK_FILTERS_URL = BASE_URL + '/artwork/admin/get-filters';
export const ADD_ARTWORK_DATA_URL = BASE_URL + '/artwork/admin/add-update';
export const GET_ALL_ARTWORK_STYLES = BASE_URL + '/artwork/admin/get-styles';
export const UPLOAD_ARTWORK_IMAGE = BASE_URL + '/file/upload-image';
export const UPLOAD_ARTWORK_COMPRESSED_IMAGE = BASE_URL + '/file/upload-artwork-image';
export const EXCEL_FILE_UPLOAD_URL = BASE_URL + '/file/upload-artwork-excel';
export const ZIP_FILE_UPLOAD_URL = BASE_URL + '/file/upload-artwork-images';
export const GET_ALL_ARTWORK_CATEGORIES =
  BASE_URL + '/artwork/admin/get-categories';
export const DEACTIVATE_ARTWORK_URL =
  BASE_URL + '/artwork/Admin/inactive-artwork';
export const DELETE_ARTWORK_URL = BASE_URL + '/artwork/Admin/delete/';
export const GET_ARTWORK_DETAILS_FOR_UPDATE =
  BASE_URL + '/artwork/admin/get-artwork-detail-for-update/';
export const GET_ARTWORK_DETAILS_URL =
  BASE_URL + '/artwork/admin/get-artwork-detail/';
export const GET_ARTWORK_RESERVATION_DETAILS =
  BASE_URL + '/artwork/admin/get-reserved-artwork-detail';
export const GET_ARTWORK_RESERVATION_WAITLIST =
  BASE_URL + '/artwork/admin/get-reserved-artwork-wait-list';
export const UPDATE_ARTWORK_QUEUE_ORDER =
  BASE_URL + '/artwork/admin/reserved-artwork-wait-list-update';
export const MARK_ARTWORK_AS_SOLD =
  BASE_URL + '/artwork/admin/reserved-artwork-sold';
export const WITHDRAW_ARTWORK_RESERVATION =
  BASE_URL + '/artwork/admin/reserved-artwork-cancel';
export const GET_ARTWORK_INQUIRY_DETAILS =
  BASE_URL + '/artwork/admin/get-inquiry-artwork-detail';
// --------------------------------------ARTISTS--------------------------------------------------------
export const GET_ALL_ARTISTS_URL = BASE_URL + '/artist/admin/get-all';
export const GET_ARTIST_DETAILS_URL = BASE_URL + '/artist/admin/get-detail/';
export const ADD_ARTIST_DATA_URL = BASE_URL + '/artist/admin/add-update';
export const DEACTIVATE_ARTIST_URL = BASE_URL + '/artist/admin/inactive-artist';
export const DELETE_ARTIST_URL = BASE_URL + '/artist/admin/delete/';
export const GET_ALL_ARTISTS_INFO_URL =
  BASE_URL + '/artist/admin/get-all-artist-only';
// --------------------------------------ARTICLES-------------------------------------------------------
export const GET_ALL_ARTICLES_URL = BASE_URL + '/article/admin/get-all';
export const ADD_ARTICLE_DATA_URL = BASE_URL + '/article/admin/add';
export const GET_ARTICLE_DETAILS_URL = BASE_URL + '/article/admin/get-detail/';
export const UPDATE_ARTICLE_URL = BASE_URL + '/article/admin/update';
export const GET_ARTICLE_CATEGORIES_URL = BASE_URL + '/article/get-categories';
export const GET_ALL_ARTICLE_CATEGORIES =
  BASE_URL + '/article/admin/get-categories';
export const DEACTIVATE_ARTICLE_URL =
  BASE_URL + '/article/admin/inactive-article';
export const GET_ARTICLE_DETAILS_FOR_UPDATE_URL =
  BASE_URL + '/article/admin/get-for-update/';
export const UPLOAD_ARTICLE_DESCRIPTION_IMAGES_URL =
  BASE_URL + '/file/upload-image';
// --------------------------------------CATEGORIES/STYLES----------------------------------------------
export const DELETE_ARTWORK_CATEGORY =
  BASE_URL + '/artwork/admin/delete-category/';
export const DELETE_ARTWORK_STYLE = BASE_URL + '/artwork/admin/delete-style/';
export const DELETE_ARTICLE_CATEGORY =
  BASE_URL + '/article/admin/delete-category/';
export const ADD_ARTWORK_CATEGORY =
  BASE_URL + '/artwork/admin/add-update-category';
export const ADD_ARTWORK_STYLE = BASE_URL + '/artwork/admin/add-update-style';
export const ADD_ARTICLE_CATEGORY =
  BASE_URL + '/article/admin/add-update-category';
// --------------------------------------RESERVATION/INQUIRY--------------------------------------------
export const GET_RESERVED_ARTWORKS =
  BASE_URL + '/artwork/admin/get-reserved-artworks';
export const GET_INQUIRY_ARTWORKS =
  BASE_URL + '/artwork/admin/get-inquiry-artworks';
export const MARK_INQUIRY_AS_RESOLVED =
  BASE_URL + '/artwork/admin/update-status-inquiry-artwork';
// --------------------------------------SUGGESTIONS----------------------------------------------------
export const MANUAL_SUGGEST_ARTWORKS_URL =
  BASE_URL + '/suggestion/admin/add-manual-suggested-artworks';
export const GET_MANUAL_SUGGESTION_ARTWORKS =
  BASE_URL + '/suggestion/admin/get-artworks-for-manual-suggestion';
export const GET_ALL_ROLES_URL = BASE_URL + '/artist/admin/get-all-artist-only';

export const priceUnits = [
  {
    value: 'USD',
  },
  {
    value: 'EUR',
  },
  {
    value: 'GBP',
  },
];
export const dimensionUnits = [
  {
    value: 'in',
  },
  {
    value: 'cm',
  },
];
