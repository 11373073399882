import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_ALL_ARTICLES_URL,
  GET_ALL_ARTISTS_INFO_URL,
  GET_ALL_ARTISTS_URL,
  GET_ALL_ARTWORKS_URL,
  GET_ARTWORK_FILTERS_URL,
  GET_USER_DETAILS_URL,
} from '../../../../constants';
import { ClientDetailsData } from './ClientDetailsData';
import { artwork } from '../../artworks/utils';
import { artistType } from '../../Artist/ArtistDetails/ArtistDetails';
import { articleType } from '../../Journals/journal-listing/Journals';
import { ArtistList } from '../../Artist/ArtistListing/ArtistList';
import { ArtworksList } from '../../artworks/ArtworksList';
import {
  artworkFilterType,
  filterData,
  selectedArtworkFilterType,
  sizeType,
} from '../../../components/filters/ArtworkFilters';
import JournalsList from '../../Journals/journal-listing/JournalsList';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';
import { ClientSurvey } from '../Survey/ClientSurvey';
import { SuggestArt } from '../../../../_metronic/partials/content/SuggestArtModal/SuggestArt';

type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ClientsDetails = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const [listView, setListView] = useState(false);
  const { dispatch } = useUser();
  const { id }: any = useParams();
  const [clientData, setClientData] = useState<clientType>();
  const [activeTab, setActiveTab] = useState('suggestArtworks');
  const [isLoading, setIsLoading] = useState(false);
  const [followedArtistData, setFollowedArtistData] = useState<artistType>();
  const [artistTotalRecords, setArtistTotalRecords] = useState(0);
  const [artistCurrentRecords, setArtistCurrentRecords] = useState(0);
  const [likedArtWorksData, setLikedArtWorksData] = useState<artwork[]>([]);
  const [likedTotalRecords, setLikedTotalRecords] = useState(0);
  const [likedCurrentRecords, setLikedCurrentRecords] = useState(0);
  const [suggestedArtWorksData, setSuggestedArtWorksData] = useState<artwork[]>(
    []
  );
  const [suggestedTotalRecords, setSuggestedTotalRecords] = useState(0);
  const [suggestedCurrentRecords, setSuggestedCurrentRecords] = useState(0);
  const [bookmarkedJournalsData, setBookmarkedJournalsData] = useState<
    articleType[]
  >([]);
  const [bookmarkedTotalRecords, setBookmarkedTotalRecords] = useState(0);
  const [bookmarkedCurrentRecords, setBookmarkedCurrentRecords] = useState(0);

  const [artistPageState, setArtistPageState] = useState({
    filters: {
      text: '',
      followUserId: id,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [likedArtworkPageState, setLikedArtworkPageState] = useState({
    filters: {
      text: '',
      categoryIds: [],
      artists: [],
      styleIds: [],
      status: [],
      price: [0, 125000],
      size: [],
      year: [1940, 2020],
      likeArtworkUserId: parseInt(id),
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [suggestedArtworkPageState, setSuggestedArtworkPageState] = useState({
    filters: {
      text: '',
      artists: [],
      categoryIds: [],
      styleIds: [],
      status: [],
      price: [0, 125000],
      size: [],
      year: [1940, 2020],
      suggestedUserId: parseInt(id),
    },
    pageInfo: {
      pageSize: 100,
      pageNumber: 1,
    },
  });
  const [journalsPageState, setJournalsPageState] = useState({
    filters: {
      text: '',
      bookmarkUserId: id,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    }, //,
  });
  const [artWorkFilters, setArtWorkFilters] = useState<artworkFilterType>({
    categories: [],
    styles: [],
    artists: [],
    status: [],
    price: [],
    size: [],
  });
  const [selectedArtWorkFilters, setSelectedArtWorkFilters] =
    useState<selectedArtworkFilterType>({
      categories: [],
      styles: [],
      status: [],
      artists: [],
      price: [],
      size: [],
      year: [],
    });
  function updateArtworkFilters(key: string, value: any) {
    console.log([...value]);
    setSelectedArtWorkFilters({ ...selectedArtWorkFilters, [key]: [...value] });
  }
  function getClientData() {
    axios({
      method: 'POST',
      url: GET_USER_DETAILS_URL + id,
    }).then(({ data }) => {
      if (data) {
        setClientData(data.data);
      }
    });
  }
  async function getAllFilters() {
    let filters: any = await axios({
      method: 'POST',
      url: GET_ARTWORK_FILTERS_URL,
    });
    let filtersData = {
      categories: filters.data.data.category.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      styles: filters.data.data.style.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      status: filters.data.data.status.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      price: filters.data.data.price.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      size: filters.data.data.size.map((item: sizeType) => {
        return {
          label: item.value.key + ' (' + item.value.value + ')',
          value: item.key,
        };
      }),
    };
    // });
    filters = await axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_INFO_URL,
    });

    let artists = filters.data.data.map((item: any) => {
      return { label: item.name, value: item.id };
      // }),
    });
    // console.log(filtersData, artists);
    setArtWorkFilters({
      categories: filtersData.categories,
      price: filtersData.price,
      size: filtersData.size,
      status: filtersData.status,
      styles: filtersData.styles,
      artists: artists,
    });
  }
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Clients', to: '/clients' },
      { title: 'Details', to: null },
    ]);
    getAllFilters();
    getClientData();
  }, []);
  useEffect(() => {
    if (activeTab === 'bookmarkedJournals') {
      axios({
        method: 'POST',
        url: GET_ALL_ARTICLES_URL,
        data: journalsPageState,
      }).then(({ data }) => {
        setBookmarkedJournalsData(data.data);
        setBookmarkedTotalRecords(data.totalRecords);
        setBookmarkedCurrentRecords(data.data?.length);
      });
    }
  }, [activeTab, journalsPageState]);
  useEffect(() => {
    if (activeTab === 'followedArtists') {
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_ARTISTS_URL,
        data: artistPageState,
      }).then(({ data }) => {
        if (data) {
          setFollowedArtistData(data.data);
          setArtistTotalRecords(data.totalRecords);
          setArtistCurrentRecords(data.data.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, artistPageState]);
  useEffect(() => {
    if (activeTab === 'likedArtworks') {
      setIsLoading(true);
      let data = {
        filters: {
          artistId: likedArtworkPageState.filters.artists,
          text: likedArtworkPageState.filters.text,
          categoryIds: likedArtworkPageState.filters.categoryIds,
          styleIds: likedArtworkPageState.filters.styleIds,
          status: likedArtworkPageState.filters.status,
          size: likedArtworkPageState.filters.size,
          likeArtworkUserId: parseInt(id),
          startPrice:
            likedArtworkPageState.filters.price[0] == 0
              ? -1
              : likedArtworkPageState.filters.price[0] == 125000
              ? -2
              : likedArtworkPageState.filters.price[0],
          endPrice:
            likedArtworkPageState.filters.price[1] &&
            likedArtworkPageState.filters.price[1] === 125000
              ? -1
              : likedArtworkPageState.filters.price[1] === 0
              ? -2
              : likedArtworkPageState.filters.price[1],
          startYear:
            likedArtworkPageState.filters.year[0] &&
            likedArtworkPageState.filters.year[0] <= 1950
              ? -1
              : likedArtworkPageState.filters.year[0] >= 2020
              ? -2
              : likedArtworkPageState.filters.year[0],
          endYear:
            likedArtworkPageState.filters.year[1] &&
            likedArtworkPageState.filters.year[1] == 2020
              ? -1
              : likedArtworkPageState.filters.year[1] <= 1950
              ? -2
              : likedArtworkPageState.filters.year[1],
        },
        pageInfo: {
          pageSize: likedArtworkPageState.pageInfo.pageSize,
          pageNumber: likedArtworkPageState.pageInfo.pageNumber,
        }, //,
      };
      axios({
        method: 'POST',
        url: GET_ALL_ARTWORKS_URL,
        data: data,
      }).then(({ data }) => {
        if (data) {
          setLikedArtWorksData(
            data.data?.map((item: any) => {
              return {
                ...item,
                key: item.id,
              };
            })
          );
          setLikedTotalRecords(data.totalRecords);
          setLikedCurrentRecords(data.data?.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, likedArtworkPageState]);
  useEffect(() => {
    if (activeTab === 'suggestedArtworks') {
      setIsLoading(true);
      let data = {
        filters: {
          artistId: suggestedArtworkPageState.filters.artists,
          text: suggestedArtworkPageState.filters.text,
          categoryIds: suggestedArtworkPageState.filters.categoryIds,
          styleIds: suggestedArtworkPageState.filters.styleIds,
          status: suggestedArtworkPageState.filters.status,
          size: suggestedArtworkPageState.filters.size,
          suggestedUserId: parseInt(id),
          startPrice:
            suggestedArtworkPageState.filters.price[0] == 0
              ? -1
              : suggestedArtworkPageState.filters.price[0] == 125000
              ? -2
              : suggestedArtworkPageState.filters.price[0],
          endPrice:
            suggestedArtworkPageState.filters.price[1] &&
            suggestedArtworkPageState.filters.price[1] === 125000
              ? -1
              : suggestedArtworkPageState.filters.price[1] === 0
              ? -2
              : suggestedArtworkPageState.filters.price[1],
          startYear:
            suggestedArtworkPageState.filters.year[0] &&
            suggestedArtworkPageState.filters.year[0] <= 1950
              ? -1
              : suggestedArtworkPageState.filters.year[0] >= 2020
              ? -2
              : suggestedArtworkPageState.filters.year[0],
          endYear:
            suggestedArtworkPageState.filters.year[1] &&
            suggestedArtworkPageState.filters.year[1] == 2020
              ? -1
              : suggestedArtworkPageState.filters.year[1] <= 1950
              ? -2
              : suggestedArtworkPageState.filters.year[1],
        },
        pageInfo: {
          pageSize: suggestedArtworkPageState.pageInfo.pageSize,
          pageNumber: suggestedArtworkPageState.pageInfo.pageNumber,
        }, //,
      };
      axios({
        method: 'POST',
        url: GET_ALL_ARTWORKS_URL,
        data: data,
      }).then(({ data }) => {
        if (data) {
          setSuggestedArtWorksData(
            data.data?.map((item: any) => {
              return {
                ...item,
                key: item.id,
              };
            })
          );
          setSuggestedTotalRecords(data.totalRecords);
          setSuggestedCurrentRecords(data.data?.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, suggestedArtworkPageState]);
  return (
    <React.Fragment>
      <div className="row">
        <div className="card mb-xl-10">
          <div className="card-body">
            <ClientDetailsData
              clientData={clientData}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              getClientData={getClientData}
            />
            <div className="stats-tables">
              {activeTab === 'followedArtists' && (
                <ArtistList
                  currentRecords={artistCurrentRecords}
                  userData={followedArtistData}
                  setIsLoading={setIsLoading}
                  totalRecords={artistTotalRecords}
                  pageState={artistPageState}
                  setPageState={setArtistPageState}
                  getArtists={undefined}
                />
              )}
              {activeTab === 'likedArtworks' && (
                <ArtworksList
                  selectedArtWorkFilters={selectedArtWorkFilters}
                  updateArtworkFilters={updateArtworkFilters}
                  artWorkFilters={artWorkFilters}
                  currentRecords={likedCurrentRecords}
                  artWorksData={likedArtWorksData}
                  setIsLoading={setIsLoading}
                  totalRecords={likedTotalRecords}
                  pageState={likedArtworkPageState}
                  setPageState={setLikedArtworkPageState}
                  getAllArtworks={undefined}
                  listView={listView}
                  setListView={setListView}
                />
              )}
              {activeTab === 'suggestedArtworks' && (
                <ArtworksList
                  selectedArtWorkFilters={selectedArtWorkFilters}
                  updateArtworkFilters={updateArtworkFilters}
                  artWorkFilters={artWorkFilters}
                  currentRecords={suggestedCurrentRecords}
                  artWorksData={suggestedArtWorksData}
                  setIsLoading={setIsLoading}
                  totalRecords={suggestedTotalRecords}
                  pageState={suggestedArtworkPageState}
                  setPageState={setSuggestedArtworkPageState}
                  getAllArtworks={undefined}
                  listView={listView}
                  setListView={setListView}
                />
              )}
              {activeTab === 'bookmarkedJournals' && (
                <JournalsList
                  articlesData={bookmarkedJournalsData}
                  pageState={journalsPageState}
                  setPageState={setJournalsPageState}
                  setIsLoading={setIsLoading}
                  totalRecords={bookmarkedTotalRecords}
                  currentRecords={bookmarkedCurrentRecords}
                  getAllArticles={undefined}
                />
              )}
              {activeTab === 'surveyresponse' && (
                <ClientSurvey
                  userId={clientData?.id || 0}
                  getData={getClientData}
                />
              )}
              {activeTab === 'suggestArtworks' && (
                <SuggestArt
                  userId={clientData?.id || 0}
                  getData={getClientData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export type clientType = {
  bookmarkedJournalCount: number;
  city: string;
  country: string;
  createdDate: string;
  email: string;
  followArtistCount: number;
  fullName: string;
  id: number;
  imageUrl: string;
  lastDeactivatedDate: string;
  likedCount: number;
  phoneNumber: string;
  state: string;
  status: string;
  suggestedArtworkCount: number;
};
