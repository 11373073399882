import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import articleimage from '../../../../assets/Artwork/user.png';
import { GET_RECENT_INQUIRIES } from '../../../../constants';

export const RecentInquiries = () => {
  const [inquiries, setInquiries] = useState<inquiriesType[]>([]);
  function getInquiries() {
    axios({
      method: 'POST',
      url: GET_RECENT_INQUIRIES,
    }).then(({ data }) => {
      setInquiries(data.data);
    });
  }
  useEffect(() => {
    getInquiries();
  }, []);

  return (
    <>
      {inquiries?.length > 0 ? (
        inquiries.map((item) => (
          <Link
            to={{
              pathname: `/inquirydetails/${item.artworkId}/${item.clientId}`,
            }}
          >
            <div className="d-flex align-items-center my-7">
              <div className="symbol symbol-50px me-5">
                <img src={item.clientImageUrl} className="" alt="" />
              </div>
              <div className="flex-grow-1">
                <span className="text-dark fw-bolder text-hover-primary fs-6">
                  {item.clientName}
                </span>
                <span className="text-muted d-block fw-bold">
                  {item.description}
                </span>
              </div>
              <span className=" fs-8 text-muted fw-bold">
                {item.inquiryDate}
              </span>
            </div>
          </Link>
        ))
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <h6>NO DATA</h6>
        </div>
      )}
    </>
  );
};
type inquiriesType = {
  clientId: number;
  artworkId: number;
  clientImageUrl: string;
  clientName: string;
  description: string;
  inquiryDate: string;
};
