import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces';


export const SelectDropdown = ({
  title,
  options,
  filters,
  setFilters,
}: DropDownPropTypes) => {
  return (
   
      <div className="select-two">
        <h6 className="filter--title">{title}</h6>
        <MultiSelect
          options={options}
          value={filters}
          onChange={(items: any) => {
            setFilters(title.toLowerCase(), items);
          }}
          labelledBy="Select"
          className=""
          disableSearch={true}
        />
      </div>
   
  );
};
interface DropDownPropTypes {
  filters: Option[];
  setFilters: Function;
  options: Array<any>;
  title: string;
}
