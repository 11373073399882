import { useEffect, useState } from 'react';
import ListTable from '../../../components/listing-table';
import { rowData, columns } from './reservation-data';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';
import axios from 'axios';
import { GET_RESERVED_ARTWORKS } from '../../../../constants';
import { Pagination } from '../../../../_metronic/partials/content/pagination/Pagination';
import { Spinner } from 'react-bootstrap-v5';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ArtworkReservationListing = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { dispatch } = useUser();
  const [reservationData, setReservationData] = useState<reservationType[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [pageState, setPageState] = useState({
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    }, //,
  });
  useEffect(() => {
    setBreadcrumbData([{ title: 'Reservations', to: null }]);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_RESERVED_ARTWORKS,
      data: pageState,
    })
      .then(({ data }) => {
        if (data) {
          setReservationData(data.data);
          setTotalRecords(data.totalRecords);
          setCurrentRecords(data.data?.length);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
          setIsLoading(false);
        }
      });
  }, [pageState]);
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <div className="card">
        <div className="card-header border-1 bg-white mb-5 align-items-center">
          <div className="card-title card-title align-items-start flex-column">
            <h3 className="fw-bolder m-0">Artwork Reservation</h3>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <ListTable columns={columns} userData={rowData(reservationData)} />
          </div>
        </div>
        <Pagination
          currentRecords={currentRecords}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          pageSize={pageState.pageInfo.pageSize}
          pageNumber={pageState.pageInfo.pageNumber}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
        />
      </div>
    );
  }
};
export type reservationType = {
  id: number;
  title: string;
  artistName: string;
  queueNumber: number;
  isHold: boolean;
  userId: number;
  clientName: string;
  clientEmail: string;
  reservationDate: string;
  imageInfo: {
    height: number;
    width: number;
    imagePath: string;
    ratio: number;
  };
};
