import { FiCheck } from 'react-icons/fi';
export const SurveyArt = ({
  artimage,
  selected,
  id,
  deSelectItem,
  setSelectedArtworks,
  categoryName,
  styleName
}: ArtworksPropType) => {
  return (
    <div
      className={`artcard ${selected ? 'selected ' : ''}`}
      onClick={() => {
        selected ? deSelectItem(id) : setSelectedArtworks(id);
      }}
    >
      <div className="card-body">
        <div className="ArtimageWrapper">
          <img src={artimage} alt="artwork" />
        </div>
        <div className="cardContent">
          {(categoryName != null && categoryName != '') && <p>{"Category: " + categoryName}</p>}
          <br />
          {(styleName != null && styleName != '') && <p>{"Style: " + styleName}</p>}

          <span className="art-action">
            <FiCheck />
          </span>
        </div>
      </div>
    </div>
  );
};
interface ArtworksPropType {
  artimage: any;
  selected: boolean;
  id: any;
  setSelectedArtworks: Function;
  deSelectItem: Function;
  categoryName: string;
  styleName: string;
}
