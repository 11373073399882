import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { BadgeStatus } from '../../../../_metronic/partials/content/badges/BadgeStatus';
export const rowData = (userData: UserData[]): any => {
  const data =
    userData.length > 0 &&
    userData?.map((item: UserData) => ({
      image: (
        <Link
          to={{
            pathname: `/inquirydetails/${item.artworkId}/${item.userId}`,
          }}
        >
          <div className="kpi-cards ">
            <div className="artwork-image-Wrapper">
              <img src={item.imageInfo.imagePath} alt="img" />
            </div>
          </div>
        </Link>
      ),
      artist: (
        <div className="d-flex align-items-center cursor-pointer">
          <div className="d-flex justify-content-start flex-column">
            <span className="fw-bold d-block fs-7">{item.artistName}</span>
          </div>
        </div>
      ),
      title: (
        // <Link
        //   to={{
        //     pathname: '/inquirydetails',
        //     state: { artworkId: item.artworkId, userId: item.userId },
        //   }}
        // >
        <div className="d-flex align-items-center cursor-pointer">
          <div className="d-flex justify-content-start flex-column">
            <a className="table-data ">
              <span className="fw-bold d-block fs-7">{item.title}</span>
            </a>
          </div>
        </div>
        // </Link>
      ),
      email: item.clientEmail,
      clientname: item.clientName,
      status: (
        <BadgeStatus
          status={item.inquiryStatusId === 1 ? 'unresolved' : 'resolved'}
        />
      ),
      timestamp: new Date(item.inquiryDate).toDateString(),
    }));
  return data;
};
type UserData = {
  artworkId: number;
  title: string;
  artistName: string;
  userId: number;
  clientName: string;
  clientEmail: string;
  inquiryDate: string;
  inquiryStatusId: number;
  description: null;
  imageInfo: {
    height: number;
    width: number;
    imagePath: string;
    ratio: number;
  };
};
type UserColumn = {
  image: any;
  artist: any;
  title: any;
  email: any;
  timestamp: any;
  clientname: any;
  status: any;
};
export const columns: Column<UserColumn>[] = [
  {
    Header: 'Artwork Image',
    accessor: 'image',
  },
  {
    Header: 'Artwork Artist',
    accessor: 'artist',
  },
  {
    Header: 'Artwork Title',
    accessor: 'title',
  },
  {
    Header: 'Client Name',
    accessor: 'clientname',
  },
  {
    Header: 'Client Email',
    accessor: 'email',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Inquiry Date',
    accessor: 'timestamp',
  },
];
