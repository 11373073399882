import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UPLOAD_ARTICLE_DESCRIPTION_IMAGES_URL } from '../../../constants';
import axios from 'axios';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default function MyEditor({ handleChange, data, ...props }: any) {
  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file: any) => {
            body.append('formFile', file);
            axios({
              method: 'POST',
              url: UPLOAD_ARTICLE_DESCRIPTION_IMAGES_URL,
              data: body,
            }).then(({ data }) => {
              resolve({
                default: data.data.url,
              });
            });
          });
        });
      },
    };
  }
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (
      loader: any
    ) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="App border border-dark">
      <CKEditor
        editor={DecoupledEditor}
        onReady={(editor: any) => {
          editor.ui
            .getEditableElement()
            .parentElement.prepend(editor.ui.view.toolbar.element);
        }}
        onChange={(event: any, editor: { getData: () => any }) => {
          handleChange(editor.getData());
        }}
        data={data}
        config={{
          extraPlugins: [uploadPlugin],
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'alignment',
            'numberedList',
            'imageUpload',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
        }}
        {...props}
      />
    </div>
  );
}
