import React, { useEffect, useState } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { ArtButton } from '../../../_metronic/partials/content/Button/ArtButton';
import { FormLabel } from '../../../_metronic/partials/content/Label/FormLabel';
import { useParams } from 'react-router-dom';
import { useUser } from '../../modules/auth/User-context';
import axios from 'axios';
import {
  ADD_ADMIN_USER_DATA_URL,
  GET_ADMIN_USER_DETAILS_URL,
  GET_ALL_ROLES_URL,
  UPDATE_ADMIN_USER_DATA_URL,
  UPLOAD_ARTWORK_IMAGE,
} from '../../../constants';
import { Alert } from 'react-bootstrap';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const EditUser = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const { state, dispatch } = useUser();
  const { id }: any = useParams();
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const initialValues = {
    phoneNumber: {
      value: '',
      isError: false,
      errorMessage: 'Phone Number is missing',
    },
    id: { value: 0, isError: false, errorMessage: 'ID is missing' },
    imageUrl: { value: '', isError: false, errorMessage: 'Image is missing' },
    name: { value: '', isError: true, errorMessage: 'Name is missing' },
    email: { value: '', isError: true, errorMessage: 'Email is missing' },
    password: { value: '', isError: true, errorMessage: 'Password is missing' },
    cnfrmpwd: { value: '', isError: true, errorMessage: 'Password is missing' },
  };
  const [userData, setUserData] =
    useState<{ [name: string]: any }>(initialValues);
  const [path, setPath] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);
  const [roles, setRoles] = useState([{ label: '', value: '' }]);
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Users', to: '/users' },
      { title: id ? 'Edit' : 'Add', to: null },
    ]);
    if (id) {
      axios({
        method: 'GET',
        url: GET_ADMIN_USER_DETAILS_URL + id,
      }).then(({ data }) => {
        // setArtistData(data.data);
        if (data.isSuccess) {
          const { id, imageUrl, fullName, email, phoneNumber, password } =
            data.data;
          setUserData({
            ...userData,
            email: {
              ...userData.email,
              value: email,
              isError: false,
            },
            id: { ...userData.id, value: id, isError: false },
            imageUrl: { ...userData.imageUrl, value: imageUrl, isError: false },
            name: { ...userData.name, value: fullName, isError: false },
            phoneNumber: {
              ...userData.phoneNumber,
              value: phoneNumber,
              isError: false,
            },
            password: { ...userData.password, value: password, isError: false },
            cnfrmpwd: { ...userData.cnfrmpwd, value: password, isError: false },
          });
          setPath(data.data?.imageUrl || '');
        } else {
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: data.message,
          });
          setShowAlert(true);
        }
      });
    }
    axios({
      method: 'POST',
      url: GET_ALL_ROLES_URL,
    }).then(({ data }) => {
      setRoles(
        data.data.map((item: any) => {
          return { label: item.name, value: item.id };
        })
      );
    });
  }, []);
  function updateRoles(selected: any) {
    setUserData({ ...userData, role: selected });
  }
  function uploadImage(e: any) {
    const formData = new FormData();
    formData.append('formFile', e.target.files?.item(0) as Blob);
    const data = e.target.files?.item(0) as Blob;
    let a = URL.createObjectURL(data);
    setPath(a);
    axios({
      method: 'POST',
      url: UPLOAD_ARTWORK_IMAGE,
      data: formData,
    })
      .then(({ data }) => {
        setUserData({
          ...userData,
          imageUrl: {
            ...userData.imageUrl,
            value: data.data.url,
            isError: false,
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  function updateInputs(key: any, value: any) {
    setUserData({
      ...userData,
      [key]: { ...userData[key], value: value, isError: false },
    });
  }
  function saveChanges(e: any) {
    let errorFound = false;
    for (const key in userData) {
      if (userData[key].isError && !errorFound) {
        setAlertData({
          variant: 'danger',
          heading: 'Fields Missing',
          message: userData[key].errorMessage,
        });
        errorFound = true;
        setShowAlert(true);
      }
    }
    if (!errorFound) {
      const addData = {
        fullName: userData.name.value,
        email: userData.email.value,
        imageUrl: userData.imageUrl.value,
        id: userData.id.value ? userData.id.value : 0,
        role: 'admin', //userData.role.value,
        phoneNumber: userData.phoneNumber.value,
        password: userData.password.value,
        confirmPassword: userData.cnfrmpwd.value,
        status: 1,
      };
      const updateData = {
        fullName: userData.name.value,
        email: userData.email.value,
        imageUrl: userData.imageUrl.value,
        id: userData.id.value ? userData.id.value : 0,
        role: 'admin', //userData.role.value,
        phoneNumber: userData.phoneNumber.value,
        newPassword: userData.password.value,
        oldPassword: userData.cnfrmpwd.value,
        status: 1,
      };
      axios({
        method: 'POST',
        url: id ? UPDATE_ADMIN_USER_DATA_URL : ADD_ADMIN_USER_DATA_URL,
        data: id ? updateData : addData,
      })
        .then(({ data }) => {
          if (data.isSuccess) {
            setAlertData({
              variant: 'success',
              message: 'User Details have been saved successfully',
              heading: 'Success',
            });
            setShowAlert(true);
            if (id && id == state.id) {
              dispatch({
                type: 'add-user-data',
                payload: {
                  ...state,
                  ...updateData,
                  status: updateData.status != 0,
                },
              });
            }
            if (!id) {
              setUserData(initialValues);
            }
          } else {
            setAlertData({
              variant: 'danger',
              message: data.message
                .split('. ')
                .map((err: string) => <p>{err}</p>),
              heading: 'Failed',
            });
            setShowAlert(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            localStorage.clear();
            dispatch({ type: 'remove-user' });
          }
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: error.message,
          });
          setShowAlert(true);
        });
    }
  }
  // }
  return (
    <React.Fragment>
      <div className="card">
        {showAlert && (
          <Alert
            variant={alertData.variant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>{alertData.heading}</Alert.Heading>
            <p>{alertData.message}</p>
          </Alert>
        )}
        <div className="card-header border-1 bg-white align-items-center ">
          <div className="card-title  align-items-start flex-column">
            <h3 className="fw-bolder m-0">User Details</h3>
          </div>
          <div className="d-flex flex-wrap gap-3">
            <ArtButton
              buttonText={id ? 'Save Changes' : 'Add User'}
              styleclass={''}
              variant={'dark'}
              icon={undefined}
              hasIcon={false}
              onClick={saveChanges}
            />
          </div>
        </div>
        <form className="form ">
          <div className="card-body border-top p-9 mb-32">
            <h4 className="my-5">User Profile</h4>
            <div className="imageUploaderWrapper mb-5">
              <div className="circle">
                <img src={path} alt="" />
              </div>
              <label className="pImage">
                <AiOutlineCamera className="uploadButton" />
                <input
                  className="fileUpload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </label>
            </div>
            <div className="row mb-6">
              <div className="col-lg-4 fv-row">
                <FormLabel label={'Name'} />
                <input
                  type="text"
                  value={userData.name.value}
                  className="form-control form-control-lg "
                  placeholder="Name"
                  onChange={(e) => {
                    updateInputs('name', e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4 fv-row">
                <FormLabel label={'Email'} />
                <input
                  type="text"
                  value={userData.email.value}
                  className="form-control form-control-lg "
                  placeholder="Email"
                  onChange={(e) => {
                    updateInputs('email', e.target.value);
                  }}
                  readOnly={id ? true : false}
                />
              </div>
              <div className="col-lg-4 fv-row">
                <FormLabel label={' Phone Number'} />
                <input
                  type="text"
                  value={userData.phoneNumber.value}
                  className="form-control form-control-lg "
                  placeholder="Phone Number"
                  onChange={(e) => {
                    updateInputs('phoneNumber', e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4 fv-row">
                <FormLabel label={'New Password'} />
                <input
                  type="password"
                  className="form-control form-control-lg "
                  placeholder="Password"
                  onChange={(e) => {
                    updateInputs('password', e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4 fv-row">
                <FormLabel label={id ? 'Old Password' : 'Confirm Password'} />
                <input
                  type="password"
                  className="form-control form-control-lg "
                  placeholder={id ? 'Old Password' : 'Confirm Password'}
                  onChange={(e) => {
                    updateInputs('cnfrmpwd', e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
