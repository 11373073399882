import React, { useEffect, useState } from 'react';
import { useLayout } from '../../../_metronic/layout/core';
import clsx from 'clsx';
import axios from 'axios';
import {
  GET_ALL_ARTISTS_INFO_URL,
  GET_ALL_ARTWORKS_URL,
  GET_ARTWORK_FILTERS_URL,
} from '../../../constants';
import { artwork } from './utils';
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces';
import { Spinner } from 'react-bootstrap-v5';
import { ArtworksList } from './ArtworksList';
import {
  artworkFilterType,
  filterData,
  selectedArtworkFilterType,
  sizeType,
} from '../../components/filters/ArtworkFilters';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { useUser } from '../../modules/auth/User-context';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const Artworks = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const { dispatch } = useUser();
  const [listView, setListView] = useState(false);
  const [artWorksData, setArtWorksData] = useState<artwork[]>([]);
  const { classes } = useLayout();
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);

  const [pageState, setPageState] = useState<artworkPageStateType>({
    filters: {
      artists: [],
      text: '',
      categoryIds: [],
      styleIds: [],
      status: [],
      price: [0, 125000],
      size: [],
      year: [1940, 2020],
    },
    pageInfo: {
      pageSize: 100,
      pageNumber: 1,
    }, //,
  });
  const [artWorkFilters, setArtWorkFilters] = useState<artworkFilterType>({
    categories: [],
    artists: [],
    styles: [],
    status: [],
    price: [],
    size: [],
  });
  const [selectedArtWorkFilters, setSelectedArtWorkFilters] =
    useState<selectedArtworkFilterType>({
      categories: [],
      styles: [],
      artists: [],
      status: [],
      price: [],
      size: [],
      year: [],
    });
  function updateArtworkFilters(key: string, value: any) {
    setSelectedArtWorkFilters({ ...selectedArtWorkFilters, [key]: [...value] });
  }
  useEffect(() => {
    setBreadcrumbData([{ title: 'Artworks', to: null }]);
    getAllFilters();
  }, []);
  async function getAllFilters() {
    let filters: any = await axios({
      method: 'POST',
      url: GET_ARTWORK_FILTERS_URL,
    });
    let filtersData = {
      categories: filters.data.data.category.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      styles: filters.data.data.style.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      status: filters.data.data.status.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      price: filters.data.data.price.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      size: filters.data.data.size.map((item: sizeType) => {
        return {
          label: item.value.key + ' (' + item.value.value + ')',
          value: item.key,
        };
      }),
    };
    // });
    filters = await axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_INFO_URL,
    });

    let artists = filters.data.data.map((item: any) => {
      return { label: item.name, value: item.id };
      // }),
    });
    // console.log(filtersData, artists);
    setArtWorkFilters({
      categories: filtersData.categories,
      price: filtersData.price,
      size: filtersData.size,
      status: filtersData.status,
      styles: filtersData.styles,
      artists: artists,
    });
  }
  function getAllArtworks() {
    console.log(pageState.filters.price[0] == 0);
    let data = {
      filters: {
        text: pageState.filters.text,
        artistId: pageState.filters.artists,
        categoryIds: pageState.filters.categoryIds,
        styleIds: pageState.filters.styleIds,
        status: pageState.filters.status,
        size: pageState.filters.size,
        startPrice:
          pageState.filters.price[0] == 0
            ? -1
            : pageState.filters.price[0] == 125000
            ? -2
            : pageState.filters.price[0],
        endPrice:
          pageState.filters.price[1] && pageState.filters.price[1] === 125000
            ? -1
            : pageState.filters.price[1] === 0
            ? -2
            : pageState.filters.price[1],
        startYear:
          pageState.filters.year[0] && pageState.filters.year[0] <= 1950
            ? -1
            : pageState.filters.year[0] >= 2020
            ? -2
            : pageState.filters.year[0],
        endYear:
          pageState.filters.year[1] && pageState.filters.year[1] == 2020
            ? -1
            : pageState.filters.year[1] <= 1950
            ? -2
            : pageState.filters.year[1],
      },
      pageInfo: {
        pageSize: pageState.pageInfo.pageSize,
        pageNumber: pageState.pageInfo.pageNumber,
      }, //,
    };
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_ALL_ARTWORKS_URL,
      data: data,
    }).then(({ data }) => {
      if (data) {
        setArtWorksData(
          data.data?.map((item: any) => {
            return {
              ...item,
              key: item.id,
            };
          })
        );
        setTotalRecords(data.totalRecords);
        setCurrentRecords(data.data?.length);
      }
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getAllArtworks();
  }, [pageState]);
  // console.log(artWorkFilters);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <>
        <div className="toolbar" id="kt_toolbar">
          <div
            id="kt_toolbar_container"
            className={clsx(
              classes.toolbarContainer.join(' '),
              'd-flex justify-content-end'
            )}
          >
            <div className="d-flex align-items-center py-1 gap-2">
              <div className="me-4"></div>
            </div>
          </div>
        </div>
        <ArtworksList
          selectedArtWorkFilters={selectedArtWorkFilters}
          artWorkFilters={artWorkFilters}
          updateArtworkFilters={updateArtworkFilters}
          artWorksData={artWorksData}
          pageState={pageState}
          setPageState={setPageState}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          currentRecords={currentRecords}
          getAllArtworks={getAllArtworks}
          listView={listView}
          setListView={setListView}
        />
      </>
    );
  }
};
type Props = {
  className: string;
};
export type handleArtworkFiltersProps = {
  artists: Option[];
  categories: Option[];
  styles: Option[];
  status: Option[];
  price: number[];
  year: number[];
  size: Option[];
};
export type artworkPageStateType = {
  filters: {
    artists: any[];
    text: string;
    categoryIds: number[];
    styleIds: number[];
    status: string[];
    price: any[];
    year: any[];
    size: number[];
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
};
