import { FiUploadCloud } from 'react-icons/fi';
import '../../../assets/sass/core/components/artuploader/artuploader.scss';
import Dropzone, {
  defaultClassNames,
  IDropzoneProps,
  ILayoutProps,
} from 'react-dropzone-uploader';
import axios from 'axios';
import React from 'react';
const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles = 1 },
}: ILayoutProps) => {
  return (
    <React.Fragment>
      <div {...dropzoneProps}>
        {previews}
        {files.length < maxFiles && input}
      </div>
      {files.length > 0 && submitButton}
    </React.Fragment>
  );
};

export const ArtUploader = ({
  title,
  description,
  fileType,
  uploadUrl,
  setErrorState,
  setErrorMessage,
  setIsLoading,
  setSuccessMessage,
}: ArtUploader) => {
  const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    const formData = new FormData();
    formData.append('formFile', files[0].file);
    setIsLoading(true);
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: uploadUrl,
      data: formData,
    })
      .then(({ data }) => {
        if (!data.isSuccess) {
          setErrorMessage(data.message);
          setErrorState(undefined);
        } else if (data.data && data.data.length > 0) {
          setErrorState(data.data);
          setErrorMessage(undefined);
          setSuccessMessage(data.message);
        } else {
          setSuccessMessage(data.message);
          setErrorMessage(undefined);
          setErrorState(undefined);
        }
        setIsLoading(false);
      })
      .catch(({ error }) => {
        setErrorMessage(error);
        setErrorState(undefined);
        setIsLoading(false);
      });
    // allFiles.forEach((f) => f.remove());
  };
  return (
    <>
      {/* <Toolbar /> */}

      <Dropzone
        multiple={false}
        maxFiles={1}
        // getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        onSubmit={handleSubmit}
        classNames={{
          inputLabelWithFiles: defaultClassNames.inputLabel,
        }}
        accept={fileType}
        inputContent={
          <div className="text-center">
            <FiUploadCloud className="upload-icon" />
            <p>
              <strong>{title}</strong> <span>{description}</span>
            </p>
          </div>
        }
      />
    </>
  );
};
interface ArtUploader {
  title: string;
  description: string;
  fileType: string;
  uploadUrl: string;
  setErrorState: Function;
  setErrorMessage: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}
