export const FormSelect = ({
  options,
  updateFilter,
  selected,
}: FormSelectProps) => {
  return (
    <div>
      <select
        placeholder="Select..."
        onChange={(e) => {
          updateFilter(e.target.value);
        }}
        className="form-select  form-select-lg fw-bold"
        required
      >
        <option value="" hidden selected>
          Select...
        </option>
        {options.map((item, index) => {
          return item.value == selected ? (
            <option value={item.value} selected>
              {item.label}
            </option>
          ) : (
            <option value={item.value}>{item.label}</option>
          );
        })}
      </select>
    </div>
  );
};

interface FormSelectProps {
  options: { value: string; label: string }[];
  updateFilter: Function;
  selected: string;
}
