import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { artwork } from './utils';
import { BadgeStatus } from '../../../_metronic/partials/content/badges/BadgeStatus';
export const rowData = (
  artworksData: artwork[],
  makeArtworkInactive: Function,
  location: string
): any => {
  return artworksData?.map((item: artwork) => {
    return {      
      artName: (
        <Link to={{ pathname: `/artwork/${item.id}` }}>
          <div className="d-flex align-items-center">
            <div className="client-Avatar">
              <img src={item.imageUrl} alt="img" />
            </div>
            <div className="d-flex justify-content-start">
              <span className="fw-bold text-dark d-block fs-7">
                {item.name}{' '}
              </span>
            </div>
          </div>
        </Link>
      ),
      artist: item.artist.name,
      year: item.year,
      price: item.price,
      status: <BadgeStatus status={item.availability} />,
      action:
        location === '/artworks' ? (
          <div className="d-flex align-items-center gap-3">
            <Link to={{ pathname: `/editartworks/${item.id}` }}>
              <FiEdit className="icons-del" />
            </Link>{' '}
            <FiTrash2
              className="icons-del"
              onClick={() => {
                makeArtworkInactive(item.id);
              }}
            />
          </div>
        ) : (
          <></>
        ),
    };
  });
};

export const columnsWithAction: Column<ArtworksColumn>[] = [
  {
    Header: 'Artwork',
    accessor: 'artName',
    maxWidth: 20,
    width:10
  },
  {
    Header: 'Artist',
    accessor: 'artist',
    width:10
  },
  {
    Header: 'Year',
    accessor: 'year',
    width:10
  },
  {
    Header: 'Price',
    accessor: 'price',
    width:10
  },
  {
    Header: 'Status',
    accessor: 'status',
    width:10
  },
  {
    Header: 'Action',
    accessor: 'action',
    width:10
  },
];
export const columnsWithoutAction: Column<ArtworksColumn>[] = [
  {
    Header: 'Artwork',
    accessor: 'artName',
    maxWidth: 20,
    width:10
  },
  {
    Header: 'Artist',
    accessor: 'artist',
  },
  {
    Header: 'Year',
    accessor: 'year',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

type ArtworksColumn = {
  artName: JSX.Element;
  artist: string;
  year: any;
  price: string;
  status: any;
  action: any;
};
