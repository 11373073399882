import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { Logout, AuthPage } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { useUser } from '../modules/auth/User-context';

const Routes: FC = () => {
  const { state } = useUser();
  const isAuthorized = !!(state.isLogin || localStorage.getItem('token'));
  return (
    <Switch>
      {!isAuthorized ? (
        <AuthPage />
      ) : (
        <>
          <MasterLayout />
          {/* <PrivateRoutes />
          </MasterLayout> */}
        </>
      )}
      <Redirect from="/auth" to="/" />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
    </Switch>
  );
};

export { Routes };
