import axios from 'axios';
import { useEffect, useState } from 'react';
import { BiCommentAdd } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import articleimage from '../../../../assets/Artwork/artist.png';
import { GET_LIKED_ARTISTS, GET_LIKED_ARTWORKS } from '../../../../constants';
export const MostLikedArtist = () => {
  const [likedArtists, setLikedArtists] = useState<likedArtistsType[]>([]);
  function getLikedArtworks() {
    axios({
      method: 'POST',
      url: GET_LIKED_ARTISTS,
    }).then(({ data }) => {
      setLikedArtists(data.data);
    });
  }
  useEffect(() => {
    getLikedArtworks();
  }, []);
  return (
    <div className="kpi-cards">
      {likedArtists.length > 0 ? (
        likedArtists.map((item) => (
          <Link to={{ pathname: `/artist/${item.artistId}` }}>
            <div className="d-flex align-items-sm-center my-4">
              <div className="symbol symbol-60px me-5">
                <img src={item.imageUrl} />
              </div>
              <div className="d-flex flex-row-fluid flex-wrap align-items-center">
                <div className="flex-grow-1 me-2 box">
                  <span
                    // href="#"
                    className="text-gray-800 fw-bolder text-hover-primary fs-6"
                  >
                    {item.artistName}
                  </span>
                </div>
                <div className="d-flex flex-row-fluid flex-wrap align-items-center justify-content-end gap-3">
                  <BiCommentAdd className="fs-2x text-muted" />
                  <div>
                    <span className=" fs-4 fw-bold m-0 p-0 ">
                      {item.followedCount}
                    </span>
                    <p className=" fs-8 m-0 p-0">Followers</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <h6>NO DATA</h6>
        </div>
      )}
    </div>
  );
};
type likedArtistsType = {
  artistId: number;
  imageUrl: string;
  artistName: string;
  followedCount: number;
};
