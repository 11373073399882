import Carousel from 'react-multi-carousel';
import { SurveyArt } from './SurveyArt';
type propType = {
  preferencesData: any;
  setPreferencesData: Function;
  deSelectPreferedArtworks: Function;
};
export const Preferences = ({
  preferencesData,
  setPreferencesData,
  deSelectPreferedArtworks,
}: propType) => {
  const responsiveForMultiple = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1921 },
      items: 6,
    },
    largeDesktop: {
      breakpoint: { max: 1920, min: 1681 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1680, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="ArtCardWrapper mb-32 border-1">
      <h2 className="fs-2 fw-bold me-1">{preferencesData.question}</h2>
      <p className="ms-1">{preferencesData.questionDescription}</p>
      <div className="select-artwork-container">
        {/* <Carousel
          responsive={responsiveForMultiple}
          draggable={true}
          autoPlay={false}
          swipeable={true}
        >
          {preferencesData.surveyOptions.map((item: any) => (
            <div className="artworkIntCards">
              <SurveyArt
                deSelectItem={deSelectPreferedArtworks}
                setSelectedArtworks={setPreferencesData}
                artimage={item.imageUrl}
                selected={item.isSelected}
                id={item.optionId}
              />
            </div>
          ))}
        </Carousel> */}


        {preferencesData.surveyOptions.map((item: any) => (
          // <div className='col-md-2'>
            <div className="artworkIntCards">
              <SurveyArt
                deSelectItem={deSelectPreferedArtworks}
                setSelectedArtworks={setPreferencesData}
                artimage={item.imageUrl}
                selected={item.isSelected}
                id={item.optionId}
                categoryName={item.categoryName}
                styleName={item.styleName}
              />
            </div>
          // </div>
        ))}

      </div>
    </div>
  );
};
