import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { GET_ARTWORK_INQUIRY_DETAILS } from '../../../../constants';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';
import { ClientDetailsCard } from '../../Clients/ClientDetailsCard';
import { ArtworkDetailCrad } from '../ArtworkDetailCrad';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ArtInquiryDetails = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { artworkId, userId }: any = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const { dispatch } = useUser();
  const [reservationData, setReservationData] = useState<inquiryDetailsType>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Inquiries', to: '/inquirylisting' },
      { title: 'Details', to: null },
    ]);
    axios({
      method: 'POST',
      url: GET_ARTWORK_INQUIRY_DETAILS,
      data: { artworkId, userId },
    }).then(({ data }) => {
      setReservationData(data.data);
    });
  }, []);
  return (
    <React.Fragment>
      <div className="row">
        {showAlert && (
          <Alert
            variant={alertData.variant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>{alertData.heading}</Alert.Heading>
            <p>{alertData.message}</p>
          </Alert>
        )}
        <div className="col-md-4">
          <ArtworkDetailCrad
            artistName={reservationData?.artistName || ''}
            artworkTitle={reservationData?.title || ''}
            imageUrl={reservationData?.artworkImageUrl || ''}
            price={reservationData?.price || ''}
            medium={reservationData?.medium || ''}
            style={reservationData?.style || []}
            category={reservationData?.category || []}
          />
        </div>
        <div className="col-md-8">
          <ClientDetailsCard
            imageUrl={reservationData?.clientImageUrl || ''}
            clientName={reservationData?.clientName || ''}
            clientEmail={reservationData?.clientEmail || ''}
            reservationTime={new Date(
              reservationData?.inquiryDate || ''
            ).toDateString()}
            artworkId={reservationData?.artworkId}
            userId={reservationData?.userId}
            setAlertData={setAlertData}
            setShowAlert={setShowAlert}
            inquiryStatusId={reservationData?.inquiryStatusId}
          />
          <div className="card">
            <div className="card-header border-1 bg-white mb-5 align-items-center">
              <div className="card-title card-title align-items-start flex-column">
                <h3 className="fw-bolder m-0">Inquiry Text/Body</h3>
              </div>
            </div>
            <div className="card-body py-3">{reservationData?.description}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
type inquiryDetailsType = {
  artworkId: number;
  artworkImageUrl: string;
  title: string;
  artistName: string;
  price: string;
  medium: string;
  category: string[];
  style: string[];
  inquiryDate: string;
  description: string;
  inquiryStatusId: number;
  userId: number;
  clientName: string;
  clientEmail: string;
  clientImageUrl: string;
};
