import React, { useEffect, useState } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { MdShowChart } from 'react-icons/md';
import { MostLikedArticle } from './KpiDashboard/MostLikedArticle';
import { MostLikedArtworks } from './KpiDashboard/MostLikedArtworks';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { MostLikedArtist } from './KpiDashboard/MostLikedArtist';
import { Tab, Tabs } from 'react-bootstrap';
import { ClientActivities } from './KpiDashboard/ClientActivities';
import { RecentReservations } from './KpiDashboard/RecentReservations';
import { RecentInquiries } from './KpiDashboard/RecentInquiries';
import { BiImageAdd } from 'react-icons/bi';
import { Stats } from '../../../_metronic/partials/content/StatsCounter/Stats';
import axios from 'axios';
import {
  GET_ARTWORK_SALES_COUNT,
  GET_ARTWORK_STATUS_COUNT,
  GET_CLIENTS_COUNT,
} from '../../../constants';

type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const Dashboardmain = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const [salesData, setSalesData] = useState<salesDataType>({
    lastMonthSale: 0,
    totalSales: 0,
  });
  const [artworksCount, setArtworksCount] = useState<artworksCountType>({
    avaiableCount: {
      lastMonthCount: 0,
      totalCount: 0,
    },
    reserveCount: {
      lastMonthCount: 0,
      totalCount: 0,
    },
    soldCount: {
      lastMonthCount: 0,
      totalCount: 0,
    },
    inquiryCount: {
      lastMonthCount: 0,
      totalCount: 0,
    },
  });
  const [clientsCount, setClientsCount] = useState<clientsCountType>({
    newCustomer: 0,
    totalCustomer: 0,
  });
  useEffect(() => {
    setBreadcrumbData([{ title: 'Dashboard', to: null }]);
  }, []);
  function getArtworkSalesCount() {
    axios({
      method: 'POST',
      url: GET_ARTWORK_SALES_COUNT,
    }).then(({ data }) => {
      setSalesData(data.data);
    });
  }

  function getArtworkStatusCount() {
    axios({
      method: 'POST',
      url: GET_ARTWORK_STATUS_COUNT,
    }).then(({ data }) => {
      setArtworksCount(data.data);
    });
  }
  function getClientsCount() {
    axios({
      method: 'POST',
      url: GET_CLIENTS_COUNT,
    }).then(({ data }) => {
      setClientsCount(data.data);
    });
  }
  useEffect(() => {
    getArtworkSalesCount();
    getArtworkStatusCount();
    getClientsCount();
  }, []);
  return (
    <React.Fragment>
      <div className="stats-grid">
        <div className="item-stats">
          <Stats
            icon={<MdShowChart />}
            title={'Total sales'}
            count={salesData.totalSales.toString()}
            current={salesData.lastMonthSale}
            monthly={'From Last Month'}
            styleclass={'totalsales'}
          />
        </div>
        <div className="item-stats">
          <Stats
            icon={<BiImageAdd />}
            title={'Artworks Available'}
            count={artworksCount.avaiableCount.totalCount.toString()}
            current={artworksCount.avaiableCount.lastMonthCount}
            monthly={'From Last Month'}
            styleclass={undefined}
          />
        </div>
        <div className="item-stats">
          <Stats
            icon={<BiImageAdd />}
            title={'Artworks Sold'}
            count={artworksCount.soldCount.totalCount.toString()}
            current={artworksCount.soldCount.lastMonthCount}
            monthly={'From Last Month'}
            styleclass={undefined}
          />
        </div>
        <div className="item-stats">
          <Stats
            icon={<BiImageAdd />}
            title={'Artworks Reserved'}
            count={artworksCount.reserveCount.totalCount.toString()}
            current={artworksCount.reserveCount.lastMonthCount}
            monthly={'From Last Month'}
            styleclass={undefined}
          />
        </div>
        <div className="item-stats">
          <Stats
            icon={<BiImageAdd />}
            title={'On inquiry'}
            count={artworksCount.inquiryCount.totalCount.toString()}
            current={artworksCount.soldCount.lastMonthCount}
            monthly={'From Last Month'}
            styleclass={undefined}
          />
        </div>
        <div className="item-stats">
          <Stats
            icon={<AiOutlineUsergroupAdd />}
            title={'Total Customers'}
            count={clientsCount.totalCustomer.toString()}
            current={clientsCount.newCustomer}
            monthly={'New Customers'}
            styleclass={undefined}
          />
        </div>
      </div>

      <div className="kpi-grid ">
        <div className="card-1">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">Most Liked</span>
              </h3>
            </div>
            <div className="card-body pt-0">
              <Tabs
                defaultActiveKey="Artwork"
                transition={false}
                id="noanim-tab-example"
                className="border-0 db-kpi"
              >
                <Tab eventKey="Artwork" title="Artwork">
                  <MostLikedArtworks />
                </Tab>
                <Tab eventKey="Article" title="Article">
                  <MostLikedArticle />
                </Tab>
                <Tab eventKey="Artist" title="Artist">
                  <MostLikedArtist />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="card-2">
          <ClientActivities />
        </div>
        <div className="card-3">
          <div className="card">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3">
                  Recent Activities
                </span>
              </h3>
            </div>
            <div className="card-body pt-0">
              <Tabs
                defaultActiveKey="Artwork"
                transition={false}
                className="border-0 db-kpi"
              >
                <Tab eventKey="Artwork" title="Inquiries">
                  <RecentInquiries />
                </Tab>
                <Tab eventKey="Article" title="Reservations">
                  <RecentReservations />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
type salesDataType = {
  lastMonthSale: number;
  totalSales: number;
};
type artworksCountType = {
  avaiableCount: {
    lastMonthCount: number;
    totalCount: number;
  };
  reserveCount: {
    lastMonthCount: number;
    totalCount: number;
  };
  soldCount: {
    lastMonthCount: number;
    totalCount: number;
  };
  inquiryCount: {
    lastMonthCount: number;
    totalCount: number;
  };
};
type clientsCountType = {
  newCustomer: number;
  totalCustomer: number;
};
