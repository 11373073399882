import Form from 'react-bootstrap/Form';

export const UnitSelect = ({
  options,
  isBordered,
  handleChange,
  title,
  selected,
}: UnitSelectProps) => {
  return (
    <div className={`${isBordered && 'select-bordered'}`}>
      <Form.Select
        onChange={(e) => {
          handleChange(title, e.target.value);
        }}
      >
        {options.map((item, index) => {
          return item.value == selected ? (
            <option value={item.value} selected>
              {item.value}
            </option>
          ) : (
            <option value={item.value}>{item.value}</option>
          );
        })}
      </Form.Select>
    </div>
  );
};

interface UnitSelectProps {
  options: { value: string }[];
  isBordered: boolean;
  handleChange: Function;
  title: string;
  selected: string | null;
}
