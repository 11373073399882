import { Pagination } from '../../../_metronic/partials/content/pagination/Pagination';
import { FiEdit3, FiMoreVertical, FiTrash } from 'react-icons/fi';
import { ArtworkCard } from '../../../_metronic/partials/content/cards/ArtworkCard';
import { DropDown } from '../../../_metronic/partials/content/dropdown/DropDown-Menu/DropDown';
import { SearchBar } from '../../../_metronic/partials/content/SearchBar/SearchBar';
import { ArtworkFilter } from '../../components/filters/ArtworkFilters';
import { artwork } from './utils';
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces';
import { HiPlus } from 'react-icons/hi';
import React, { useState } from 'react';
import { LinkTag } from '../../../_metronic/partials/content/Button/LinkTag';
import { DEACTIVATE_ARTWORK_URL, DELETE_ARTWORK_URL } from '../../../constants';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { useUser } from '../../modules/auth/User-context';
import { useParams, useLocation } from 'react-router-dom';
import { BsCardList, BsGrid3X3 } from 'react-icons/bs';
import ListTable from '../../components/listing-table';
import {
  columnsWithAction,
  columnsWithoutAction,
  rowData,
} from './artworks-list-data';

type propsType = {
  artWorksData: artwork[];
  pageState: any;
  setPageState: any;
  setIsLoading: any;
  totalRecords: number;
  currentRecords: number;
  selectedArtWorkFilters: any;
  artWorkFilters: any;
  updateArtworkFilters: any;
  getAllArtworks: Function | undefined;
  listView: boolean;
  setListView: Function;
};
export const ArtworksList = ({
  artWorksData,
  pageState,
  setPageState,
  setIsLoading,
  totalRecords,
  currentRecords,
  selectedArtWorkFilters,
  artWorkFilters,
  updateArtworkFilters,
  listView,
  setListView,
  getAllArtworks,
}: propsType) => {
  const { id }: any = useParams();
  const location = useLocation();
  const { dispatch } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }
  function handleFiltersChange({
    categories,
    styles,
    status,
    price,
    size,
    artists,
    year,
  }: handleArtworkFiltersProps) {
    setPageState({
      ...pageState,
      filters: {
        ...pageState.filters,
        styleIds: styles.map((style) => style.value),
        categoryIds: categories.map((category) => category.value),
        status: status.map((item) => item.value),
        price: price,
        year: year,
        size: size.map((item) => item.value),
        artists: artists.map((item) => item.value),
      },
    });
  }
  function handleSearchClick(search: string): void {
    setPageState({
      // ...pageState,
      // filters: { ...pageState.filters, text: search },
      filters: {
        text: search,
        categoryIds: [],
        styleIds: [],
        status: [],
        price: [0, 125000],
        size: [],
        year: [1940, 2020],
        artists: [],
      },
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
      }, //,
    });
  }
  function makeArtworkInactive(id: number) {
    axios({
      // method: 'POST',
      // url: DEACTIVATE_ARTWORK_URL,
      // data: { artworkId: id },
      method: 'DELETE',
      url: DELETE_ARTWORK_URL + id,
      // data: { artworkId: id },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'Artwork Deactivated Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
          if (getAllArtworks) getAllArtworks();
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  const dropdownItems = [
    {
      key: 1,
      value: 'Edit',
      iconss: <FiEdit3 />,
      linkto: `/editartworks`,
      onClickFn: null,
    },
    {
      key: 4,
      value: 'Delete',
      iconss: <FiTrash />,
      linkto: '/ioli',
      onClickFn: makeArtworkInactive,
    },
  ];

  return (
    <React.Fragment>
      <div className="">
        {showAlert && (
          <Alert
            variant={alertData.variant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>{alertData.heading}</Alert.Heading>
            <p>{alertData.message}</p>
          </Alert>
        )}
        <div className="bg-white mb-5">
          <div className="card-header d-flex justify-content-between  border-1 align-items-center ">
            <div className="card-title align-items-start flex-column">
              <h3 className="fw-bolder m-0">Artworks</h3>
            </div>
            <div className="d-flex gap-3 flex-wrap">
              <div className="toggle-icons d-flex gap-2  justify-content-between align-items-center">
                <BsGrid3X3
                  color={listView ? 'grey' : 'black'}
                  size={'30px'}
                  onClick={() => setListView(false)}
                  cursor="pointer"
                />
                <BsCardList
                  color={listView ? 'black' : 'grey'}
                  size={'40px'}
                  onClick={() => setListView(true)}
                  cursor="pointer"
                />
              </div>
              <SearchBar handleSearchClick={handleSearchClick} />
              <ArtworkFilter
                handleFiltersChange={handleFiltersChange}
                selectedArtWorkFilters={selectedArtWorkFilters}
                artWorkFilters={artWorkFilters}
                updateArtworkFilters={updateArtworkFilters}
              />
              {location.pathname == '/artworks' && (
                <LinkTag
                  To={'/editartworks'}
                  icon={<HiPlus />}
                  text={'Create Artwork'}
                  hasIcon={true}
                />
              )}
            </div>
          </div>
          {selectedArtWorkFilters &&
            (selectedArtWorkFilters.categories.length > 0 ||
              selectedArtWorkFilters.styles.length > 0 ||
              selectedArtWorkFilters.artists.length > 0 ||
              selectedArtWorkFilters.status.length > 0 ||
              // selectedArtWorkFilters.price.length > 0 ||
              selectedArtWorkFilters.size.length > 0) && (
              <div className="select-items-view--wrapper">
                <div className="select-items-view">
                  <h6>Selected Filters</h6>
                  {selectedArtWorkFilters.categories.length > 0
                    ? selectedArtWorkFilters.categories.map((category: any) => (
                        <span>{category.label}</span>
                      ))
                    : ''}
                  {selectedArtWorkFilters.styles.length > 0
                    ? selectedArtWorkFilters.styles.map((category: any) => (
                        <span>{category.label}</span>
                      ))
                    : ''}
                  {selectedArtWorkFilters.status.length > 0
                    ? selectedArtWorkFilters.status.map((category: any) => (
                        <span>{category.label}</span>
                      ))
                    : ''}
                  {/* {selectedArtWorkFilters.price.length > 0
                    ? selectedArtWorkFilters.price.map((category: any) => (
                        <span>{category.label}</span>
                      ))
                    : ''} */}
                  {selectedArtWorkFilters.size.length > 0
                    ? selectedArtWorkFilters.size.map((category: any) => (
                        <span>{category.label}</span>
                      ))
                    : ''}
                  {selectedArtWorkFilters.artists.length > 0
                    ? selectedArtWorkFilters.artists.map((category: any) => (
                        <span>{category.label}</span>
                      ))
                    : ''}
                </div>
              </div>
            )}
        </div>
        <div className="tab-pane fade show active" id="kt_table_widget_8_tab_1">
          <div className="row">
            {!artWorksData || artWorksData.length <= 0 ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-30 data-not-found">
                <span className="fs-4  fw-bold ">No Data Found</span>
              </div>
            ) : listView ? (
              <ListTable
                columns={
                  location.pathname == '/artworks'
                    ? columnsWithAction
                    : columnsWithoutAction
                }
                userData={rowData(
                  artWorksData,
                  makeArtworkInactive,
                  location.pathname
                )}
              />
            ) : (
              artWorksData.map((Art, index) => (
                <div className="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-xs-12 px-4">
                  <ArtworkCard
                    id={Art.id}
                    artimage={Art.imageUrl}
                    title={Art.name}
                    action={
                      <DropDown
                        DropdownItem={dropdownItems}
                        title={<FiMoreVertical />}
                        id={Art.id}
                      />
                    }
                    cost={Art.price}
                    status={Art.availability.toLowerCase()}
                    artist={Art?.artist.name || ''}
                    year={Art?.year || ''}
                  />
                </div>
              ))
            )}
          </div>
        </div>
        <Pagination
          currentRecords={currentRecords}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          pageSize={pageState.pageInfo.pageSize}
          pageNumber={pageState.pageInfo.pageNumber}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
        />
      </div>
    </React.Fragment>
  );
};
type handleArtworkFiltersProps = {
  artists: Option[];
  categories: Option[];
  styles: Option[];
  status: Option[];
  price: number[];
  year: number[];
  size: Option[];
};
