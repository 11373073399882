import { useEffect, useState } from 'react';
import { ArtUploader } from '../../../_metronic/partials/content/ArtUploader/ArtUploader';
import { ZIP_FILE_EXTENSIONS, ZIP_FILE_UPLOAD_URL } from '../../../constants';
import { Spinner } from 'react-bootstrap-v5';
import { ErrorCard } from '../../../_metronic/partials/content/cards/ErrorCard';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ImportArts = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [zipError, setZipError] = useState<zipErrorType[]>();
  const [zipErrorMessage, setZipErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Artworks', to: '/artworks' },
      { title: 'Import', to: null },
    ]);
  }, []);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <div className="import-art-wrapper">
        <h2 className="art-uploader-heading mb-5">Import Arts Zip File</h2>
        <div className="file-card--wrapper mb-32">
          <ArtUploader
            setSuccessMessage={setSuccessMessage}
            setIsLoading={setIsLoading}
            setErrorState={setZipError}
            setErrorMessage={setZipErrorMessage}
            title={'Click to upload'}
            description={'or drag and drop  .zip , .zipx '}
            fileType={ZIP_FILE_EXTENSIONS}
            uploadUrl={ZIP_FILE_UPLOAD_URL}
          />
        </div>
        {successMessage.length > 0 && (
          <div className="text-success justify-content-center d-flex">
            <p>{successMessage}</p>
          </div>
        )}
        {zipError && (
          <div className="card border my-5">
            <div className="card-header bg-danger border-1  text-center">
              <h3 className="card-title">
                <span className="card-label fw-bolder fs-3  text-white">
                  Images not mapped with Stock Number
                </span>
              </h3>
            </div>
            <div className="row p-5">
              {zipError.map((item) => (
                <div className="col-md-3">
                  <ErrorCard imageTitle={item.name} imageUrl={item.imageUrl} />
                </div>
              ))}
            </div>
          </div>
        )}
        {zipErrorMessage && (
          <div className="text-danger d-flex justify-content-center">
            <p>{zipErrorMessage}</p>
          </div>
        )}
      </div>
    );
  }
};

type zipErrorType = {
  name: string;
  imageUrl: string;
};
