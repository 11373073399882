import axios from 'axios';
import { AiOutlineMail } from 'react-icons/ai';
import {
  MARK_ARTWORK_AS_SOLD,
  MARK_INQUIRY_AS_RESOLVED,
  WITHDRAW_ARTWORK_RESERVATION,
} from '../../../constants';
import { useLocation, useHistory } from 'react-router';
import { BadgeStatus } from '../../../_metronic/partials/content/badges/BadgeStatus';
type propTypes = {
  setShowAlert: Function;
  setAlertData: Function;
  imageUrl: string;
  clientName: string;
  clientEmail: string;
  reservationTime: string;
  artworkId: number | undefined;
  userId: number | undefined;
  inquiryStatusId: number | undefined;
};
export const ClientDetailsCard = ({
  imageUrl,
  clientEmail,
  clientName,
  reservationTime,
  artworkId,
  userId,
  setAlertData,
  setShowAlert,
  inquiryStatusId,
}: propTypes) => {
  const location = useLocation();
  const history = useHistory();
  function handleClick(type: string) {
    let obj: any = {
      artworkId: artworkId,
      userId: userId,
    };
    if (type === 'resolved') {
      obj.statusId = 1;
    }
    axios({
      method: 'POST',
      url:
        type === 'sold'
          ? MARK_ARTWORK_AS_SOLD
          : type === 'withdraw'
          ? WITHDRAW_ARTWORK_RESERVATION
          : MARK_INQUIRY_AS_RESOLVED,
      data: obj,
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message:
              type === 'sold'
                ? 'Sold Successfully'
                : type === 'withdraw'
                ? 'Withdrawed Successfully'
                : 'Resolved Successfully',
            heading: 'Successful',
          });
          setShowAlert(true);
          history.replace(
            type === 'sold' || type === 'withdraw'
              ? '/reservation'
              : '/inquirylisting'
          );
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch(({ error }) => {
        setAlertData({
          variant: 'danger',
          message: error.message,
          heading: 'Failed',
        });
        setShowAlert(true);
      });
  }
  function withdrawReservation() {
    axios({
      method: 'POST',
      url: WITHDRAW_ARTWORK_RESERVATION,
      data: {
        artworkId: artworkId,
        userId: userId,
      },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'Withdrawed Successfully',
            heading: 'Successful',
          });
          setShowAlert(true);
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch(({ error }) => {
        setAlertData({
          variant: 'danger',
          message: error.message,
          heading: 'Failed',
        });
        setShowAlert(true);
      });
  }
  return (
    <div className="card mb-5">
      <div className="card-header border-0">
        <div className="card-title m-0 n gap-3">
          <h3 className="fw-bolder m-0">Client Details</h3>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center">
          <div className="me-7 mb-4">
            <div className="symbol symbol-80px symbol-lg-100px symbol-fixed position-relative">
              <img src={imageUrl} />
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-1">
                  <span className="fs-1 fw-bold me-1">{clientName}</span>
                </div>
                <div className="d-flex flex-wrap mb-1 pe-2">
                  <span className="d-flex align-items-center fs-2 me-5 mb-2 gap-2">
                    <AiOutlineMail className="fs-2" />
                    <span className="text-gray-400 fs-4">{clientEmail}</span>
                  </span>
                  {/* <span className="d-flex align-items-center me-5 mb-2 gap-2">
                    <BiPhone className="fs-2" />
                    <span className="text-gray-400 fs-4">{props.phone}</span>
                  </span> */}
                </div>
                <div className="d-flex flex-wrap mb-1 pe-2">
                  <span className="d-flex align-items-center text-gray-400 fs-4 me-5 mb-2 gap-2">
                    <span className="fw-bold text-dark">Reservation Time:</span>
                    {reservationTime}
                  </span>
                </div>
                {location.pathname.includes('/inquirydetails') && (
                  <div>
                    <BadgeStatus
                      status={inquiryStatusId === 1 ? 'resolved' : 'unresolved'}
                    />
                  </div>
                )}
              </div>

              {location.pathname.includes('/reservationdetails') && (
                <div className="d-flex my-4">
                  <button
                    className="btn btn-sm btn-light-primary me-3"
                    onClick={() => handleClick('sold')}
                  >
                    Mark as sold
                  </button>

                  <button
                    className="d-inline-flex btn btn-dark btn-sm align-items-center art-btn gap-2"
                    onClick={() => handleClick('withdraw')}
                  >
                    Withdraw reservation
                  </button>
                </div>
              )}
              {location.pathname.includes('/inquirydetails') &&
                inquiryStatusId == 1 && (
                  <div className="d-flex my-4">
                    <button
                      className="d-inline-flex btn btn-dark btn-sm align-items-center art-btn gap-2"
                      onClick={() => handleClick('resolved')}
                    >
                      Mark as Resolved
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
