import React, { useEffect, useState } from 'react';
import { FormLabel } from '../../../_metronic/partials/content/Label/FormLabel';
import { AiOutlineCamera } from 'react-icons/ai';
import { ArtButton } from '../../../_metronic/partials/content/Button/ArtButton';
import axios from 'axios';
import {
  ADD_ARTIST_DATA_URL,
  GET_ARTIST_DETAILS_URL,
  UPLOAD_ARTWORK_IMAGE,
} from '../../../constants';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { useUser } from '../../modules/auth/User-context';

type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const EditArtist = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const { dispatch } = useUser();
  const { id }: any = useParams();
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const initialValues = {
    birthDate: {
      value: '',
      isError: false,
      errorMessage: 'Date of Birth is missing',
    },
    city: { value: '', isError: false, errorMessage: 'City is missing' },
    country: { value: '', isError: false, errorMessage: 'Country is missing' },
    deathDate: {
      value: '',
      isError: false,
      errorMessage: 'Date of Death is missing',
    },
    description: {
      value: '',
      isError: true,
      errorMessage: 'Description is missing',
    },
    id: { value: 0, isError: false, errorMessage: 'ID is missing' },
    image: { value: '', isError: true, errorMessage: 'Image is missing' },
    name: { value: '', isError: true, errorMessage: 'Name is missing' },
    state: { value: '', isError: false, errorMessage: 'State is missing' },
  };
  const [artistData, setArtistData] =
    useState<{ [name: string]: any }>(initialValues);
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Artists', to: '/artists' },
      { title: id ? 'Edit' : 'Add', to: null },
    ]);
    if (id) {
      axios({
        method: 'POST',
        url: GET_ARTIST_DETAILS_URL + id,
      }).then(({ data }) => {
        // setArtistData(data.data);
        const {
          birthDate,
          city,
          country,
          deathDate,
          description,
          id,
          imageUrl,
          name,
          state,
          tags,
        } = data.data;
        setArtistData({
          ...artistData,
          birthDate: {
            ...artistData.birthDate,
            value: birthDate,
            isError: false,
          },
          city: { ...artistData.city, value: city, isError: false },
          country: { ...artistData.country, value: country, isError: false },
          deathDate: {
            ...artistData.deathDate,
            value: deathDate,
            isError: false,
          },
          description: {
            ...artistData.description,
            value: description,
            isError: false,
          },
          id: { ...artistData.id, value: id, isError: false },
          image: { ...artistData.image, value: imageUrl, isError: false },
          name: { ...artistData.name, value: name, isError: false },
          state: { ...artistData.state, value: state, isError: false },
        });
        setPath(data.data?.imageUrl || '');
      });
    }
  }, []);
  const [showAlert, setShowAlert] = useState(false);
  function uploadImage(e: any) {
    const formData = new FormData();
    formData.append('formFile', e.target.files?.item(0) as Blob);
    const data = e.target.files?.item(0) as Blob;
    let a = URL.createObjectURL(data);
    setPath(a);
    axios({
      method: 'POST',
      url: UPLOAD_ARTWORK_IMAGE,
      data: formData,
    })
      .then(({ data }) => {
        setArtistData({
          ...artistData,
          image: { ...artistData.image, value: data.data.url, isError: false },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  function updateInputs(key: any, value: any) {
    setArtistData({
      ...artistData,
      [key]: { ...artistData[key], value: value, isError: false },
    });
  }
  function saveChanges(e: any) {
    let errorFound = false;
    if (
      artistData.birthDate.value.length > 1 &&
      artistData.deathDate.value.length > 1 &&
      new Date(artistData.birthDate.value) >=
        new Date(artistData.deathDate.value)
    ) {
      errorFound = true;
      setAlertData({
        variant: 'danger',
        heading: 'Date Error',
        message: 'Date of birth should be before Date of Death',
      });
      setShowAlert(true);
    }
    for (const key in artistData) {
      if (artistData[key].isError && !errorFound) {
        setAlertData({
          variant: 'danger',
          heading: 'Fields Missing',
          message: artistData[key].errorMessage,
        });
        errorFound = true;
        setShowAlert(true);
      }
    }
    if (!errorFound) {
      const data = {
        name: artistData.name.value,
        birthDate: artistData.birthDate.value,
        deathDate: artistData.deathDate.value,
        country: artistData.country.value,
        state: artistData.state.value,
        city: artistData.city.value,
        imageUrl: artistData.image.value,
        id: artistData.id.value ? artistData.id.value : 0,
        description: artistData.description.value,
      };
      axios({
        method: 'POST',
        url: ADD_ARTIST_DATA_URL,
        data: data,
      })
        .then(({ data }) => {
          if (data.isSuccess) {
            setAlertData({
              variant: 'success',
              message: 'Artist Details have been saved successfully',
              heading: 'Success',
            });
            setShowAlert(true);
            if (!id) {
              setArtistData(initialValues);
            }
          } else {
            setAlertData({
              variant: 'danger',
              message: data.message,
              heading: 'Failed',
            });
            setShowAlert(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            localStorage.clear();
            dispatch({ type: 'remove-user' });
          }
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: error.message,
          });
          setShowAlert(true);
        });
    }
  }
  const [path, setPath] = useState<string>();
  return (
    <React.Fragment>
      <div className="row">
        <div className="">
          {showAlert && (
            <Alert
              variant={alertData.variant}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <Alert.Heading>{alertData.heading}</Alert.Heading>
              <p>{alertData.message}</p>
            </Alert>
          )}
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-0">
              <div className="card-title m-0 d-flex justify-content-between gap-3">
                <h3 className="fw-bolder m-0">Artist Details</h3>
              </div>
              <div className="card-title m-0 d-flex  gap-3">
                <ArtButton
                  buttonText={id ? 'Save Changes' : 'Add Artist'}
                  styleclass={''}
                  variant={'dark'}
                  icon={undefined}
                  hasIcon={false}
                  onClick={saveChanges}
                />
              </div>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <form className="form ">
                <div className="card-body border-top p-9">
                  <div className="row mb-32">
                    <h4 className="my-5">Artist Profile</h4>
                    <div className="imageUploaderWrapper mb-5">
                      <div className="circle">
                        <img src={path} alt="" />
                      </div>
                      <label className="pImage">
                        <AiOutlineCamera className="uploadButton" />
                        <input
                          className="fileUpload"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                        />
                      </label>
                    </div>
                    <div className="row mb-32">
                      <div className="col-lg-4 fv-row">
                        <FormLabel label={'Name'} />
                        <input
                          type="text"
                          value={artistData.name.value}
                          className="form-control form-control-lg "
                          placeholder="Name"
                          onChange={(e) => {
                            updateInputs('name', e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-lg-4 fv-row">
                        <FormLabel label={' Date of Birth'} />
                        <input
                          type="date"
                          value={artistData.birthDate.value}
                          className="form-control form-control-lg "
                          placeholder="Date of Birth"
                          onChange={(e) => {
                            updateInputs('birthDate', e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-4 fv-row">
                        <FormLabel label={'Date of Death'} />
                        <input
                          type="date"
                          value={artistData.deathDate.value}
                          className="form-control form-control-lg "
                          placeholder="Date of Death"
                          onChange={(e) => {
                            updateInputs('deathDate', e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-4 fv-row">
                        <FormLabel label={'Country'} />
                        <input
                          type="text"
                          value={artistData.country.value}
                          className="form-control form-control-lg "
                          placeholder="Country"
                          onChange={(e) => {
                            updateInputs('country', e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-4 f3v-row">
                        <FormLabel label={'State'} />
                        <input
                          type="text"
                          value={artistData.state.value}
                          className="form-control form-control-lg "
                          placeholder="State"
                          onChange={(e) => {
                            updateInputs('state', e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-4 fv-row">
                        <FormLabel label={'City'} />
                        <input
                          type="text"
                          value={artistData.city.value}
                          className="form-control form-control-lg "
                          placeholder="City"
                          onChange={(e) => {
                            updateInputs('city', e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 fv-row">
                        <FormLabel label={'Description'} />
                        <textarea
                          rows={4}
                          value={artistData.description.value}
                          className="form-control form-control-lg "
                          placeholder="Description"
                          onChange={(e) => {
                            updateInputs('description', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
