/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import { Collapse } from 'react-bootstrap-v5';
import { AsideMenuItem } from './AsideMenuItem';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export function AsideMenuMain() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/dashboard.svg"
        title="Dashboard"
        fontIcon="bi-app-indicator"
      />
      <div className="d-flex align-items-center justify-content-between position-relative">
        <div className="w-100">
          <AsideMenuItem
            to="/artworks"
            title="Artworks"
            icon="/media/icons/duotune/art/artworks.svg"
            fontIcon="bi-person"
          />
        </div>
        {open ? (
          <span className="child-icon-menu">
            <IoIosArrowUp
              style={{ marginInline: '20px', cursor: 'pointer' }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              color="white"
            />
          </span>
        ) : (
          <span className="child-icon-menu">
            <IoIosArrowDown
              style={{ marginInline: '20px', cursor: 'pointer' }}
              className="mr-5"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              color="white"
            />
          </span>
        )}
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text">
          <AsideMenuItem
            to="/importartwork"
            title="Import from Images"
            hasBullet={true}
            fontIcon="bi-layers"
          />
          <AsideMenuItem
            to="/importexcel"
            title="Import from Excel"
            fontIcon="bi-layers"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/reservation"
            title="Artwork Reservation"
            fontIcon="bi-layers"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/inquirylisting"
            title="Artwork Inquiry"
            fontIcon="bi-layers"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/categories"
            title="Styles and categories"
            fontIcon="bi-layers"
            hasBullet={true}
          />
        </div>
      </Collapse>
      <AsideMenuItem
        to="/clients"
        title="Clients"
        icon="/media/icons/duotune/art/users.svg"
        fontIcon="bi-person"
      />

      <AsideMenuItem
        to="/artists"
        title="Artists"
        fontIcon="bi-sticky"
        icon="/media/icons/duotune/art/artist.svg"
      />
      <AsideMenuItem
        to="/journals"
        title="Journal"
        icon="/media/icons/duotune/art/journals.svg"
        fontIcon="bi-layers"
      />
      <AsideMenuItem
        to="/users"
        title="Users"
        icon="/media/icons/duotune/art/users.svg"
        fontIcon="bi-person"
      />
    </>
  );
}
