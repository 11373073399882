// import React, { FC } from 'react';
import { columns } from '../artworks/Addartwork/utils/user-data';
import React, { useEffect, useState } from 'react';
import { useLayout } from '../../../_metronic/layout/core';
import axios from 'axios';
import { GET_ALL_USERS_URL, GET_USER_FILTERS_URL } from '../../../constants';
import { Spinner } from 'react-bootstrap-v5';
import clsx from 'clsx';
import {
  filterData,
  userFilterType,
} from '../../components/filters/UserFilters';
import { ClientList } from './ClientsList';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { useUser } from '../../modules/auth/User-context';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const Clients = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const { dispatch } = useUser();
  const { classes } = useLayout();
  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [pageState, setPageState] = useState<pageStateType>({
    filters: {
      text: '',
      categoryIds: [],
      styleIds: [],
      status: [],
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  function getAllClients() {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_ALL_USERS_URL,
      data: pageState,
    }).then(({ data }) => {
      if (data) {
        setUserData(data.data);
        setTotalRecords(data.totalRecords);
        setCurrentRecords(data.data?.length);
      }
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getAllClients();
  }, [pageState]);
  const [userFilters, setUserFilters] = useState<userFilterType>({
    categories: [],
    styles: [],
    status: [],
  });
  const [selectedUserFilters, setSelectedUserFilters] = useState({
    categories: [],
    styles: [],
    status: [],
  });
  function updateUserFilters(key: string, value: any) {
    setSelectedUserFilters({ ...selectedUserFilters, [key]: [...value] });
  }
  useEffect(() => {
    setBreadcrumbData([{ title: 'Clients', to: null }]);
    axios({
      method: 'POST',
      url: GET_USER_FILTERS_URL,
    })
      .then(({ data }) => {
        setUserFilters({
          categories: data.data.category.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          styles: data.data.style.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          status: data.data.status.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
      });
  }, []);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="toolbar" id="kt_toolbar">
          <div
            id="kt_toolbar_container"
            className={clsx(
              classes.toolbarContainer.join(' '),
              'd-flex justify-content-end'
            )}
          >
            <div className="d-flex align-items-center py-1 gap-2">
              <div className="me-4"></div>
            </div>
          </div>
        </div>
        <ClientList
          selectedUserFilters={selectedUserFilters}
          userFilters={userFilters}
          updateUserFilters={updateUserFilters}
          currentRecords={currentRecords}
          userData={userData}
          columns={columns}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          pageState={pageState}
          setPageState={setPageState}
          getAllClients={getAllClients}
        />
      </React.Fragment>
    );
  }
};

export interface pageStateType {
  filters: {
    text: string;
    categoryIds: number[];
    styleIds: number[];
    status: string[];
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
}
