import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { Routes } from './routing/Routes';
import { UserProvider } from './modules/auth/User-context';

const App: React.FC = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename="/artvisor-admin">
        <LayoutProvider>
          <UserProvider>
            <Routes />
          </UserProvider>
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
