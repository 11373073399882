// import * as React from 'react';

import { BiCommentAdd, BiLike } from 'react-icons/bi';
import { BsImages } from 'react-icons/bs';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { StatsCounter2 } from '../../../../_metronic/partials/content/StatsCounter/StatsCounter2';
import blank from '../../../../_metronic/assets/images/blank.png';
import { artistType, pageStateType } from './ArtistDetails';
import React from 'react';
import { Link } from 'react-router-dom';

interface IArtistDetailsComponentProps {
  artistData: artistType | undefined;
  setActiveTab: Function;
  activeTab: string;
}

export const ArtistDetailsComponent = ({
  artistData,
  activeTab,
  setActiveTab,
}: IArtistDetailsComponentProps) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center">
        <div className="me-7 mb-4">
          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            <img src={artistData ? artistData.imageUrl : blank} />
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <span className="fs-1 fw-bold me-1">{artistData?.name}</span>
              </div>
              <div className="d-flex flex-wrap mb-1 pe-2">
                <span className="d-flex align-items-center mb-2 gap-2">
                  <HiOutlineLocationMarker className="fs-2" />
                  <span className="text-gray-400 fs-4">
                    {artistData?.city
                      ? artistData?.city + ', ' + artistData?.country
                      : 'Not Available'}
                  </span>
                </span>
              </div>
              <div className="d-flex flex-wrap mb-1 pe-2">
                <span className="d-flex align-items-center text-gray-400 fs-4 me-5 mb-2 gap-2">
                  <span className="fw-bold text-dark"> Date of Birth:</span>
                  {artistData?.birthDate || 'Not Available'}
                </span>
                <span className="d-flex align-items-center text-gray-400 fs-4 mb-2 gap-2">
                  <span className="fw-bold text-dark">Date of Death:</span>
                  <span>{artistData?.deathDate || 'Not Available'}</span>
                </span>
              </div>
            </div>
            <div className="d-flex text-align-start">
              {artistData?.id && (
                <Link
                  className="d-inline-flex btn btn-dark btn-sm align-items-center art-btn gap-2"
                  to={{
                    pathname: `/editartist/${artistData.id}`,
                  }}
                >
                  Edit
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {artistData?.description && (
        <div>
          <p>{artistData.description}</p>
        </div>
      )}

      <div className="d-flex artdetails-wrapper-card  mb-32">
        <div
          className="= mb-md-0 p"
          onClick={() => {
            setActiveTab('totalArtworks');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'totalArtworks'}
            counts={artistData?.artworkCount || 0}
            icon={<BsImages />}
            title={'Total Artworks'}
          />
        </div>
        <div
          className="mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('followers');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'followers'}
            counts={artistData?.followingClientCount || 0}
            icon={<BiCommentAdd />}
            title={'Followers'}
          />
        </div>
        <div
          className="mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('likedArtworks');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'likedArtworks'}
            counts={artistData?.likedArtworkCount || 0}
            icon={<BiLike />}
            title={'Liked Artworks'}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
