import React from "react";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";

export const LinkTag = (props: LinkPropTypes) => {
  return (
      <Link
        to={props.To}
        className="d-inline-flex btn btn-dark align-items-center art-btn gap-2"
      >
        {props.hasIcon && <span>{props.icon !== "" && props.icon}</span>}
        {props.text}
      </Link>
  );
};

interface LinkPropTypes {
  To: any;
  icon: any;
  text: string;
  hasIcon: boolean;
}
