import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import {
  DELETE_ARTICLE_CATEGORY,
  DELETE_ARTWORK_CATEGORY,
  DELETE_ARTWORK_STYLE,
  GET_ALL_ARTICLE_CATEGORIES,
  GET_ALL_ARTWORK_CATEGORIES,
  GET_ALL_ARTWORK_STYLES,
} from '../../../../constants';
import { ArtButton } from '../../../../_metronic/partials/content/Button/ArtButton';
import { AddModal } from './addModal/AddModal';
import { columns, rowData } from './categories-data';
import CategoriesListTable from './CategoriesTable';

export const Categories = ({
  title,
  setAlertData,
  setShowAlert,
}: TablePropsTypes) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [filtersData, setFiltersData] = useState([]);
  function getData() {
    axios({
      method: 'GET',
      url:
        title === 'Artwork Categories'
          ? GET_ALL_ARTWORK_CATEGORIES
          : title === 'Artwork Styles'
          ? GET_ALL_ARTWORK_STYLES
          : GET_ALL_ARTICLE_CATEGORIES,
    }).then(({ data }) => {
      setFiltersData(data.data);
    });
  }
  useEffect(() => {
    getData();
  }, []);
  function deleteFilter(id: string) {
    axios({
      method: 'DELETE',
      url:
        title === 'Artwork Categories'
          ? DELETE_ARTWORK_CATEGORY + id
          : title === 'Artwork Styles'
          ? DELETE_ARTWORK_STYLE + id
          : DELETE_ARTICLE_CATEGORY + id,
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            heading: 'Deleted',
            variant: 'success',
            message: 'Deleted Successfully',
          });
          setShowAlert(true);
          getData();
        } else {
          setAlertData({
            heading: 'Failed',
            variant: 'danger',
            message: data?.message,
          });
          setShowAlert(true);
        }
      })
      .catch(({ error }) => {
        setAlertData({
          heading: 'Something Went Wrong',
          variant: 'danger',
          message: 'Please Try Again',
        });
        setShowAlert(true);
      });
  }
  return (
    <div className="card">
      <div className="card-header align-items-center">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark">{title}</span>
        </h3>
        <ArtButton
          buttonText={''}
          styleclass={'btn-sm line-height-1'}
          variant={'dark'}
          icon={<AiOutlinePlus />}
          hasIcon={true}
          onClick={() => setModalShow(true)}
        />
        <AddModal
          getData={getData}
          setAlertData={setAlertData}
          setShowAlert={setShowAlert}
          title={title}
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleClose={() => setModalShow(false)}
        />
      </div>

      <div className="card-body py-3 categories-table">
        <div className="table-responsive">
          <CategoriesListTable
            columns={columns}
            userData={rowData(filtersData, deleteFilter)}
          />
        </div>
      </div>
    </div>
  );
};

interface TablePropsTypes {
  title: string;
  setAlertData: Function;
  setShowAlert: Function;
}
