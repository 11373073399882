// import React, { FC } from 'react';
// import { columns } from './artist-data';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GET_ALL_ARTISTS_URL } from '../../../../constants';
import { Spinner } from 'react-bootstrap-v5';
import { ArtistList } from './ArtistList';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const Artists = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [pageState, setPageState] = useState<pageStateType>({
    filters: {
      text: '',
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  useEffect(() => {
    setBreadcrumbData([{ title: 'Artists', to: null }]);
  }, []);
  function getArtists() {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_URL,
      data: pageState,
    }).then(({ data }) => {
      if (data) {
        setUserData(data.data);
        setTotalRecords(data.totalRecords);
        setCurrentRecords(data.data.length);
      }
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getArtists();
  }, [pageState]);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <ArtistList
          getArtists={getArtists}
          currentRecords={currentRecords}
          userData={userData}
          // columns={columns}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          pageState={pageState}
          setPageState={setPageState}
        />
      </React.Fragment>
    );
  }
};

export interface pageStateType {
  filters: {
    text: string;
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
}
