import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import "./SearchBar.scss";
interface searchBarProps {
  handleSearchClick: (search: string) => void;
}
export const SearchBar = ({ handleSearchClick }: searchBarProps) => {
  const [inputText, setInputText] = useState("");
  return (
    <React.Fragment>
      <div className="search-bar">
        <input
          type="text"
          value={inputText}
          onChange={(event) => {
            setInputText(event.target.value);
          }}
          placeholder="Search"
          name="text"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSearchClick(inputText);
            }
          }}
        />
        <button
          onClick={() => {
            handleSearchClick(inputText);
          }}
          className="btn"
        >
          <FiSearch />
        </button>
      </div>
    </React.Fragment>
  );
};
