import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap-v5';
import { useParams } from 'react-router-dom';
import { GET_ARTWORK_RESERVATION_DETAILS } from '../../../../constants';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';

import { ClientDetailsCard } from '../../Clients/ClientDetailsCard';
import { ArtworkDetailCrad } from '../ArtworkDetailCrad';
import { QueueTable } from './queue/queueTable';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ReservationDetails = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { dispatch } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const [reservationData, setReservationData] = useState<reservationDataType>();
  const [isLoading, setIsLoading] = useState(true);
  const { artworkId, userId }: any = useParams();
  useEffect(() => {
    // setIsLoading(true);
    setBreadcrumbData([
      { title: 'Reservations', to: '/reservation' },
      { title: 'Details', to: null },
    ]);
    axios({
      method: 'POST',
      url: GET_ARTWORK_RESERVATION_DETAILS,
      data: { artworkId, userId },
    }).then(({ data }) => {
      setIsLoading(false);
      setReservationData(data.data);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return reservationData ? (
      <React.Fragment>
        <div className="row">
          {showAlert && (
            <Alert
              variant={alertData.variant}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <Alert.Heading>{alertData.heading}</Alert.Heading>
              <p>{alertData.message}</p>
            </Alert>
          )}
          <div className="col-md-4">
            <ArtworkDetailCrad
              artistName={reservationData.artistName}
              artworkTitle={reservationData.title}
              imageUrl={reservationData.artworkImageUrl}
              price={reservationData.price}
              medium={reservationData.medium}
              style={reservationData.style}
              category={reservationData.category}
            />
          </div>
          <div className="col-md-8 ">
            <ClientDetailsCard
              imageUrl={reservationData.clientImageUrl}
              clientName={reservationData.clientName}
              clientEmail={reservationData.clientEmail}
              reservationTime={new Date(
                reservationData.reservationDate
              ).toDateString()}
              artworkId={reservationData.artworkId}
              userId={reservationData.userId}
              setAlertData={setAlertData}
              setShowAlert={setShowAlert}
              inquiryStatusId={undefined}
            />
            <QueueTable
              artworkData={reservationData || null}
              setAlertData={setAlertData}
              setShowAlert={setShowAlert}
            />
          </div>
        </div>
      </React.Fragment>
    ) : (
      <></>
    );
  }
};
export type reservationDataType = {
  artworkId: number;
  artworkImageUrl: string;
  title: string;
  artistName: string;
  price: string;
  medium: string;
  category: string[];
  style: string[];
  userId: 2;
  clientName: string;
  clientEmail: string;
  reservationDate: string;
  clientImageUrl: string;
};
