import React, { useEffect, useState } from 'react';
import { AsideDefault } from './components/aside/AsideDefault';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import axios from 'axios';
import { useUser } from '../../app/modules/auth/User-context';
import { GET_USER_INFO_URL } from '../../constants';
import setupAxios from '../../setup/axios/SetupAxios';

export type breadcrumbType = {
  title: string;
  to: string | null;
  // isCurrent: boolean;
};
const MasterLayout: React.FC = ({ children }) => {
  const { dispatch } = useUser();
  const [breadcrumbData, setBreadcrumbData] = useState<breadcrumbType[]>([
    { title: 'home', to: 'home' },
    { title: 'home', to: 'home' },
    { title: 'home', to: null },
  ]);
  function getUserInfo() {
    setupAxios();
    axios({
      method: 'GET',
      url: GET_USER_INFO_URL,
    })
      .then(({ data }) => {
        dispatch({
          type: 'add-user-data',
          payload: {
            email: data.data.email,
            fullName: data.data.fullName,
            isLogin: true,
            id: data.data.id,
            imageUrl: data.data.imageUrl,
            phoneNumber: data.data.phoneNumber,
            status: data.data.approvalStatus,
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
          window.location.href = '/';
        }
      });
  }
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content
                breadcrumbData={breadcrumbData || []}
                setBreadcrumbData={setBreadcrumbData}
              />
              {/* {children}</Content> */}
            </div>
          </div>
        </div>
      </div>
    </PageDataProvider>
  );
};

export { MasterLayout };
