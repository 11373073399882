import { useEffect, useState } from 'react';
import { ArtUploader } from '../../../_metronic/partials/content/ArtUploader/ArtUploader';
import { BiMessageError } from 'react-icons/bi';
import {
  EXCEL_FILE_EXTENSIONS,
  EXCEL_FILE_UPLOAD_URL,
} from '../../../constants';
import { Spinner } from 'react-bootstrap-v5';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ImportExcel = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const [excelErrorMessage, setExcelErrorMessage] = useState();
  const [excelError, setExcelError] = useState<excelErrorType[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Artworks', to: '/artworks' },
      { title: 'Import', to: null },
    ]);
  }, []);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <div className="import-art-wrapper">
        <h2 className="art-uploader-heading mb-5">Import Excel File</h2>
        <div className="file-card--wrapper mb-32 ">
          <ArtUploader
            setSuccessMessage={setSuccessMessage}
            setIsLoading={setIsLoading}
            setErrorState={setExcelError}
            setErrorMessage={setExcelErrorMessage}
            title={'Click to upload'}
            description={'or drag and drop  .xlsx , .xlsm , .xlsb '}
            fileType={EXCEL_FILE_EXTENSIONS}
            uploadUrl={EXCEL_FILE_UPLOAD_URL}
          />
        </div>
        {excelErrorMessage && (
          <div className="text-danger justify-content-center d-flex">
            <p>{excelErrorMessage}</p>
          </div>
        )}
        {successMessage.length > 0 && (
          <div className="text-success justify-content-center d-flex">
            <p>{successMessage}</p>
          </div>
        )}
        {excelError && (
          <div className="card border my-5">
            <div className="card-header bg-danger border-1  text-center">
              <h3 className="card-title">
                <span className="card-label fw-bolder fs-3  text-white">
                  Errors
                </span>
              </h3>
            </div>
            <div className="table-responsive ">
              <div className="card-body bg-light-danger error-body">
                <table className="table table-row-dashed table-row-gray-300 align-middle">
                  <thead>
                    <tr className="fw-bolder p-5">
                      <th></th>
                      <th>Stock Number</th>
                      <th>Reasons for Failure</th>
                    </tr>
                  </thead>

                  <tbody className="">
                    {excelError.map((row: excelErrorType) => {
                      return (
                        <tr>
                          <td className="text-danger fs-1">
                            <BiMessageError />
                          </td>
                          <td>{row.stockNumber}</td>
                          <td>
                            {row.errors.map((error: string) => (
                              <p>{error}</p>
                            ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};
type excelErrorType = {
  artistDto: any;
  availability: string;
  depth: string;
  editionNumber: string;
  errors: string[];
  framed: string;
  framedDimensions: string;
  framingDetails: string;
  height: string;
  isVaild: boolean;
  location: string;
  lstCategoryStyleDtos: [];
  medium: string;
  retailCurrency: string;
  retailPrice: string;
  stockNumber: string;
  title: string;
  width: string;
  year: string;
};
