import clsx from 'clsx';
import { FC, useState } from 'react';
import { useUser } from '../../../../app/modules/auth/User-context';
// import { HeaderUserMenu } from '../../../partials';
import blank from '../../../assets/images/blank.png';
import OutsideClickHandler from 'react-outside-click-handler';
import { HeaderUserMenu } from '../../../partials/layout/header-menus/HeaderUserMenu';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
  const { state } = useUser();
  const [userMenuVisibility, setUserMenuVisibility] = useState(false);
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div
        className={clsx(
          'd-flex align-items-center ms-4 ms-lg-8 ',
          toolbarButtonMarginClass
        )}
        id="kt_header_user_menu_toggle"
      >
        <div
          onClick={() => {
            setUserMenuVisibility(!userMenuVisibility);
          }}
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
        >
          <img
            className="rounded-circle"
            src={state.imageUrl ? state.imageUrl : blank}
          />
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setUserMenuVisibility(false);
          }}
        >
          <HeaderUserMenu
            menuVisibility={userMenuVisibility}
            setMenuVisibility={setUserMenuVisibility}
          />
        </OutsideClickHandler>

        {/* </div> */}
      </div>
    </div>
  );
};

export { Topbar };
