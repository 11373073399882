import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GET_RECENT_RESERVATIONS } from '../../../../constants';
export const RecentReservations = () => {
  const [reservations, setReservations] = useState<reservationType[]>([]);
  function getReservations() {
    axios({
      method: 'POST',
      url: GET_RECENT_RESERVATIONS,
    }).then(({ data }) => {
      setReservations(data.data);
    });
  }
  useEffect(() => {
    getReservations();
  }, []);
  return (
    <>
      {reservations.length > 0 ? (
        reservations.map((item, index) => (
          <Link
            to={{
              pathname: `/reservationdetails/${item.artworkId}/${item.clientId}`,
            }}
          >
            <div className="d-flex align-items-center my-7">
              <div className="flex-grow-1">
                <span className="text-dark fw-bolder text-hover-primary fs-6">
                  {item.clientName}
                </span>
                <span className="text-muted d-block ">{item.description}</span>
              </div>
              <span className=" fs-8 text-muted fw-bold">
                {item.reserveDate}
              </span>
            </div>
          </Link>
        ))
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <h6>NO DATA</h6>
        </div>
      )}
    </>
  );
};
type reservationType = {
  clientId: number;
  artworkId: number;
  clientName: string;
  artworkTitle: string;
  description: string;
  reserveDate: string;
};
