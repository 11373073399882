import axios from 'axios';
import { useState } from 'react';
import { FiEdit3, FiMoreVertical, FiTrash } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import { DEACTIVATE_ARTICLE_URL } from '../../../../constants';
import { LinkTag } from '../../../../_metronic/partials/content/Button/LinkTag';
import { DropDown } from '../../../../_metronic/partials/content/dropdown/DropDown-Menu/DropDown';
import { Pagination } from '../../../../_metronic/partials/content/pagination/Pagination';
import { SearchBar } from '../../../../_metronic/partials/content/SearchBar/SearchBar';
import { JournalCard } from '../JournalCard';
import { articleType } from './Journals';
import { Alert } from 'react-bootstrap';
import { useUser } from '../../../modules/auth/User-context';
import { useLocation } from 'react-router-dom';

interface IJournalsListProps {
  articlesData: articleType[];
  pageState: any;
  setPageState: any;
  setIsLoading: any;
  totalRecords: number;
  currentRecords: number;
  getAllArticles: Function | undefined;
}

const JournalsList = ({
  articlesData,
  pageState,
  setIsLoading,
  setPageState,
  totalRecords,
  currentRecords,
  getAllArticles,
}: IJournalsListProps) => {
  const location = useLocation();
  const { dispatch } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }

  function handleSearchClick(search: string): void {
    setPageState({
      filters: {
        text: search,
      },
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
      },
      // ...pageState,
      // filters: { ...pageState.filters, text: search },
    });
  }
  function makeArticleInactive(id: number) {
    axios({
      method: 'POST',
      url: DEACTIVATE_ARTICLE_URL,
      data: { articleId: id },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'Article Deactivated Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
          if (getAllArticles) getAllArticles();
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  const dropdownItems = [
    {
      key: 1,
      value: 'Edit',
      iconss: <FiEdit3 />,
      linkto: '/editarticle',
      onClickFn: null,
    },
    {
      key: 2,
      value: 'Delete',
      iconss: <FiTrash />,
      linkto: '/editarticle',
      onClickFn: makeArticleInactive,
    },
  ];
  return (
    <div className="row">
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="card articles-cards  mb-xl-10">
        <div className="card-header border-1 align-items-center bg-white mb-5 ">
          <div className="card-title m-0 align-items-start flex-column">
            <h3 className="fw-bolder m-0">Journal/Articles</h3>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            <SearchBar handleSearchClick={handleSearchClick} />
            {location.pathname == '/journals' && (
              <LinkTag
                To={'/editarticle'}
                icon={<HiPlus />}
                text={'Create Article'}
                hasIcon={true}
              />
            )}
          </div>
        </div>
        <div className="row">
          {articlesData.map((article, index) => (
            <div className="col-md-6 col-lg-4 col-xl-4 col-sm-12 col-xs-12 px-4">
              <JournalCard
                id={article.id}
                isBookmarked={article.isBookmarked}
                imageUrl={article.imageUrl}
                author={article.author}
                publishDate={article.publishDate}
                title={article.title}
                action={
                  <DropDown
                    DropdownItem={dropdownItems}
                    title={<FiMoreVertical />}
                    id={article.id}
                  />
                }
              />
            </div>
          ))}
        </div>
        <Pagination
          currentRecords={currentRecords}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          pageSize={pageState.pageInfo.pageSize}
          pageNumber={pageState.pageInfo.pageNumber}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
        />
      </div>
    </div>
  );
};

export default JournalsList;
