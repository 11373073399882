import axios from 'axios';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  ADD_ARTICLE_CATEGORY,
  ADD_ARTWORK_CATEGORY,
  ADD_ARTWORK_STYLE,
} from '../../../../../constants';
import { ArtButton } from '../../../../../_metronic/partials/content/Button/ArtButton';
import './AddModal.scss';

export const AddModal = ({
  show,
  handleClose,
  onHide,
  title,
  setAlertData,
  setShowAlert,
  getData,
}: DeleteModalType) => {
  const [name, setName] = useState('');
  function addFilter() {
    axios({
      method: 'POST',
      url:
        title === 'Artwork Categories'
          ? ADD_ARTWORK_CATEGORY
          : title === 'Article Categories'
          ? ADD_ARTICLE_CATEGORY
          : ADD_ARTWORK_STYLE,
      data: { name },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            heading: 'Success',
            variant: 'success',
            message: 'Added Successfully',
          });
          setShowAlert(true);
          handleClose();
          getData();
        } else {
          setAlertData({
            heading: 'Failed',
            variant: 'danger',
            message: data?.message,
          });
          setShowAlert(true);
          handleClose();
        }
      })
      .catch(({ error }) => {
        setAlertData({
          heading: 'Something Went Wrong',
          variant: 'danger',
          message: 'Please Try Again',
        });
        setShowAlert(true);
        handleClose();
      });
  }
  return (
    <>
      <Modal className="ModalWrapper" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0 pb-0 m-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="content">
            <h2>Add Categories</h2>
            <div className="d-flex gap-3">
              <input
                type="text"
                className="form-control form-control-lg "
                placeholder="Add "
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="buttonWrapper">
            <ArtButton
              buttonText={'Cancel'}
              styleclass={'btn-block w-100'}
              variant={'outline-dark'}
              icon={' '}
              hasIcon={false}
              onClick={onHide}
            />
            <ArtButton
              buttonText={'Add'}
              styleclass={'w-100'}
              variant={'dark'}
              icon={' '}
              hasIcon={false}
              onClick={() => addFilter()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

interface DeleteModalType {
  show: any;
  onHide: any;
  handleClose: any;
  title: string;
  setAlertData: Function;
  setShowAlert: Function;
  getData: Function;
}
