import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

export const ArtButton = (props: ButtonPropTypes) => {
  return (
    <Button
      disabled={props.disabled ? true : false}
      onClick={props.onClick}
      className={`${props.styleclass}  art-btn gap-2`}
      variant={props.variant}
    >
      {props.hasIcon && <span>{props.icon !== '' && props.icon}</span>}
      {props.buttonText}
    </Button>
  );
};
interface ButtonPropTypes {
  buttonText: string;
  styleclass: string;
  onClick?: any;
  variant: string;
  icon: any;
  hasIcon: boolean;
  disabled?: boolean;
}
