import ReactDOM from 'react-dom';
import setupAxios from './setup/axios/SetupAxios';
import { Chart, registerables } from 'chart.js';

// Apps
import { App } from './app/App';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import './_metronic/assets/sass/style.scss';
import '../src/custom.scss';
// import './_metronic/assets/sass/style.react.scss';
setupAxios();

Chart.register(...registerables);

ReactDOM.render(
  <MetronicI18nProvider>
    <App />
  </MetronicI18nProvider>,
  document.getElementById('root')
);
