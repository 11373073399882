import { Column } from 'react-table';
import { FiTrash2 } from 'react-icons/fi';
export const rowData = (
  filterData: filterData[],
  deleteFilter: Function
): any => {
  const data = filterData.map((item: filterData) => ({
    name: item.name,
    action: (
      <div className="d-flex align-items-center gap-3">
        <FiTrash2
          className="icons-del"
          onClick={() => deleteFilter(item?.id)}
        />
      </div>
    ),
  }));
  return data;
};
type filterData = any;
type UserColumn = {
  name: string;
  action: any;
};
export const columns: Column<UserColumn>[] = [
  {
    Header: ' Name',
    accessor: 'name',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
