import React, { useEffect, useState } from 'react';
import './journals.scss';
import axios from 'axios';
import { GET_ALL_ARTICLES_URL } from '../../../../constants';
import JournalsList from './JournalsList';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const Journals = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const [articlesData, setArticlesData] = useState<articleType[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [pageState, setPageState] = useState<articlePageStateType>({
    filters: {
      text: '',
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  useEffect(() => {
    setBreadcrumbData([{ title: 'Journal', to: null }]);
  }, []);
  function getAllArticles() {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_ALL_ARTICLES_URL,
      data: pageState,
    }).then(({ data }) => {
      if (data) {
        setArticlesData(
          data.data.map((item: any) => {
            return {
              ...item,
              key: item.id,
            };
          })
        );
        setTotalRecords(data.totalRecords);
        setCurrentRecords(data.data.length);
      }
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getAllArticles();
  }, [pageState]);

  return (
    <React.Fragment>
      <JournalsList
        getAllArticles={getAllArticles}
        articlesData={articlesData}
        pageState={pageState}
        setPageState={setPageState}
        setIsLoading={setIsLoading}
        totalRecords={totalRecords}
        currentRecords={currentRecords}
      />
    </React.Fragment>
  );
};

export type articlePageStateType = {
  filters: {
    text: string;
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
};
export interface articleType {
  author: string;
  id: number;
  imageUrl: string;
  isBookmarked: boolean;
  publishDate: string;
  title: string;
}
