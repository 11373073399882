import '../../../../_metronic/assets/sass/core/components/artworkcards/artworkcard.scss';
type imageCardProps = {
  imageTitle: string;
  imageUrl: string;
};
export const ErrorCard = ({ imageTitle, imageUrl }: imageCardProps) => {
  return (
   
      <div className="error-card card card-custom gutter-b mb-5">
        <div className="card-body">
          <div className="card-image">
            <img src={imageUrl} alt="artwork" />
          </div>

          <div className="card-content">
            <a className="art-title" href={imageUrl} target="_blank">
              {imageTitle}
            </a>
          </div>
        </div>
      </div>
 
  );
};
