import axios from 'axios';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../../_metronic/assets/sass/core/components/artworkcards/artworkcard.scss';
import { BadgeStatus } from '../badges/BadgeStatus';
import broken from '../../../../_metronic/assets/images/broken.png';
import { useLocation } from 'react-router';
export const ArtworkCard = ({
  id,
  action,
  artimage,
  title,
  status,
  cost,
  artist,
  year,
}: ArtworksPropType) => {
  let location = useLocation();
  return (
    <div className="art-work-card card card-custom gutter-b mb-24">
      <div className="card-body">
        <div className="card-image">
          <Link to={{ pathname: `/artwork/${id}` }}>
            <img src={artimage || broken} alt="artwork" />
          </Link>
        </div>
        <div className="card-content">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="art-title">{artist}</div>
              <div className="art-title">{'' + title + ', ' + year}</div>

              {/* <div className="art-title">{year}</div> */}
            </div>

            {location.pathname === '/artworks' && (
              <span className="art-action">{action}</span>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="art-cost">{cost}</span>
            <BadgeStatus status={status} />
          </div>
        </div>
      </div>
    </div>
  );
};
interface ArtworksPropType {
  artimage: any;
  title: string;
  action: any;
  cost: any;
  status: any;
  id: any;
  artist: string;
  year: string;
}
