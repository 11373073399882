import { useEffect, useState } from 'react';
import '../../../_metronic/assets/sass/core/components/artuploader/artuploader.scss';
import { FormLabel } from '../../../_metronic/partials/content/Label/FormLabel';
import { ArtButton } from '../../../_metronic/partials/content/Button/ArtButton';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  ADD_ARTWORK_DATA_URL,
  dimensionUnits,
  GET_ALL_ARTISTS_INFO_URL,
  GET_ARTWORK_DETAILS_FOR_UPDATE,
  GET_ARTWORK_FILTERS_URL,
  priceUnits,
  UPLOAD_ARTWORK_COMPRESSED_IMAGE,
} from '../../../constants';
import { AiOutlineCamera } from 'react-icons/ai';
import { filterData } from '../../components/filters/ArtworkFilters';
import { SelectDropdown } from '../../../_metronic/partials/content/SelectDropdown/SelectDropdown';
import { FormSelect } from '../../../_metronic/partials/content/FormSelect/FormSelect';
import { Alert } from 'react-bootstrap';
import { UnitSelect } from '../../../_metronic/partials/content/FormSelect/UnitSelect';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { useUser } from '../../modules/auth/User-context';
import { FaCross } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import loading from '../../../assets/Artwork/loading.png';
import upload from '../../../assets/Artwork/upload.png';
import { Spinner } from 'react-bootstrap-v5';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const EditArtwork = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { id }: any = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const { dispatch } = useUser();
  const [path, setPath] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [compressedImages, setCompressedImages] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [artists, setArtists] = useState([{ label: '', value: '' }]);
  const [statuses] = useState([{ label: "Available", value: "Available" },
  { label: "Sold", value: "Sold" }]);
  const initialValues = {
    id: { value: 0, isError: false, errorMessage: 'ID is missing' },
    title: { value: '', isError: true, errorMessage: 'Title is missing' },
    artistId: { value: 0, isError: true, errorMessage: 'Artist is missing' },
    year: { value: '', isError: false, errorMessage: 'Year is missing' },
    price: { value: '', isError: true, errorMessage: 'Price is missing' },
    status: {
      value: 'available',
      isError: false,
      errorMessage: 'Status is missing',
    },
    height: { value: '', isError: true, errorMessage: 'Height is missing' },
    width: { value: '', isError: true, errorMessage: 'Width is missing' },
    depth: { value: '', isError: true, errorMessage: 'Depth is missing' },
    stockNo: {
      value: '',
      isError: true,
      errorMessage: 'Stock Number is missing',
    },
    editionNo: {
      value: '',
      isError: false,
      errorMessage: 'Edition Number is missing',
    },
    isFramed: {
      value: true,
      isError: false,
      errorMessage: 'Frame is missing',
    },
    isFeatured: {
      value: true,
      isError: false,
      errorMessage: 'Frame is missing',
    },
    isPrivate: {
      value: true,
      isError: false,
      errorMessage: 'Frame is missing',
    },
    frameWidth: {
      value: '',
      isError: true,
      errorMessage: 'Frame Width is missing',
    },
    frameHeight: {
      value: '',
      isError: true,
      errorMessage: 'Frame Height is missing',
    },
    frameDescription: {
      value: '',
      isError: false,
      errorMessage: 'Frame description is missing',
    },
    description: {
      value: '',
      isError: true,
      errorMessage: 'Artwork description is missing',
    },
    styles: { value: [], isError: true, errorMessage: 'Style is missing' },
    dimensionUnit: {
      value: 'cm',
      isError: false,
      errorMessage: 'Unit is missing',
    },
    priceCurrency: {
      value: 'USD',
      isError: false,
      errorMessage: 'Currency is missing',
    },
    categories: {
      value: [],
      isError: true,
      errorMessage: 'Category is missing',
    },
    medium: { value: '', isError: false, errorMessage: 'Medium is missing' },
    tags: { value: '', isError: false, errorMessage: 'Tag is missing' },
    image: { value: '', isError: true, errorMessage: 'Image is missing' },
    privateNotes: { value: '', isError: true }
  };
  const [artworkData, setArtworkData] =
    useState<{ [name: string]: any }>(initialValues);
  const [artWorkFilters, setArtWorkFilters] = useState({
    categories: [],
    styles: [],
    status: [],
  });
  const [selectedArtWorkFilters, setSelectedArtWorkFilters] = useState({
    categories: [],
    styles: [],
    status: '',
  });
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Artworks', to: '/artworks' },
      { title: id ? 'Edit' : 'Add', to: null },
    ]);
    if (id) {
      axios({
        method: 'GET',
        url: GET_ARTWORK_DETAILS_FOR_UPDATE + id,
      }).then(({ data }) => {
        const {
          id,
          name,
          artist,
          year,
          price,
          availability,
          priceCurrency,
          height,
          width,
          unit,
          styles,
          categories,
          medium,
          tags,
          imageUrl,
          frameHeight,
          frameWidth,
          framed,
          stockNumber,
          editionNumber,
          framingDetail,
          depth,
          description,
          isFeatured,
          isPrivate,
          privateNotes
        } = data.data;
        setArtworkData({
          ...artworkData,
          id: { ...artworkData.id, value: id, isError: false },
          title: { ...artworkData.title, value: name, isError: false },
          artistId: {
            ...artworkData.artistId,
            value: artist.id,
            isError: false,
          },
          year: { ...artworkData.year, value: year, isError: false },
          price: { ...artworkData.price, value: price, isError: false },
          frameHeight: {
            ...artworkData.frameHeight,
            value: frameHeight,
            isError: false,
          },
          frameWidth: {
            ...artworkData.frameWidth,
            value: frameWidth,
            isError: false,
          },
          framed: {
            ...artworkData.framed,
            value: framed,
            isError: false,
          },
          isFeatured: {
            ...artworkData.isFeatured,
            value: isFeatured,
            isError: false,
          },
          isPrivate: {
            ...artworkData.isPrivate,
            value: isPrivate,
            isError: false,
          },
          stockNo: {
            ...artworkData.stockNo,
            value: stockNumber,
            isError: false,
          },
          editionNo: {
            ...artworkData.editionNo,
            value: editionNumber,
            isError: false,
          },
          frameDescription: {
            ...artworkData.frameDescription,
            value: framingDetail,
            isError: false,
          },
          description: {
            ...artworkData.description,
            value: description,
            isError: false,
          },
          depth: { ...artworkData.depth, value: depth, isError: false },
          status: {
            ...artworkData.status,
            value: availability,
            isError: false,
          },
          height: { ...artworkData.height, value: height, isError: false },
          width: { ...artworkData.width, value: width, isError: false },
          styles: {
            ...artworkData.styles,
            value: styles.map((item: any) => parseInt(item.id)),
            isError: false,
          },
          categories: {
            ...artworkData.categories,
            value: categories.map((item: any) => parseInt(item.id)),
            isError: false,
          },
          medium: { ...artworkData.medium, value: medium, isError: false },
          tags: { ...artworkData.tags, value: tags, isError: false },
          image: { ...artworkData.image, value: imageUrl, isError: false },
          priceCurrency: {
            ...artworkData.priceCurrency,
            value: priceCurrency,
            isError: false,
          },
          dimensionUnit: {
            ...artworkData.dimensionUnit,
            value: unit,
            isError: false,
          },
          privateNotes: {
            ...artworkData.privateNotes, value: privateNotes, isError: false
          },
        });

        if (data.data?.artworkImageData && data.data?.artworkImageData.length > 0) {
          data.data.artworkImageData.forEach((element: { original: string; }) => {
            setPath(path => [...path, element.original]);
          });
        } else {
          setPath([]);
        }

        if (data.data?.artworkImageData && data.data?.artworkImageData.length > 0) {
          data.data.artworkImageData.forEach((element: { original: string; }) => {
            setImages(images => [...images, element.original]);
          });
        } else {
          setImages([]);
        }

        setCompressedImages(data.data?.artworkImageData || []);
        setSelectedArtWorkFilters({
          ...selectedArtWorkFilters,
          categories: data.data.categories.map((item: any) => {
            return { label: item.name, value: JSON.stringify(item.id) };
          }),
          styles: data.data.styles.map((item: any) => {
            return { label: item.name, value: JSON.stringify(item.id) };
          }),
        });
      });
    }
  }, []);
  function updateArtworkFilters(
    key: string,
    value: Array<string | number> | string
  ) {
    if (Array.isArray(value)) {
      setSelectedArtWorkFilters({
        ...selectedArtWorkFilters,
        [key]: [...value],
      });
      setArtworkData({
        ...artworkData,
        [key]: {
          ...artworkData[key],
          value: value.map((item: any) => parseInt(item.value)),
          isError: false,
        },
      });
    } else {
      setArtworkData({
        ...artworkData,
        [key]: {
          ...artworkData[key],
          value: value,
          isError: false,
        },
      });
    }
  }
  function updateStatus(selected: string) {
    setSelectedArtWorkFilters({ ...selectedArtWorkFilters, status: selected });
    setArtworkData({
      ...artworkData,
      status: { ...artworkData.status, value: selected, isError: false },
    });
  }
  useEffect(() => {
    axios({
      method: 'POST',
      url: GET_ARTWORK_FILTERS_URL,
    })
      .then(({ data }) => {
        setArtWorkFilters({
          categories: data.data.category.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          styles: data.data.style.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          status: data.data.status.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
      });
    axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_INFO_URL,
    }).then(({ data }) => {
      setArtists(
        data.data.map((item: any) => {
          return { label: item.name, value: item.id };
        })
      );
    });
  }, []);


  function setStatuses() {
    let statusArr = [{ label: "Available", value: "Available" },
    { label: "Sold", value: "Sold" }];

    return statusArr;
  };
  function uploadImage(index: number, e: any) {
    setUploading(true);
    const formData = new FormData();
    formData.append('formFile', e.target.files?.item(0) as Blob);
    const data = e.target.files?.item(0) as Blob;
    let a = URL.createObjectURL(data);
    setPath([...path, a]);
    axios({
      method: 'POST',
      url: UPLOAD_ARTWORK_COMPRESSED_IMAGE,
      data: formData,
    })
      .then(({ data }) => {
        setArtworkData({
          ...artworkData,
          image: { ...artworkData.image, value: data.data.url, isError: false },
        });
        setImages([...images, data.data.url]);
        let respCompressedImage = data.data.compressed && data.data.compressed.url ? data.data.compressed.url : ""
        setCompressedImages(compressedImages => [...compressedImages, { original: data.data.url, compressed: respCompressedImage }])
        setUploading(false);
      })
      .catch((error) => {
        setUploading(false);
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  function updateInputs(key: any, value: any) {
    setArtworkData({
      ...artworkData,
      [key]: { ...artworkData[key], value: value, isError: false },
    });
  }
  function saveChanges(e: any) {
    let errorFound = false;
    for (const key in artworkData) {
      if (artworkData[key].isError && !errorFound) {
        setAlertData({
          variant: 'danger',
          heading: 'Fields Missing',
          message: artworkData[key].errorMessage,
        });
        errorFound = true;
        setShowAlert(true);
      }
    }
    if (!errorFound) {
      const data = {
        id: artworkData.id.value ? parseInt(artworkData.id.value) : 0,
        title: artworkData.title.value,
        artistId: parseInt(artworkData.artistId.value),
        year: artworkData.year.value,
        price: parseInt(artworkData.price.value),
        status: artworkData.status.value,
        height: parseFloat(artworkData.height.value),
        width: parseFloat(artworkData.width.value),
        styles: artworkData.styles.value.toString(),
        categories: artworkData.categories.value.toString(),
        tags: artworkData.tags.value,
        medium: artworkData.medium.value,
        imageUrl: artworkData.image.value,
        imageUrls: images,
        compressedImageUrls: compressedImages,
        priceCurrency: artworkData.priceCurrency.value,
        dimensionUnit: artworkData.dimensionUnit.value,
        depth: artworkData.depth.value,
        framed: artworkData.isFramed.value,
        isFeatured: artworkData.isFeatured.value,
        isPrivate: artworkData.isPrivate.value,
        frameHeight: artworkData.frameHeight.value,
        frameWidth: artworkData.frameWidth.value,
        framingDetail: artworkData.frameDescription.value,
        stockNumber: artworkData.stockNo.value,
        editionNumber: artworkData.editionNo.value,
        description: artworkData.description.value,
        privateNotes: artworkData.privateNotes.value
      };
      // console.log('submit');
      // console.log(data);
      // return [];
      axios({
        method: 'POST',
        url: ADD_ARTWORK_DATA_URL,
        data: data,
      })
        .then(({ data }) => {
          if (data.isSuccess) {
            setAlertData({
              variant: 'success',
              message: 'Artwork Details have been saved successfully',
              heading: 'Success',
            });
            setShowAlert(true);
            if (!id) {
              setArtworkData(initialValues);
            }
          } else {
            setAlertData({
              variant: 'danger',
              message: data.message,
              heading: 'Failed',
            });
            setShowAlert(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            localStorage.clear();
            dispatch({ type: 'remove-user' });
          }
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: error.message,
          });
          setShowAlert(true);
        });
    }
  }
  function updateArtist(selected: any) {
    setArtworkData({
      ...artworkData,
      artistId: { ...artworkData.artistId, value: selected, isError: false },
    });
  }

  return (
    <div className="row">
      <div className="card mb-5 mb-xl-10">
        {showAlert && (
          <Alert
            variant={alertData.variant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>{alertData.heading}</Alert.Heading>
            <p>{alertData.message}</p>
          </Alert>
        )}
        <div className="card-header border-0">
          <div className="card-title m-0 d-flex justify-content-between gap-3">
            <h3 className="fw-bolder m-0">Artwork Details</h3>
          </div>
          <div className="card-title m-0 d-flex  gap-3">
            <ArtButton
              buttonText={id ? 'Save Changes' : 'Add Artwork'}
              styleclass={''}
              variant={'dark'}
              icon={''}
              hasIcon={false}
              onClick={saveChanges}
              disabled={uploading}
            />
          </div>
        </div>
        <div id="kt_account_profile_details" className="collapse show">
          <form className="form">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <div className=" col-lg-8 col-md-6 ">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <FormLabel label={'Title'} />
                      <input
                        type="text"
                        value={artworkData.title.value}
                        className="form-control form-control-lg"
                        placeholder="Title"
                        onChange={(e) => {
                          updateInputs('title', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={'Stock Number'} />
                      <input
                        type="text"
                        value={artworkData.stockNo.value}
                        className="form-control form-control-lg "
                        placeholder=" Stock Number"
                        onChange={(e) => {
                          updateInputs('stockNo', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={'Edition Number'} />
                      <input
                        type="text"
                        value={artworkData.editionNo.value}
                        className="form-control form-control-lg "
                        placeholder=" Edition Number"
                        onChange={(e) => {
                          updateInputs('editionNo', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={' Artist Name'} />
                      <FormSelect
                        options={artists}
                        updateFilter={updateArtist}
                        selected={
                          artworkData.artistId.value
                            ? artworkData.artistId.value.toString()
                            : ''
                        }
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={'Year'} />
                      <input
                        type="number"
                        value={artworkData.year.value}
                        className="form-control form-control-lg "
                        placeholder=" Year"
                        onChange={(e) => {
                          updateInputs('year', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <div className="input-group mb-3">
                        <FormLabel label={'Price'} />
                        <div className="input-group">
                          <span className="input-group-text " id="basic-addon1">
                            <UnitSelect
                              options={priceUnits}
                              isBordered={true}
                              selected={artworkData.priceCurrency.value}
                              handleChange={updateInputs}
                              title="priceCurrency"
                            />
                          </span>
                          <input
                            type="number"
                            value={artworkData.price.value}
                            className="form-control form-control-lg "
                            placeholder=" Price"
                            onChange={(e) => {
                              updateInputs('price', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={'Status'} />
                      {/* <input
                        type="text"
                        value={artworkData.status.value}
                        className="form-control form-control-lg "
                        readOnly
                      /> */}
                      <FormSelect
                        options={statuses}
                        updateFilter={updateStatus}
                        selected={
                          artworkData.status.value
                        }
                      />
                    </div>
                    <h6 className="mt-4">Dimensions</h6>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={'Height'} />
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <UnitSelect
                            options={dimensionUnits}
                            isBordered={true}
                            selected={artworkData.dimensionUnit.value}
                            handleChange={updateInputs}
                            title="dimensionUnit"
                          />
                        </span>
                        <input
                          type="number"
                          value={artworkData.height.value}
                          className="form-control form-control-lg "
                          placeholder=" Height"
                          onChange={(e) => {
                            updateInputs('height', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 fv-row">
                      <FormLabel label={'Width'} />
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <UnitSelect
                            options={dimensionUnits}
                            isBordered={true}
                            selected={artworkData.dimensionUnit.value}
                            handleChange={updateInputs}
                            title="dimensionUnit"
                          />
                        </span>
                        <input
                          type="number"
                          value={artworkData.width.value}
                          className="form-control form-control-lg "
                          placeholder=" Width"
                          onChange={(e) => {
                            updateInputs('width', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-6">
                      <FormLabel label={'Depth'} />
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <UnitSelect
                            options={dimensionUnits}
                            isBordered={true}
                            selected={artworkData.dimensionUnit.value}
                            handleChange={updateInputs}
                            title="dimensionUnit"
                          />
                        </span>
                        <input
                          type="number"
                          value={artworkData.depth.value}
                          className="form-control form-control-lg "
                          placeholder="Depth"
                          onChange={(e) => {
                            updateInputs('depth', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-6">
                      <FormLabel label={'Medium'} />
                      <input
                        type="text"
                        value={artworkData.medium.value}
                        className="form-control form-control-lg "
                        placeholder=" Comma Separated Meduim"
                        onChange={(e) => {
                          updateInputs('medium', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-6">
                      <SelectDropdown
                        filters={selectedArtWorkFilters.styles}
                        options={artWorkFilters.styles}
                        setFilters={updateArtworkFilters}
                        title={'Styles'}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <SelectDropdown
                        filters={selectedArtWorkFilters.categories}
                        options={artWorkFilters.categories}
                        setFilters={updateArtworkFilters}
                        title={'Categories'}
                      />
                    </div>

                    <div className="col-lg-6 mb-6">
                      <FormLabel label={'Tags'} />
                      <input
                        type="text"
                        value={artworkData.tags.value}
                        className="form-control form-control-lg "
                        placeholder=" Comma Separated Tags"
                        onChange={(e) => {
                          updateInputs('tags', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 d-flex align-items-center mb-6 mt-6">
                      <div className="form-check ">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          checked={artworkData.isFramed.value}
                          id="framed"
                          onChange={(e) => {
                            updateInputs('isFramed', e.target.checked);
                          }}
                        />
                        <label className="form-check-label" htmlFor="framed">
                          <b>Is Artwork Framed</b>
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input ms-2"
                          type="checkbox"
                          checked={artworkData.isFeatured.value}
                          id="featured"
                          onChange={(e) => {
                            updateInputs('isFeatured', e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label ms-1"
                          htmlFor="featured"
                        >
                          <b>Is Artwork Featured</b>
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input ms-2"
                          type="checkbox"
                          checked={artworkData.isPrivate.value}
                          id="private"
                          onChange={(e) => {
                            updateInputs('isPrivate', e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label ms-1"
                          htmlFor="private"
                        >
                          <b>Is Artwork Private</b>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-6">
                      <FormLabel label={'Frame Height'} />
                      <input
                        type="number"
                        value={artworkData.frameHeight.value}
                        className="form-control form-control-lg "
                        placeholder="Height"
                        onChange={(e) => {
                          updateInputs('frameHeight', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-6">
                      <FormLabel label={'Frame Width'} />
                      <input
                        type="number"
                        value={artworkData.frameWidth.value}
                        className="form-control form-control-lg "
                        placeholder="Width"
                        onChange={(e) => {
                          updateInputs('frameWidth', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-6">
                      <FormLabel label={'Frame Description'} />
                      <input
                        type="text"
                        value={artworkData.frameDescription.value}
                        className="form-control form-control-lg "
                        placeholder="Description"
                        onChange={(e) => {
                          updateInputs('frameDescription', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <FormLabel label={'Description'} />
                      <textarea
                        cols={30}
                        rows={5}
                        value={artworkData.description.value}
                        className="form-control form-control-lg "
                        placeholder="Artwork Description"
                        onChange={(e) => {
                          updateInputs('description', e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-12">
                      <FormLabel label={'Private Notes'} />
                      <textarea
                        cols={30}
                        rows={5}
                        value={artworkData.privateNotes.value}
                        className="form-control form-control-lg "
                        placeholder="Private Notes"
                        onChange={(e) => {
                          updateInputs('privateNotes', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 align-items-start col-md-6 ">
                  {uploading ? (
                    <div className="d-flex justify-content-center align-items-start w-100 h-100">
                      <Spinner animation="grow" />
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap gap-2 ">
                      <div className="imageUploaderWrapper mb-5">
                        <div className="square-upload">
                          <img
                            src={path[0]?.length > 1 ? path[0] : upload}
                            alt=""
                          />
                        </div>
                        {path[0]?.length > 1 && (
                          <div
                            className="crossImage"
                            onClick={() => {
                              setPath(
                                path.filter(
                                  (item, index, array) => item != array[0]
                                )
                              );
                              setImages(
                                images.filter(
                                  (item, index, array) => item != array[0]
                                )
                              );
                              setCompressedImages(
                                compressedImages.filter(
                                  (item, index, array) => item != array[0]
                                )
                              );
                            }}
                          >
                            <MdCancel />
                          </div>
                        )}
                        <label className="pImage">
                          <AiOutlineCamera className="uploadButton" />
                          <input
                            className="fileUpload"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadImage(0, e);
                            }}
                          />
                        </label>
                      </div>
                      <div className="imageUploaderWrapper mb-5">
                        <div className="square-upload">
                          <img
                            src={path[1]?.length > 1 ? path[1] : upload}
                            alt=""
                          />
                        </div>
                        {path[1]?.length > 1 && (
                          <div
                            className="crossImage"
                            onClick={() => {
                              setPath(
                                path.filter(
                                  (item, index, array) => item != array[1]
                                )
                              );
                              setImages(
                                images.filter(
                                  (item, index, array) => item != array[1]
                                )
                              );
                              setCompressedImages(
                                compressedImages.filter(
                                  (item, index, array) => item != array[1]
                                )
                              );
                            }}
                          >
                            <MdCancel />
                          </div>
                        )}
                        <label className="pImage">
                          <AiOutlineCamera className="uploadButton" />
                          <input
                            className="fileUpload"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadImage(1, e);
                            }}
                          />
                        </label>
                      </div>
                      <div className="imageUploaderWrapper mb-5">
                        <div className="square-upload">
                          <img
                            src={path[2]?.length > 1 ? path[2] : upload}
                            alt=""
                          />
                        </div>
                        {path[2]?.length > 1 && (
                          <div
                            className="crossImage"
                            onClick={() => {
                              setPath(
                                path.filter(
                                  (item, index, array) => item != array[2]
                                )
                              );
                              setImages(
                                images.filter(
                                  (item, index, array) => item != array[2]
                                )
                              );
                              setCompressedImages(
                                compressedImages.filter(
                                  (item, index, array) => item != array[2]
                                )
                              );
                            }}
                          >
                            <MdCancel />
                          </div>
                        )}
                        <label className="pImage">
                          <AiOutlineCamera className="uploadButton" />
                          <input
                            className="fileUpload"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadImage(2, e);
                            }}
                          />
                        </label>
                      </div>
                      <div className="imageUploaderWrapper mb-5">
                        <div className="square-upload">
                          <img
                            src={path[3]?.length > 1 ? path[3] : upload}
                            alt=""
                          />
                        </div>
                        {path[3]?.length > 1 && (
                          <div
                            className="crossImage"
                            onClick={() => {
                              setPath(
                                path.filter(
                                  (item, index, array) => item != array[3]
                                )
                              );
                              setImages(
                                images.filter(
                                  (item, index, array) => item != array[3]
                                )
                              );
                              setCompressedImages(
                                compressedImages.filter(
                                  (item, index, array) => item != array[3]
                                )
                              );
                            }}
                          >
                            <MdCancel />
                          </div>
                        )}
                        <label className="pImage">
                          <AiOutlineCamera className="uploadButton" />
                          <input
                            className="fileUpload"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadImage(3, e);
                            }}
                          />
                        </label>
                      </div>
                      <div className="imageUploaderWrapper mb-5">
                        <div className="square-upload">
                          <img
                            src={path[4]?.length > 1 ? path[4] : upload}
                            alt=""
                          />
                        </div>
                        {path[4]?.length > 1 && (
                          <div
                            className="crossImage"
                            onClick={() => {
                              setPath(
                                path.filter(
                                  (item, index, array) => item != array[4]
                                )
                              );
                              setImages(
                                images.filter(
                                  (item, index, array) => item != array[4]
                                )
                              );
                              setCompressedImages(
                                compressedImages.filter(
                                  (item, index, array) => item != array[4]
                                )
                              );
                            }}
                          >
                            <MdCancel />
                          </div>
                        )}
                        <label className="pImage">
                          <AiOutlineCamera className="uploadButton" />
                          <input
                            className="fileUpload"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadImage(4, e);
                            }}
                          />
                        </label>
                      </div>
                      <div className="imageUploaderWrapper mb-5">
                        <div className="square-upload">
                          <img
                            src={path[5]?.length > 1 ? path[5] : upload}
                            alt=""
                          />
                        </div>
                        {path[5]?.length > 1 && (
                          <div
                            className="crossImage"
                            onClick={() => {
                              setPath(
                                path.filter(
                                  (item, index, array) => item != array[5]
                                )
                              );
                              setImages(
                                images.filter(
                                  (item, index, array) => item != array[5]
                                )
                              );
                              setCompressedImages(
                                compressedImages.filter(
                                  (item, index, array) => item != array[5]
                                )
                              );
                            }}
                          >
                            <MdCancel />
                          </div>
                        )}
                        <label className="pImage">
                          <AiOutlineCamera className="uploadButton" />
                          <input
                            className="fileUpload"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadImage(4, e);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
