export const Stats = (props: StatsPropTypes) => {
  return (
    <div className={`${props.styleclass} statskpi-wrapper p-24 mb-7`}>
      <div className="upper-section">
      <span className="count">{props.count}</span>
        <span className="title">{props.title}</span>
      </div>
      <div className="bottom">
        <span className="fw-bold fs-6 line-height-1 me-1">{props.current}</span>
        <span className=" fs-6"> {props.monthly}</span>
      </div>
    </div>
  );
};
interface StatsPropTypes {
  icon: any;
  title: string;
  count: string;
  monthly: any;
  styleclass: any;
  current: any;
}
