import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  GET_ALL_USERS_URL,
  GET_ARTIST_DETAILS_URL,
  GET_ALL_ARTWORKS_URL,
  GET_ARTWORK_FILTERS_URL,
  GET_USER_FILTERS_URL,
  DEACTIVATE_CLIENT_URL,
  GET_ALL_ARTISTS_INFO_URL,
} from '../../../../constants';
import { artwork } from '../../artworks/utils';
import { ArtworksList } from '../../artworks/ArtworksList';
import { Spinner } from 'react-bootstrap-v5';
import { ArtistDetailsComponent } from './ArtistDetailsComponent';
import { columns, rowData } from '../../artworks/Addartwork/utils/user-data';
import { ClientList } from '../../Clients/ClientsList';
import {
  artworkFilterType,
  filterData,
  selectedArtworkFilterType,
  sizeType,
} from '../../../components/filters/ArtworkFilters';
import { userFilterType } from '../../../components/filters/UserFilters';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ArtistDetails = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { dispatch } = useUser();
  const [listView, setListView] = useState(false);
  const [artistData, setArtistData] = useState<artistType>();
  const [isLoading, setIsLoading] = useState(false);
  const { id }: any = useParams();
  const [activeTab, setActiveTab] = useState('totalArtworks');
  const [allArtWorksData, setAllArtWorksData] = useState<artwork[]>([]);
  const [likedArtWorksData, setLikedArtWorksData] = useState<artwork[]>([]);
  const [allArtworkCurrentRecords, setAllArtworkCurrentRecords] = useState(0);
  const [allArtworkTotalRecords, setAllArtworkTotalRecords] = useState(0);
  const [likedArtworkCurrentRecords, setLikedArtworkCurrentRecords] =
    useState(0);
  const [likedArtworkTotalRecords, setLikedArtworkTotalRecords] = useState(0);
  const [followingClientsData, setFollowingClientsData] = useState([]);
  const [clientTotalRecords, setClientTotalRecords] = useState(0);
  const [clientCurrentRecords, setClientCurrentRecords] = useState(0);
  const [likedArtworkPageState, setLikedArtworkPageState] = useState({
    filters: {
      text: '',
      categoryIds: [],
      artists: [],
      styleIds: [],
      status: [],
      price: [0, 125000],
      size: [],
      year: [1940, 2020],
      likedArtworkArtistId: parseInt(id),
      // likedArtwork: true,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    }, //,
  });
  const [allArtworkPageState, setAllArtworkPageState] = useState<any>({
    filters: {
      text: '',
      categoryIds: [],
      artists: [],
      styleIds: [],
      status: [],
      price: [0, 125000],
      size: [],
      year: [1940, 2020],
      artistId: parseInt(id),
      // likedArtwork: false,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    }, //,
  });
  const [clientPageState, setClientPageState] = useState({
    filters: {
      text: '',
      categoryIds: [],
      styleIds: [],
      status: [],
      artistId: parseInt(id),
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [artWorkFilters, setArtWorkFilters] = useState<artworkFilterType>({
    categories: [],
    styles: [],
    status: [],
    price: [],
    size: [],
    artists: [],
  });
  const [selectedArtWorkFilters, setSelectedArtWorkFilters] =
    useState<selectedArtworkFilterType>({
      categories: [],
      artists: [],
      styles: [],
      status: [],
      price: [],
      size: [],
      year: [],
    });
  function updateArtworkFilters(key: string, value: any) {
    setSelectedArtWorkFilters({ ...selectedArtWorkFilters, [key]: [...value] });
  }
  async function getAllFilters() {
    let filters: any = await axios({
      method: 'POST',
      url: GET_ARTWORK_FILTERS_URL,
    });
    let filtersData = {
      categories: filters.data.data.category.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      styles: filters.data.data.style.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      status: filters.data.data.status.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      price: filters.data.data.price.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      size: filters.data.data.size.map((item: sizeType) => {
        return {
          label: item.value.key + ' (' + item.value.value + ')',
          value: item.key,
        };
      }),
    };
    // });
    filters = await axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_INFO_URL,
    });

    let artists = filters.data.data.map((item: any) => {
      return { label: item.name, value: item.id };
      // }),
    });
    // console.log(filtersData, artists);
    setArtWorkFilters({
      categories: filtersData.categories,
      price: filtersData.price,
      size: filtersData.size,
      status: filtersData.status,
      styles: filtersData.styles,
      artists: artists,
    });
  }
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Artists', to: '/artists' },
      { title: 'Details', to: null },
    ]);
    getAllFilters();
  }, []);
  const [userFilters, setUserFilters] = useState<userFilterType>({
    categories: [],
    styles: [],
    status: [],
  });
  const [selectedUserFilters, setSelectedUserFilters] = useState({
    categories: [],
    styles: [],
    status: [],
  });
  function updateUserFilters(key: string, value: any) {
    setSelectedUserFilters({ ...selectedUserFilters, [key]: [...value] });
  }
  useEffect(() => {
    axios({
      method: 'POST',
      url: GET_USER_FILTERS_URL,
    })
      .then(({ data }) => {
        setUserFilters({
          categories: data.data.category.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          styles: data.data.style.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          status: data.data.status.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
      });
  }, []);
  useEffect(() => {
    axios({
      method: 'POST',
      url: GET_ARTIST_DETAILS_URL + id,
    }).then(({ data }) => {
      setArtistData(data.data);
    });
  }, []);
  useEffect(() => {
    if (activeTab === 'likedArtworks') {
      let data = {
        filters: {
          likedArtworkArtistId: parseInt(id),
          artistId: likedArtworkPageState.filters.artists,
          text: likedArtworkPageState.filters.text,
          categoryIds: likedArtworkPageState.filters.categoryIds,
          styleIds: likedArtworkPageState.filters.styleIds,
          status: likedArtworkPageState.filters.status,
          size: likedArtworkPageState.filters.size,
          startPrice:
            likedArtworkPageState.filters.price[0] == 0
              ? -1
              : likedArtworkPageState.filters.price[0] == 125000
              ? -2
              : likedArtworkPageState.filters.price[0],
          endPrice:
            likedArtworkPageState.filters.price[1] &&
            likedArtworkPageState.filters.price[1] === 125000
              ? -1
              : likedArtworkPageState.filters.price[1] === 0
              ? -2
              : likedArtworkPageState.filters.price[1],
          startYear:
            likedArtworkPageState.filters.year[0] &&
            likedArtworkPageState.filters.year[0] <= 1950
              ? -1
              : likedArtworkPageState.filters.year[0] >= 2020
              ? -2
              : likedArtworkPageState.filters.year[0],
          endYear:
            likedArtworkPageState.filters.year[1] &&
            likedArtworkPageState.filters.year[1] == 2020
              ? -1
              : likedArtworkPageState.filters.year[1] <= 1950
              ? -2
              : likedArtworkPageState.filters.year[1],
        },
        pageInfo: {
          pageSize: likedArtworkPageState.pageInfo.pageSize,
          pageNumber: likedArtworkPageState.pageInfo.pageNumber,
        }, //,
      };
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_ARTWORKS_URL,
        data: data,
      }).then(({ data }) => {
        if (data) {
          setLikedArtWorksData(
            data.data?.map((item: any) => {
              return {
                ...item,
                key: item.id,
              };
            })
          );
          setLikedArtworkTotalRecords(data.totalRecords);
          setLikedArtworkCurrentRecords(data.data?.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, likedArtworkPageState]);
  useEffect(() => {
    if (activeTab === 'totalArtworks') {
      allArtworkPageState.filters.artists.push(parseInt(id));
      setIsLoading(true);
      let data: any = {
        filters: {
          artistId: allArtworkPageState.filters.artists,
          text: allArtworkPageState.filters.text,
          categoryIds: allArtworkPageState.filters.categoryIds,
          styleIds: allArtworkPageState.filters.styleIds,
          status: allArtworkPageState.filters.status,
          size: allArtworkPageState.filters.size,
          startPrice:
            allArtworkPageState.filters.price[0] &&
            allArtworkPageState.filters.price[0] == 0
              ? -1
              : allArtworkPageState.filters.price[0],
          endPrice:
            allArtworkPageState.filters.price[1] &&
            allArtworkPageState.filters.price[1] === 125000
              ? -1
              : allArtworkPageState.filters.price[1] === 0
              ? -2
              : allArtworkPageState.filters.price[1],
          startYear:
            allArtworkPageState.filters.year[0] &&
            allArtworkPageState.filters.year[0] <= 1950
              ? -1
              : allArtworkPageState.filters.year[0] >= 2020
              ? -2
              : allArtworkPageState.filters.year[0],
          endYear:
            allArtworkPageState.filters.year[1] &&
            allArtworkPageState.filters.year[1] == 2020
              ? -1
              : allArtworkPageState.filters.year[1] <= 1950
              ? -2
              : allArtworkPageState.filters.year[1],
          // ArtistId: parseInt(id),
        },
        pageInfo: {
          pageSize: allArtworkPageState.pageInfo.pageSize,
          pageNumber: allArtworkPageState.pageInfo.pageNumber,
        }, //,
      };
      axios({
        method: 'POST',
        url: GET_ALL_ARTWORKS_URL,
        data: data,
      }).then(({ data }) => {
        if (data) {
          setAllArtWorksData(
            data.data?.map((item: any) => {
              return {
                ...item,
                key: item.id,
              };
            })
          );
          setAllArtworkTotalRecords(data.totalRecords);
          setAllArtworkCurrentRecords(data.data?.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, allArtworkPageState]);
  useEffect(() => {
    if (activeTab === 'followers') {
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_USERS_URL,
        data: clientPageState,
      }).then(({ data }) => {
        if (data) {
          setFollowingClientsData(data.data);
          setClientTotalRecords(data.totalRecords);
          setClientCurrentRecords(data.data.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, clientPageState]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  function makeArtistInactive(id: number) {
    axios({
      method: 'GET',
      url: DEACTIVATE_CLIENT_URL + id,
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'User Deactivated Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body ">
            <ArtistDetailsComponent
              artistData={artistData}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <div className="stats-tables">
              {activeTab === 'totalArtworks' && (
                <ArtworksList
                  selectedArtWorkFilters={selectedArtWorkFilters}
                  updateArtworkFilters={updateArtworkFilters}
                  artWorkFilters={artWorkFilters}
                  artWorksData={allArtWorksData}
                  pageState={allArtworkPageState}
                  setPageState={setAllArtworkPageState}
                  setIsLoading={setIsLoading}
                  totalRecords={allArtworkTotalRecords}
                  currentRecords={allArtworkCurrentRecords}
                  getAllArtworks={undefined}
                  listView={listView}
                  setListView={setListView}
                />
              )}
              {activeTab === 'likedArtworks' && (
                <ArtworksList
                  selectedArtWorkFilters={selectedArtWorkFilters}
                  updateArtworkFilters={updateArtworkFilters}
                  artWorkFilters={artWorkFilters}
                  artWorksData={likedArtWorksData}
                  pageState={likedArtworkPageState}
                  setPageState={setLikedArtworkPageState}
                  setIsLoading={setIsLoading}
                  totalRecords={likedArtworkTotalRecords}
                  currentRecords={likedArtworkCurrentRecords}
                  getAllArtworks={undefined}
                  listView={listView}
                  setListView={setListView}
                />
              )}
              {activeTab === 'followers' && (
                <ClientList
                  selectedUserFilters={selectedUserFilters}
                  updateUserFilters={updateUserFilters}
                  userFilters={userFilters}
                  currentRecords={clientCurrentRecords}
                  userData={followingClientsData}
                  columns={columns}
                  setIsLoading={setIsLoading}
                  totalRecords={clientTotalRecords}
                  pageState={clientPageState}
                  setPageState={setClientPageState}
                  getAllClients={undefined}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};
export type artistType = {
  artworkCount: number;
  birthDate: string;
  city: string;
  country: string;
  deathDate: string;
  followingClientCount: number;
  id: number;
  imageUrl: string;
  likedArtworkCount: number;
  name: string;
  state: string;
  description: string;
};
export type pageStateType = {
  filters: {
    text: string;
    categoryIds: never[];
    styleIds: never[];
    status: never[];
    price: never[];
    size: never[];
    artistId: number;
    likedArtwork: boolean;
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
};
