import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { reservationType } from './ArtworkReservationListing';
export const rowData = (userData: reservationType[]): any => {
  const data = userData.map((item: reservationType) => ({
    image: (
      <Link
        to={{
          pathname: `/reservationdetails/${item.id}/${item.userId}`,
        }}
      >
        <div className="kpi-cards ">
          <div className="artwork-image-Wrapper">
            <img src={item.imageInfo.imagePath} alt="img" />
          </div>
        </div>
      </Link>
    ),
    artist: (
      // <Link to="/reservationdetails">
      <div className="d-flex align-items-center cursor-pointer">
        <div className="d-flex justify-content-start flex-column">
          {/* <a className="table-data "> */}
          <span className="fw-bold d-block fs-7">{item.artistName}</span>
          {/* </a> */}
        </div>
      </div>
      // </Link>
    ),
    title: (
      // <Link to=" ">
      <div className="d-flex align-items-center cursor-pointer">
        <div className="d-flex justify-content-start flex-column">
          <a className="table-data ">
            <span className="fw-bold d-block fs-7">{item.title}</span>
          </a>
        </div>
      </div>
      // </Link>
    ),
    email: item.clientEmail,
    clientname: item.clientName,
    queuecount: item.queueNumber,
    timestamp: new Date(item.reservationDate).toDateString(),
  }));
  return data;
};
type UserColumn = {
  image: any;
  artist: any;
  title: any;
  email: any;
  count: any;
  timestamp: any;
  clientname: any;
  queuecount: any;
};
export const columns: Column<UserColumn>[] = [
  {
    Header: 'Artwork Image',
    accessor: 'image',
  },
  {
    Header: 'Artwork Artist',
    accessor: 'artist',
  },
  {
    Header: 'Artwork Title',
    accessor: 'title',
  },
  {
    Header: 'Client Name',
    accessor: 'clientname',
  },
  {
    Header: 'Client Email',
    accessor: 'email',
  },
  {
    Header: 'Queue Count',
    accessor: 'queuecount',
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
];
