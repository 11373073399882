import { Link } from 'react-router-dom';
import { useUser } from '../../../../app/modules/auth/User-context';
import blank from '../../../assets/images/blank.png';
import { useHistory } from 'react-router-dom';

interface HeaderUserMenuProps {
  menuVisibility: boolean;
  setMenuVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeaderUserMenu = ({
  menuVisibility,
  setMenuVisibility,
}: HeaderUserMenuProps) => {
  const history = useHistory();
  const { state, dispatch } = useUser();
  const logout = () => {
    dispatch({ type: 'remove-user' });
    localStorage.clear();
    // history.replace('/');
    // window.location.href = '/';
  };
  return (
    <div
      className={`menu menu-sub user-menu menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px ${
        menuVisibility ? 'show' : ''
      }`}
      data-kt-menu="true"
    >
      <div
        className="menu-item px-3"
        onClick={() => {
          setMenuVisibility(false);
        }}
      >
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={state.imageUrl ? state.imageUrl : blank} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {state.fullName}
            </div>
            <a href="" className="fw-bold text-muted text-hover-primary fs-7">
              {state.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div
        className="menu-item px-5"
        onClick={() => {
          setMenuVisibility(false);
        }}
      >
        <Link
          to={{ pathname: `/edituser/${state.id}` }}
          className="menu-link px-5 text-dark"
        >
          My Profile
        </Link>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item d-flex align-items-start justify-content-start px-5">
        <button
          className="btn btn-default btn-block bg-white text-danger"
          onClick={logout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
