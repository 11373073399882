import { useEffect, useState } from 'react';
import ListTable from '../../../components/listing-table';
import { rowData, columns } from './inquiry-data';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';
import axios from 'axios';
import { GET_INQUIRY_ARTWORKS } from '../../../../constants';
import { Pagination } from '../../../../_metronic/partials/content/pagination/Pagination';
import { Form } from 'react-bootstrap';

type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ArtInquirynListing = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { dispatch } = useUser();
  const [inquiryData, setInquiryData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [pageState, setPageState] = useState({
    filters: {
      status: 'All',
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    }, //,
  });
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }
  useEffect(() => {
    setBreadcrumbData([{ title: 'Reservations', to: null }]);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_INQUIRY_ARTWORKS,
      data: pageState,
    })
      .then(({ data }) => {
        if (data) {
          setInquiryData(data.data);
          setTotalRecords(data.totalRecords);
          setCurrentRecords(data.data?.length);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
          setIsLoading(false);
        }
      });
  }, [pageState]);
  return (
    <div className="card">
      <div className="card-header border-1 bg-white mb-5 align-items-center justify-content-between">
        <div className="card-title">
          <h3 className="fw-bolder m-0">Artwork Inquiry</h3>
        </div>
        <Form>
          {['radio'].map((type) => (
            <div key={`inline-${type}`}>
              <Form.Check
                checked={pageState.filters.status === 'All'}
                onChange={(e: any) =>
                  setPageState({
                    ...pageState,
                    filters: { status: e.target.labels[0].innerText },
                  })
                }
                inline
                label="All"
                name="group1"
                type={'radio'}
                id={`inline-${type}-3`}
              />
              <Form.Check
                checked={pageState.filters.status === 'Resolved'}
                onChange={(e: any) =>
                  setPageState({
                    ...pageState,
                    filters: { status: e.target.labels[0].innerText },
                  })
                }
                inline
                label="Resolved"
                name="group1"
                type={'radio'}
                id={`inline-${type}-1`}
              />
              <Form.Check
                checked={pageState.filters.status === 'Open'}
                onChange={(e: any) =>
                  setPageState({
                    ...pageState,
                    filters: { status: e.target.labels[0].innerText },
                  })
                }
                inline
                label="Open"
                name="group1"
                type={'radio'}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </Form>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <ListTable columns={columns} userData={rowData(inquiryData)} />
        </div>
      </div>
      <Pagination
        currentRecords={currentRecords}
        setIsLoading={setIsLoading}
        totalRecords={totalRecords}
        pageSize={pageState.pageInfo.pageSize}
        pageNumber={pageState.pageInfo.pageNumber}
        handlePageSize={handlePageSize}
        handlePageNumber={handlePageNumber}
      />
    </div>
  );
};
