import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces';
import { SelectDropdown } from '../../../_metronic/partials/content/SelectDropdown/SelectDropdown';
import { IoFilter } from 'react-icons/io5';
import { ArtButton } from '../../../_metronic/partials/content/Button/ArtButton';
import { Range } from 'rc-slider';
export const ArtworkFilter = ({
  handleFiltersChange,
  selectedArtWorkFilters,
  artWorkFilters,
  updateArtworkFilters,
}: propType) => {
  // const [value, setValue] = React.useState(30);
  const [minDate, setMinDate] = useState(1940);
  const [maxDate, setMaxDate] = useState(2020);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPricee] = useState(125000);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <React.Fragment>
      <ArtButton
        buttonText={'Filters'}
        styleclass={'d-inline-flex align-items-center'}
        variant={'outline-dark'}
        icon={<IoFilter />}
        hasIcon={true}
        onClick={handleShow}
      />
      <Modal className="right" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-filter">
          <SelectDropdown
            filters={selectedArtWorkFilters.artists}
            options={artWorkFilters.artists}
            setFilters={updateArtworkFilters}
            title={'Artists'}
          />
          <SelectDropdown
            filters={selectedArtWorkFilters.categories}
            options={artWorkFilters.categories}
            setFilters={updateArtworkFilters}
            title={'Categories'}
          />
          <SelectDropdown
            filters={selectedArtWorkFilters.styles}
            options={artWorkFilters.styles}
            setFilters={updateArtworkFilters}
            title={'Styles'}
          />
          <SelectDropdown
            filters={selectedArtWorkFilters.status}
            options={artWorkFilters.status}
            setFilters={updateArtworkFilters}
            title={'Status'}
          />
          <SelectDropdown
            filters={selectedArtWorkFilters.size}
            options={artWorkFilters.size}
            setFilters={updateArtworkFilters}
            title={'Size'}
          />
          <h6>Year</h6>
          <div className="mt-5">
            <Range
              min={minDate}
              max={maxDate}
              marks={{
                1940: <p> {'<'}1960</p>,
                1960: <p>1960</p>,
                1980: <p>1980</p>,
                2000: <p>2000</p>,
                2020: <p>2000{'<'} </p>,
              }}
              step={10}
              defaultValue={[minDate, maxDate]}
              onAfterChange={(value) => {
                updateArtworkFilters('year', value);
              }}
            />
          </div>
          <h6 className="mt-20">Price (£)</h6>
          <div className="mt-5">
            <Range
              min={minPrice}
              max={maxPrice}
              // dots
              marks={{
                0: <p> 0</p>,
                25000: <p>25K</p>,
                50000: <p>50K</p>,
                75000: <p>75K</p>,
                100000: <p> 100K</p>,
                125000: <p>100K{'<'} </p>,
              }}
              step={25000}
              defaultValue={[minPrice, maxPrice]}
              onAfterChange={(value) => {
                updateArtworkFilters('price', value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ArtButton
            buttonText={'Cancel'}
            styleclass={'w-120'}
            variant={'light'}
            icon={false}
            hasIcon={false}
            onClick={handleClose}
          />
          <ArtButton
            buttonText={'Apply Filters'}
            styleclass={''}
            variant={'dark'}
            icon={false}
            hasIcon={false}
            onClick={() => {
              handleFiltersChange(selectedArtWorkFilters);
              setShow(false);
            }}
          />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
interface propType {
  handleFiltersChange: Function;
  selectedArtWorkFilters: selectedArtworkFilterType;
  updateArtworkFilters: Function;
  artWorkFilters: artworkFilterType;
}
export interface artworkFilterType {
  artists: Option[];
  categories: Option[];
  styles: Option[];
  status: Option[];
  price: Option[];
  size: sizeType[];
}
export interface selectedArtworkFilterType {
  year: number[];
  categories: Option[];
  artists: Option[];
  styles: Option[];
  status: Option[];
  price: number[];
  size: Option[];
}
export interface filterData {
  value: string;
  key: number;
}
export interface sizeType {
  key: string;
  value: {
    key: string;
    value: string;
  };
}
