import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
export const rowData = (
  userData: UserData[],
  makeArtistInactive: Function,
  location: string
): any => {
  return userData?.map((item: UserData) => {
    return {
      artistname: (
        <Link to={{ pathname: `/artist/${item.id}` }}>
          <div className="d-flex align-items-center">
            <div className="client-Avatar">
              <img src={item.imageUrl} alt="img" />
            </div>
            <div className="d-flex justify-content-start">
              <span className="fw-bold text-dark d-block fs-7">
                {item.name}{' '}
              </span>
            </div>
          </div>
        </Link>
      ),
      counts: item.artworkCount,
      action:
        location === '/artists' ? (
          <div className="d-flex align-items-center gap-3">
            <Link to={{ pathname: `/editartist/${item.id}` }}>
              <FiEdit className="icons-del" />
            </Link>{' '}
            <FiTrash2
              className="icons-del"
              onClick={() => {
                makeArtistInactive(item.id);
              }}
            />
          </div>
        ) : (
          <></>
        ),
    };
  });
};
type UserData = {
  name: string;
  id: number;
  imageUrl: string;
  artworkCount: number;
};
type UserColumn = {
  artistname: JSX.Element;
  counts: number;
  action: any;
};
export const columnsWithAction: Column<UserColumn>[] = [
  {
    Header: 'Artist Name',
    accessor: 'artistname',
  },

  {
    Header: 'Artwork Count',
    accessor: 'counts',
  },
  {
    Header: 'Actions',
    accessor: 'action',
  },
];
export const columnsWithoutAction: Column<UserColumn>[] = [
  {
    Header: 'Artist Name',
    accessor: 'artistname',
  },

  {
    Header: 'Artwork Count',
    accessor: 'counts',
  },
];
