import React from 'react';

export const FormLabel = (props: LabelTypes) => {
  return (
    <React.Fragment>
      <label className=" col-form-label fw-bold fs-6 pb-0 mb-6">
        {props.label}
      </label>
    </React.Fragment>
  );
};
interface LabelTypes {
  label: string;
}
