import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Slider.scss';
import broken from '../../../../_metronic/assets/images/broken.png';
const responsiveForMultiple = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1921 },
    items: 1,
    // partialVisibilityGutter: 20,
  },
  largeDesktop: {
    breakpoint: { max: 1920, min: 1681 },
    items: 1,
    // partialVisibilityGutter: 16,
  },
  desktop: {
    breakpoint: { max: 1680, min: 1024 },
    items: 1,
    // partialVisibilityGutter: 16,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
type sliderPropTypes = {
  imageUrls: string[];
};
export const Slider = ({ imageUrls }: sliderPropTypes) => {
  if (imageUrls.length < 1) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="mb-60">
          <img src={broken} alt="art image" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="mb-60">
          <Carousel
            responsive={responsiveForMultiple}
            draggable={true}
            autoPlay={false}
            swipeable={true}
          >
            {imageUrls.map((art) => {
              return <img src={art} alt="art image" />;
            })}
          </Carousel>
        </div>
      </>
    );
  }
};
