import React, { useEffect, useState } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import { FormLabel } from '../../../_metronic/partials/content/Label/FormLabel';
import { ArtButton } from '../../../_metronic/partials/content/Button/ArtButton';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import axios from 'axios';
import {
  ADD_CLIENTS_DATA_URL,
  GET_CLIENT_EDIT_DETAILS_URL,
  UPLOAD_ARTWORK_IMAGE,
} from '../../../constants';
import { useUser } from '../../modules/auth/User-context';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const EditClients = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const [showAlert, setShowAlert] = useState(false);
  const { id }: any = useParams();
  const [path, setPath] = useState('');
  const initialValues = {
    createdDate: {
      value: '',
      isError: false,
      errorMessage: 'Date of Creation is missing',
    },
    deactivatedDate: {
      value: '',
      isError: false,
      errorMessage: 'Date of Deactivation is missing',
    },
    phoneNumber: {
      value: '',
      isError: true,
      errorMessage: 'Phone Number is missing',
    },
    id: { value: 0, isError: false, errorMessage: 'ID is missing' },
    status: { value: 0, isError: false, errorMessage: 'Status is missing' },
    imageUrl: { value: '', isError: true, errorMessage: 'Image is missing' },
    name: { value: '', isError: true, errorMessage: 'Name is missing' },
    email: { value: '', isError: false, errorMessage: 'Email is missing' },
  };
  const [clientData, setClientData] =
    useState<{ [name: string]: any }>(initialValues);
  const { dispatch } = useUser();
  function getData() {
    console.log('hiiii');
    if (id) {
      axios({
        method: 'POST',
        url: GET_CLIENT_EDIT_DETAILS_URL + id,
      }).then(({ data }) => {
        if (data.isSuccess) {
          const {
            id,
            imageUrl,
            fullName,
            email,
            phoneNumber,
            createdDate,
            deactivatedDate,
            status,
          } = data.data;
          setClientData({
            ...clientData,
            email: { ...clientData.email, value: email, isError: false },
            id: { ...clientData.id, value: id, isError: false },
            imageUrl: {
              ...clientData.imageUrl,
              value: imageUrl,
              isError: false,
            },
            name: { ...clientData.name, value: fullName, isError: false },
            status: { ...clientData.status, value: status, isError: false },
            phoneNumber: {
              ...clientData.phoneNumber,
              value: phoneNumber,
              isError: false,
            },
            createdDate: {
              ...clientData.createdDate,
              value: createdDate,
              isError: false,
            },
            deactivatedDate: {
              ...clientData.deactivatedDate,
              value: deactivatedDate,
              isError: false,
            },
          });
          setPath(data.data?.imageUrl || '');
        } else {
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: data.message,
          });
          setShowAlert(true);
        }
      });
    }
  }
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Clients', to: '/clients' },
      { title: 'Edit', to: null },
    ]);
    getData();
  }, []);
  function uploadImage(e: any) {
    const formData = new FormData();
    formData.append('formFile', e.target.files?.item(0) as Blob);
    const data = e.target.files?.item(0) as Blob;
    let a = URL.createObjectURL(data);
    setPath(a);
    axios({
      method: 'POST',
      url: UPLOAD_ARTWORK_IMAGE,
      data: formData,
    })
      .then(({ data }) => {
        setClientData({
          ...clientData,
          imageUrl: {
            ...clientData.image,
            value: data.data.url,
            isError: false,
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  function updateInputs(key: any, value: any) {
    setClientData({
      ...clientData,
      [key]: { ...clientData[key], value: value, isError: false },
    });
  }
  function saveChanges(e: any) {
    let errorFound = false;
    for (const key in clientData) {
      if (clientData[key].isError && !errorFound) {
        setAlertData({
          variant: 'danger',
          heading: 'Fields Missing',
          message: clientData[key].errorMessage,
        });
        errorFound = true;
        setShowAlert(true);
      }
    }
    if (!errorFound) {
      const data = {
        fullName: clientData.name.value,
        // createdDate: clientData.createdDate.value,
        // deactivatedDate: clientData.deactivatedDate.value,
        // status: clientData.status.value,
        email: clientData.email.value,
        phoneNumber: clientData.phoneNumber.value,
        imageUrl: clientData.imageUrl.value,
        id: clientData.id.value ? clientData.id.value : 0,
      };
      axios({
        method: 'POST',
        url: ADD_CLIENTS_DATA_URL,
        data: data,
      })
        .then(({ data }) => {
          if (data.isSuccess) {
            setAlertData({
              variant: 'success',
              message: 'User Details have been saved successfully',
              heading: 'Success',
            });
            setShowAlert(true);
            if (!id) {
              setClientData(initialValues);
            }
            getData();
          } else {
            setAlertData({
              variant: 'danger',
              message: data.message,
              heading: 'Failed',
            });
            setShowAlert(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            localStorage.clear();
            dispatch({ type: 'remove-user' });
          }
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: error.message,
          });
          setShowAlert(true);
        });
    }
  }
  return (
    <React.Fragment>
      <div className="card mb-5 mb-xl-10">
        {showAlert && (
          <Alert
            variant={alertData.variant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>{alertData.heading}</Alert.Heading>
            <p>{alertData.message}</p>
          </Alert>
        )}
        <div className="card-header border-1 bg-white align-items-center ">
          <div className="card-title  align-items-start flex-column">
            <h3 className="fw-bolder m-0">Client Details</h3>
          </div>
          <div className="d-flex flex-wrap gap-3">
            <ArtButton
              buttonText={id ? 'Save Changes' : 'Add User'}
              styleclass={''}
              variant={'dark'}
              icon={undefined}
              hasIcon={false}
              onClick={saveChanges}
            />
          </div>
        </div>
        <div id="kt_account_profile_details" className="collapse show">
          <form className="form ">
            <div className="card-body border-top p-9 mb-32">
              <h4 className="my-5">Client Profile</h4>
              <div className="imageUploaderWrapper mb-5">
                <div className="circle">
                  <img src={path} alt="user img" />
                </div>
                <label className="pImage">
                  <AiOutlineCamera className="uploadButton" />
                  <input
                    className="fileUpload"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                </label>
              </div>
              <div className="row mb-6">
                <div className="col-lg-4 fv-row">
                  <FormLabel label={'Name'} />
                  <input
                    type="text"
                    value={clientData.name.value}
                    className="form-control form-control-lg "
                    placeholder="Name"
                    onChange={(e) => {
                      updateInputs('name', e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-4 fv-row">
                  <FormLabel label={'Email'} />
                  <input
                    type="text"
                    value={clientData.email.value}
                    className="form-control form-control-lg "
                    placeholder="Email"
                    readOnly
                  />
                </div>
                <div className="col-lg-4 fv-row">
                  <FormLabel label={' Phone No'} />
                  <input
                    type="text"
                    value={clientData.phoneNumber.value}
                    className="form-control form-control-lg "
                    placeholder="Phone Number"
                    onChange={(e) => {
                      updateInputs('phoneNumber', e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-4 fv-row">
                  <FormLabel label={'Status'} />
                  <input
                    type="text"
                    value={clientData.status.value}
                    className="form-control form-control-lg "
                    placeholder="Status"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
