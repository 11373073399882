import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { Categories } from './Categories';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const StyleAndCategories = ({ setBreadcrumbData }: propType) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  useEffect(() => {
    setBreadcrumbData([{ title: 'Categories', to: null }]);
  }, []);
  return (
    <React.Fragment>
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-3">
          <Categories
            title={'Artwork Categories'}
            setShowAlert={setShowAlert}
            setAlertData={setAlertData}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-3">
          <Categories
            title={'Artwork Styles'}
            setShowAlert={setShowAlert}
            setAlertData={setAlertData}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-3">
          <Categories
            title={'Article Categories'}
            setShowAlert={setShowAlert}
            setAlertData={setAlertData}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
