import { Suspense } from 'react';
import { Clients } from '../pages/Clients/Clients';
import { ClientsDetails } from '../pages/Clients/ClientDetails/ClientsDetails';
import { ImportExcel } from '../pages/artworks/ImportExcel';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import { ImportArts } from '../pages/artworks/ImportArts';
import { Artworks } from '../pages/artworks/Artworks';
import { ArtDetails } from '../pages/artworks/ArtworkDetails/ArtDetails';
import { ArtistDetails } from '../pages/Artist/ArtistDetails/ArtistDetails';
import { Artists } from '../pages/Artist/ArtistListing/Artists';
import { JournalDetails } from '../pages/Journals/JournalDetails';
import { Journals } from '../pages/Journals/journal-listing/Journals';
import { Dashboardmain } from '../pages/DashboardMain/Dashboardmain';
import { EditArtwork } from '../pages/artworks/EditArtwork';
import { EditClients } from '../pages/ClientDetails/EditClients';
import { EditArtist } from '../pages/Artist/EditArtist';
import { EditJournals } from '../pages/Journals/EditJournals';
import { ArtworkReservationListing } from '../pages/artworks/ArtworkReservation/ArtworkReservationListing';
import { ReservationDetails } from '../pages/artworks/ArtworkReservation/ReservationDetails';
import { ArtInquiryDetails } from '../pages/artworks/Artwork-Inquiry/ArtInquiryDetails';
import { ArtInquirynListing } from '../pages/artworks/Artwork-Inquiry/ArtInquiryListing';
import { breadcrumbType } from '../../_metronic/layout/MasterLayout';
import { StyleAndCategories } from '../pages/artworks/StylesAndCategories/StyleAndCategories';
import { Users } from '../pages/Users/Users';
import { EditUser } from '../pages/Users/EditUser';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export function PrivateRoutes({ breadcrumbData, setBreadcrumbData }: propType) {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* ----------------------------DASHBOARD---------------------------------- */}
        <Route path="/dashboard">
          <Dashboardmain
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/categories">
          <StyleAndCategories
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        {/* ----------------------------ARTWORKS---------------------------------- */}
        <Route path="/artworks">
          <Artworks
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route path="/artwork/:id">
          <ArtDetails
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route path="/editartworks/:id">
          <EditArtwork
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route path="/editartworks">
          <EditArtwork
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route path="/importartwork">
          <ImportArts
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route path="/importexcel">
          <ImportExcel
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/inquirylisting">
          <ArtInquirynListing
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/inquirydetails/:artworkId/:userId">
          <ArtInquiryDetails
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/reservation">
          <ArtworkReservationListing
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/reservationdetails/:artworkId/:userId">
          <ReservationDetails
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        {/* -------------------------------USERS---------------------------------- */}
        <Route exact path="/users">
          <Users
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>{' '}
        <Route exact path="/edituser/:id">
          <EditUser
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/edituser">
          <EditUser
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        {/* ----------------------------JOURNALS---------------------------------- */}
        <Route exact path="/journals">
          <Journals
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/article/:id">
          <JournalDetails
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/editarticle/:id">
          <EditJournals
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/editarticle">
          <EditJournals
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        {/* ----------------------------CLIENTS---------------------------------- */}
        <Route path="/clients">
          <Clients
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/client/:id">
          <ClientsDetails
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/editclient/:id">
          <EditClients
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/editclient">
          <EditClients
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        {/* ----------------------------ARTISTS---------------------------------- */}
        <Route exact path="/artists">
          <Artists
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/artist/:id">
          <ArtistDetails
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/editartist/:id">
          <EditArtist
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Route exact path="/editartist">
          <EditArtist
            breadcrumbData={breadcrumbData || []}
            setBreadcrumbData={setBreadcrumbData}
          />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
