import { Link } from 'react-router-dom';
import broken from '../../../_metronic/assets/images/broken.png';
import { useLocation } from 'react-router';

export const JournalCard = ({
  author,
  id,
  imageUrl,
  publishDate,
  title,
  action,
}: articlePropsType) => {
  const location = useLocation();
  return (
    <div>
      <div className="article-card card card-custom gutter-b mb-5  artictle-listing border-1">
        <div className="card-body">
          <div className="card-image">
            <Link to={{ pathname: `/article/${id}` }}>
              <img src={imageUrl || broken} alt="artwork" />
            </Link>
          </div>
          <div className="card-content">
            <div className="d-flex justify-content-between align-items-center">
              <span className="article-title  ms-2">{title}</span>
              {location.pathname === '/journals' && (
                <span className="article-title  ms-2">{action}</span>
              )}
            </div>
            <div className="d-flex justify-content-start  mb-3">
              <span className="article-description ms-2">{author}</span>
            </div>
            <div className="d-flex justify-content-start  mb-3">
              <span className="article-title text-muted ms-2">
                {new Date(publishDate).toDateString() === 'Invalid Date'
                  ? ''
                  : new Date(publishDate).toDateString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
interface articlePropsType {
  author: any;
  id: any;
  imageUrl: any;
  isBookmarked: any;
  publishDate: any;
  title: any;
  action: any;
}
