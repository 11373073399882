import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useLayout } from '../core';
import { DrawerComponent } from '../../assets/ts/components';
import { PrivateRoutes } from '../../../app/routing/PrivateRoutes';
import { breadcrumbType } from '../MasterLayout';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
const Content = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const { classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_content_container"
      className={clsx(classes.contentContainer.join(' '))}
    >
      <PrivateRoutes
        breadcrumbData={breadcrumbData || []}
        setBreadcrumbData={setBreadcrumbData}
      />
    </div>
  );
};

export { Content };
