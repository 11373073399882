import { Column, useTable, useGlobalFilter, useFilters } from 'react-table';

interface IAppProps {
  columns: Column<any>[];
  userData: {}[];
}

const CategoriesListTable = ({ columns, userData }: IAppProps) => {
  let tableProps: any = useTable(
    { columns: columns, data: userData ? userData : [] },
    useFilters,
    useGlobalFilter
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = tableProps;
  const { globalFilter } = state;
  return (
    <div>
      <table
        className="table table-row-dashed w-50 table-row-gray "
        {...getTableProps()}
      >
        <thead>
          <tr className="fw-bolder ">
            <td>
              <div className="search-bar">
                <input
                  value={globalFilter || ''}
                  type={'text'}
                  placeholder="Search"
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>
            </td>
          </tr>
          {headerGroups.map((headerGroup: any) => (
            <tr className="fw-bolder " {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CategoriesListTable;
