import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import {
  GET_USER_SURVEY_URL,
  UPDATE_USER_SURVEY_URL,
} from '../../../../constants';
import { useUser } from '../../../modules/auth/User-context';
import { ArtworkYouLike } from './ArtworkYouLike';
import { Preferences } from './Preferences';
import { PriceRange } from './PriceRange';
type propType = {
  userId: number;
  getData?: Function;
};
export const ClientSurvey = ({ userId, getData }: propType) => {
  const [surveyData, setSurveyData] = useState<any>([]);
  let likedArtworks: any = surveyData[0];
  let priceData: any = surveyData[1];
  let preferencesData = surveyData[2];
  const { dispatch } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  useEffect(() => {
    axios({
      method: 'POST',
      url: GET_USER_SURVEY_URL,
      data: {
        userId,
      },
    }).then(({ data }) => {
      if (data.data) {
        setSurveyData(data.data);
      }
    });
  }, []);
  function setPrice(val: number) {
    priceData.price = val;
    setSurveyData(
      surveyData.map((item: any) => {
        if (item.questionId === 2) {
          item.price = val;
          return item;
        } else return item;
      })
    );
  }
  function setLikedArtworks(data: any) {
    likedArtworks.surveyOptions.forEach((element: any) => {
      if (data === element.optionId) {
        element.isSelected = true;
      }
    });
    setSurveyData(
      surveyData.map((item: any) => {
        if (item.questionId === 1) {
          item.surveyOptions = likedArtworks.surveyOptions;
          return item;
        } else return item;
      })
    );
  }
  function setPreferencesData(data: any) {
    preferencesData.surveyOptions.forEach((element: any) => {
      if (data === element.optionId) {
        element.isSelected = true;
      }
    });
    setSurveyData(
      surveyData.map((item: any) => {
        if (item.questionId === 3) {
          item.surveyOptions = preferencesData.surveyOptions;
          return item;
        } else return item;
      })
    );
  }
  function deSelectLikedArtworks(data: any) {
    likedArtworks.surveyOptions.forEach((element: any) => {
      if (data === element.optionId) {
        element.isSelected = false;
      }
    });
    setSurveyData(
      surveyData.map((item: any) => {
        if (item.questionId === 1) {
          item.surveyOptions = likedArtworks.surveyOptions;
          return item;
        } else return item;
      })
    );
  }
  function deSelectPreferedArtworks(data: any) {
    preferencesData.surveyOptions.forEach((element: any) => {
      if (data === element.optionId) {
        element.isSelected = false;
      }
    });
    setSurveyData(
      surveyData.map((item: any) => {
        if (item.questionId === 3) {
          item.surveyOptions = preferencesData.surveyOptions;
          return item;
        } else return item;
      })
    );
  }
  function saveChanges() {
    let data = JSON.parse(JSON.stringify(surveyData));
    data.forEach((element: any, index: any, array: any) => {
      element.userId = userId;
      element.surveyOptions = element.surveyOptions.filter(
        (option: any) => option.isSelected
      );
    });
    axios({
      method: 'POST',
      url: UPDATE_USER_SURVEY_URL,
      data: {
        surveyQuestions: data,
      },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: data.message,
            heading: 'Success',
          });
          setShowAlert(true);
          if (getData) {
            setTimeout(() => {
              getData();
            }, 3000);
          }
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  return (
    <React.Fragment>
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="d-flex justify-content-center">
        <Button variant="dark" onClick={saveChanges}>
          Save Current Changes
        </Button>
      </div>
      {likedArtworks && (
        <ArtworkYouLike
          deSelectLikedArtworks={deSelectLikedArtworks}
          likedArtworks={surveyData[0]}
          setLikedArtworks={setLikedArtworks}
        />
      )}
      {preferencesData && (
        <Preferences
          deSelectPreferedArtworks={deSelectPreferedArtworks}
          preferencesData={surveyData[2]}
          setPreferencesData={setPreferencesData}
        />
      )}
      {priceData && (
        <PriceRange priceData={surveyData[1]} setPrice={setPrice} />
      )}
    </React.Fragment>
  );
};
