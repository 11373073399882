import React, { useEffect, useState } from 'react';
import { FormLabel } from '../../../_metronic/partials/content/Label/FormLabel';
import MyEditor from './Editor';
import { ArtButton } from '../../../_metronic/partials/content/Button/ArtButton';
import axios from 'axios';
import {
  ADD_ARTICLE_DATA_URL,
  GET_ALL_ARTISTS_INFO_URL,
  GET_ARTICLE_CATEGORIES_URL,
  GET_ARTICLE_DETAILS_FOR_UPDATE_URL,
  UPDATE_ARTICLE_URL,
} from '../../../constants';
import { useLocation, useParams } from 'react-router';
import { Alert } from 'react-bootstrap';
import { FormSelect } from '../../../_metronic/partials/content/FormSelect/FormSelect';
import { AiOutlineCamera } from 'react-icons/ai';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { useUser } from '../../modules/auth/User-context';
import { Spinner } from 'react-bootstrap-v5';

type articleType = {
  id: number | null;
  title: string;
  coverImage: Blob | string;
  artistId: number | null;
  publishDate: string;
  category: number | null;
  tags: string;
  imageUrl: string;
  articleUrl: string;
};
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const EditJournals = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { id }: any = useParams();
  const { dispatch } = useUser();
  const [path, setPath] = useState('');
  const [setButtonDisable, setSubmitArticleButtonDisable] = useState(false);
  const location = useLocation<{ id: string }>();
  const targetUrl = id ? UPDATE_ARTICLE_URL : ADD_ARTICLE_DATA_URL;
  const [showAlert, setShowAlert] = useState(false);
  const [artists, setArtists] = useState([{ label: '', value: '' }]);
  const [categories, setCategories] = useState([{ label: '', value: '' }]);
  const [alertData, setAlertData] = useState({
    variant: 'danger',
    message: '',
    heading: 'Missing values',
  });
  const initialValues = {
    id: 0,
    title: '',
    coverImage: '',
    artistId: 0,
    publishDate: '',
    category: null,
    tags: '',
    imageUrl: '',
    articleUrl: '',
  };
  const [articleData, setArticleData] = useState<articleType>(initialValues);
  const [description, setDescription] = useState('');
  function updateFilters(selected: any) {
    setArticleData({
      ...articleData,
      category: selected,
    });
  }
  function updateArtist(selected: any) {
    setArticleData({ ...articleData, artistId: selected });
  }
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Articles', to: '/journals' },
      { title: id ? 'Edit' : 'Add', to: null },
    ]);
    if (id) {
      axios({
        method: 'GET',
        url: GET_ARTICLE_DETAILS_FOR_UPDATE_URL + id,
      }).then(({ data }) => {
        setArticleData({
          ...articleData,
          id: data.data.id,
          artistId: data.data.artistId,
          imageUrl: data.data.imageUrl,
          publishDate: data.data.publishDate,
          tags: data.data.tags,
          title: data.data.title,
          coverImage: data.data.imageUrl,
          category: data.data.categoryId,
          articleUrl: data.data.articleLink,
        });
        setDescription(data.data.description || '');
        setPath(data.data?.imageUrl || '');
      });
    }
    axios({
      method: 'GET',
      url: GET_ARTICLE_CATEGORIES_URL,
    })
      .then(({ data }) => {
        setCategories(
          data.data.map((item: any) => {
            return { label: item.name, value: item.id };
          })
        );
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
      });
    axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_INFO_URL,
    }).then(({ data }) => {
      setArtists(
        data.data.map((item: any) => {
          return { label: item.name, value: item.id };
        })
      );
    });
  }, []);
  function saveChanges(e: any) {
    if (articleData.title == '') {
      setAlertData({ ...alertData, message: 'Title is missing' });
      setShowAlert(true);
    } else if (articleData.publishDate == '') {
      setAlertData({ ...alertData, message: 'Publish Date is missing' });
      setShowAlert(true);
    } else if (articleData.category == null) {
      setAlertData({ ...alertData, message: 'Category is missing' });
      setShowAlert(true);
    } else if (articleData.tags == '') {
      setAlertData({ ...alertData, message: 'Tag is missing' });
      setShowAlert(true);
    } else if (articleData.coverImage == '') {
      setAlertData({ ...alertData, message: 'Image is missing' });
      setShowAlert(true);
    } else if (description == '' && articleData.articleUrl == '') {
      setAlertData({
        ...alertData,
        message: 'Either Description or URL must be provided',
      });
      setShowAlert(true);
    } else if (articleData.articleUrl !== '' &&
      articleData.articleUrl.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      ) === null
    ) {
      setAlertData({ ...alertData, message: 'URL is not valid' });
      setShowAlert(true);
    } else {
      setSubmitArticleButtonDisable(true);
      const formData = new FormData();
      if (id) {
        formData.append('id', id);
      }
      formData.append('description', description);
      formData.append('title', articleData.title);
      formData.append('artistId', '' + articleData.artistId);
      formData.append('publishDate', articleData.publishDate);
      formData.append('categoryId', articleData.category.toString());
      formData.append('tags', articleData.tags);
      formData.append('coverImage', articleData.coverImage);
      formData.append('articleLink', articleData.articleUrl);
      axios({
        method: 'POST',
        url: targetUrl,
        data: formData,
      })
        .then(({ data }) => {
          if (data.isSuccess) {
            setAlertData({
              variant: 'success',
              message: 'Journal details have been saved successfully',
              heading: 'Success',
            });
            setShowAlert(true);
            if (!id) {
              setArticleData(initialValues);
              setDescription('');
            }
            setSubmitArticleButtonDisable(false);
          } else {
            setAlertData({
              variant: 'danger',
              message: data.message,
              heading: 'Failed',
            });
            setShowAlert(true);
            setSubmitArticleButtonDisable(false);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            localStorage.clear();
            dispatch({ type: 'remove-user' });
          }
          setAlertData({
            variant: 'danger',
            heading: 'Failed',
            message: error.message,
          });
          setShowAlert(true);
          setSubmitArticleButtonDisable(false);
        });
    }
  }
  return (
    <React.Fragment>
      <div className="row">
        <div className="card mb-5 mb-xl-10">
          {showAlert && (
            <Alert
              variant={alertData.variant}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <Alert.Heading>{alertData.heading}</Alert.Heading>
              <p>{alertData.message}</p>
            </Alert>
          )}
          <div className="card-header border-0">
            <div className="card-title m-0 d-flex justify-content-between gap-3">
              <h3 className="fw-bolder m-0">Article Details</h3>
            </div>
            <div className="card-title m-0 d-flex  gap-3">
              <ArtButton
                buttonText={id ? 'Save Changes' : 'Add Article'}
                styleclass={''}
                variant={'dark'}
                icon={undefined}
                hasIcon={false}
                onClick={saveChanges}
                disabled={setButtonDisable}
              />
              {setButtonDisable ? <Spinner animation="border" /> : null}
            </div>
          </div>
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <div className="col-lg-6 col-md-12">
                <div className="card mb-5 mb-xl-10">
                  <h4 className="fs-1 fw-bold me-1 mb-5">Cover Image</h4>
                  <div className="imageUploaderWrapper mb-5">
                    <div className="square-upload">
                      <img src={path.length > 1 ? path : undefined} alt="" />
                    </div>
                    <label className="pImage">
                      <AiOutlineCamera className="uploadButton" />
                      <input
                        className="fileUpload"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          const data = e.target.files?.item(0) as Blob;
                          let a = URL.createObjectURL(data);
                          setPath(a);
                          setArticleData({
                            ...articleData,
                            coverImage: e.target.files?.item(0) || '',
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 fv-row">
                      <FormLabel label={'Title'} />
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Title"
                        value={articleData.title}
                        onChange={(e) => {
                          setArticleData({
                            ...articleData,
                            title: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 fv-row">
                      <FormLabel label={' Artist Name'} />
                      <FormSelect
                        options={artists}
                        updateFilter={updateArtist}
                        selected={
                          articleData.artistId
                            ? articleData.artistId?.toString()
                            : ''
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-12fv-row">
                      <FormLabel label={'Publish Date'} />
                      <input
                        type="date"
                        value={articleData.publishDate}
                        className="form-control form-control-lg "
                        placeholder=" Publish date"
                        onChange={(e) => {
                          setArticleData({
                            ...articleData,
                            publishDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 ">
                      <FormLabel label={' Category'} />
                      <FormSelect
                        options={categories}
                        updateFilter={updateFilters}
                        selected={
                          // location.state && articleData.artistId
                          articleData.category?.toString() || ''
                          // : ''
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <FormLabel label={'Tags'} />
                      <input
                        type="text"
                        value={articleData.tags}
                        className="form-control form-control-lg "
                        placeholder=" Comma seperated Tags"
                        onChange={(e) => {
                          setArticleData({
                            ...articleData,
                            tags: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <FormLabel label={'URL'} />
                      <input
                        type="text"
                        value={articleData.articleUrl}
                        className="form-control form-control-lg "
                        placeholder=" URL for Article"
                        onChange={(e) => {
                          setArticleData({
                            ...articleData,
                            articleUrl: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-6 col-md-12 ">
                <h4 className="fs-1 fw-bold me-1 mb-5">Article Details</h4>
                <MyEditor
                  handleChange={(data: any) => {
                    setDescription(data);
                  }}
                  data={description}
                // {...props}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
