import axios from 'axios';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces';
import { DEACTIVATE_CLIENT_URL } from '../../../constants';
import { Pagination } from '../../../_metronic/partials/content/pagination/Pagination';
import { SearchBar } from '../../../_metronic/partials/content/SearchBar/SearchBar';
import {
  UserFilters,
  userFilterType,
} from '../../components/filters/UserFilters';
import ListTable from '../../components/listing-table';
import { useUser } from '../../modules/auth/User-context';
import { rowData } from '../artworks/Addartwork/utils/user-data';
import { useLocation } from 'react-router';
import { Column } from 'react-table';
type UserColumn = {
  serialNo: string;
  fullName: any;
  emailAddress: any;
  action: any;
  telephone: any;
  status: any;
};
interface IClientListProps {
  currentRecords: any;
  userData: any;
  columns: any;
  setIsLoading: any;
  totalRecords: any;
  pageState: any;
  setPageState: any;
  selectedUserFilters: any;
  userFilters: userFilterType;
  updateUserFilters: Function;
  getAllClients: Function | undefined;
}
export const ClientList = ({
  currentRecords,
  userData,
  columns,
  setIsLoading,
  totalRecords,
  pageState,
  setPageState,
  userFilters,
  updateUserFilters,
  selectedUserFilters,
  getAllClients,
}: IClientListProps) => {
  const location = useLocation();
  const { dispatch } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  let col: Column<UserColumn>[] = [];
  col =
    location.pathname == '/clients'
      ? [
          {
            Header: 'Full Name',
            accessor: 'fullName',
          },
          {
            Header: 'Email address',
            accessor: 'emailAddress',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Telephone',
            accessor: 'telephone',
          },
          {
            Header: 'Actions',
            accessor: 'action',
          },
        ]
      : [
          {
            Header: 'Full Name',
            accessor: 'fullName',
          },
          {
            Header: 'Email address',
            accessor: 'emailAddress',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Telephone',
            accessor: 'telephone',
          },
        ];
  function makeArtistInactive(id: number) {
    axios({
      method: 'GET',
      url: DEACTIVATE_CLIENT_URL + id,
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'User Deactivated Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
          if (getAllClients) getAllClients();
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }
  function handleFiltersChange({
    categories,
    styles,
    status,
  }: handleClientFiltersProps) {
    setPageState({
      ...pageState,
      filters: {
        ...pageState.filters,
        styleIds: styles.map((style) => style.value),
        categoryIds: categories.map((category) => category.value),
        status: status.map((item) => item.value),
      },
    });
  }
  function handleSearchClick(search: string): void {
    setPageState({
      filters: {
        text: search,
        categoryIds: [],
        styleIds: [],
        status: [],
      },
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
      },
    });
  }
  return (
    <div className="card ">
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="bg-white">
        <div className="card-header  border-1  align-items-center ">
          <div className="card-title  align-items-start flex-column">
            <h3 className="fw-bolder m-0">Clients</h3>
          </div>
          <div className="d-flex flex-wrap gap-3">
            <SearchBar handleSearchClick={handleSearchClick} />
            <UserFilters
              handleFiltersChange={handleFiltersChange}
              userFilters={userFilters}
              selectedUserFilters={selectedUserFilters}
              updateUserFilters={updateUserFilters}
            />
          </div>
        </div>
        {selectedUserFilters &&
          (selectedUserFilters.categories.length > 0 ||
            selectedUserFilters.styles.length > 0 ||
            selectedUserFilters.status.length > 0) && (
            <div className="select-items-view--wrapper">
              <div className="select-items-view">
                <h6>Selected Filters</h6>
                {selectedUserFilters.categories.length > 0
                  ? selectedUserFilters.categories.map((category: any) => (
                      <span>{category.label}</span>
                    ))
                  : ''}
                {selectedUserFilters.styles.length > 0
                  ? selectedUserFilters.styles.map((category: any) => (
                      <span>{category.label}</span>
                    ))
                  : ''}
                {selectedUserFilters.status.length > 0
                  ? selectedUserFilters.status.map((category: any) => (
                      <span>{category.label}</span>
                    ))
                  : ''}
              </div>
            </div>
          )}
      </div>
      <div className="card-body j-c">
        <div className="table-responsive">
          {userData == null || userData.length <= 0 ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-30 data-not-found">
              <span className="fs-4  fw-bold">No Data Found</span>
            </div>
          ) : (
            <ListTable
              columns={col}
              userData={rowData(
                userData,
                makeArtistInactive,
                location.pathname
              )}
            />
          )}
          <Pagination
            currentRecords={currentRecords}
            setIsLoading={setIsLoading}
            totalRecords={totalRecords}
            pageSize={pageState.pageInfo.pageSize}
            pageNumber={pageState.pageInfo.pageNumber}
            handlePageSize={handlePageSize}
            handlePageNumber={handlePageNumber}
          />
        </div>
      </div>
    </div>
  );
};
type handleClientFiltersProps = {
  categories: Option[];
  styles: Option[];
  status: Option[];
};
export interface pageStateType {
  filters: {
    text: string;
    categoryIds: number[];
    styleIds: number[];
    status: string[];
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
}
