import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
export const rowData = (
  userData: UserData[],
  makeArtistInactive: Function
): any => {
  const data = userData.map((item: UserData) => ({
    username: (
      // <Link to={{ pathname: "/edituser", state: { id: item.id } }}>
      <div className="d-flex align-items-center">
        <div className="client-Avatar">
          <img src={item.imageUrl} alt="img" />
        </div>
        <div className="d-flex justify-content-start">
          <span className="fw-bold text-dark d-block fs-7">
            {item.fullName}{' '}
          </span>
        </div>
      </div>
      // </Link>
    ),
    email: (
      <div className="d-flex justify-content-start">
        <span className="fw-bold text-dark d-block fs-7">{item.email} </span>
      </div>
    ),
    phoneNumber: (
      <div className="d-flex justify-content-start">
        <span className="fw-bold text-dark d-block fs-7">
          {item.phoneNumber}{' '}
        </span>
      </div>
    ),
    action: (
      <div className="d-flex align-items-center  gap-3">
        <FiTrash2
          className="icons-del"
          onClick={() => {
            makeArtistInactive(item.id);
          }}
        />{' '}
        <Link to={{ pathname: `/edituser/${item.id}` }}>
          <FiEdit className="icons-del" />
        </Link>
      </div>
    ),
  }));
  return data;
};
type UserData = {
  fullName: string;
  imageUrl: any;
  role: any;
  status: string;
  email: any;
  id: number;
  phoneNumber: string;
};
type UserColumn = {
  username: string;
  action: any;
  email: any;
  phoneNumber: any;
};
export const columns: Column<UserColumn>[] = [
  {
    Header: 'Full Name',
    accessor: 'username',
  },

  {
    Header: 'Email Address',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
