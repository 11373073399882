import { useState } from 'react';
import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
export interface PaginationProps {
  totalRecords: number;
  currentRecords: number;
  pageSize: number;
  pageNumber: number;
  handlePageNumber: Function;
  handlePageSize: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Pagination = ({
  currentRecords,
  totalRecords,
  pageNumber,
  pageSize,
  handlePageNumber,
  handlePageSize,
  setIsLoading,
}: PaginationProps) => {
  const selectChanges = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    handlePageSize(parseInt(event.target.value));
  };
  const handlePageIncrease = (num: number): void => {
    handlePageNumber(num);
  };
  const handlePageDecrease = (num: number): void => {
    handlePageNumber(num);
  };
  return (
    <React.Fragment>
      <div className="footer py-2 px-5 d-flex flex-column" id="kt_footer">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex align-items-center py-3">
            <span className="text-muted d-flex align-items-center  gap-3 w-100">
              <select
                value={pageSize}
                onChange={selectChanges}
                className="custom-select p-1 rounded bg-white text-muted"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{' '}
              <span className="w-100">
                Displaying {currentRecords} of {totalRecords} records
              </span>{' '}
            </span>
          </div>
          <div className="d-flex flex-wrap py-2 mr-3">
            <button
              disabled={pageNumber <= 1}
              onClick={() => {
                if (pageNumber > 1) {
                  setIsLoading(false);
                  handlePageDecrease(pageNumber - 1);
                }
              }}
              className="btn btn-icon btn-sms btn-light mr-2 m-1 "
            >
              <FaAngleLeft />
            </button>
            <button className="btn btn-icon btn-sms border-0 btn-dark mr-2 m-1 ">
              {pageNumber}
            </button>

            <button
              disabled={pageNumber >= Math.ceil(totalRecords / pageSize)}
              onClick={() => {
                setIsLoading(false);
                if (pageNumber < Math.ceil(totalRecords / pageSize)) {
                  handlePageIncrease(pageNumber + 1);
                }
              }}
              className="btn btn-icon btn-sms btn-light mr-2 m-1"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
