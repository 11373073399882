import * as React from 'react';

type Action =
  | { type: 'add-user-data'; payload: State }
  | { type: 'remove-user' };
type Dispatch = (action: Action) => void;
type State = {
  email: string;
  fullName: string;
  isLogin: boolean;
  id: string;
  imageUrl: string;
  phoneNumber: string;
  status: boolean;
};
type UserProviderProps = { children: React.ReactNode };

const UserStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);
const initialState: State = {
  email: '',
  fullName: '',
  isLogin: false,
  id: '',
  imageUrl: '',
  phoneNumber: '',
  status: false,
};
function userReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'add-user-data': {
      const { email, fullName, isLogin, id, imageUrl, phoneNumber, status } =
        action.payload;
      return {
        email,
        fullName,
        isLogin,
        id,
        imageUrl,
        phoneNumber,
        status,
      };
    }
    case 'remove-user': {
      return initialState;
    }
    default: {
      //   throw new Error(`Unhandled action type: ${action.type}`)
      return initialState;
    }
  }
}

function UserProvider({ children }: UserProviderProps) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
