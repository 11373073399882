import React, { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BiPhone, BiLike, BiCommentAdd } from 'react-icons/bi';
import { FaRegLightbulb } from 'react-icons/fa';
import { BsBookmarkCheck } from 'react-icons/bs';
import { RiSurveyLine } from 'react-icons/ri';
import { BadgeStatus } from '../../../../_metronic/partials/content/badges/BadgeStatus';
import { StatsCounter2 } from '../../../../_metronic/partials/content/StatsCounter/StatsCounter2';
import { clientType } from './ClientsDetails';
import blank from '../../../../_metronic/assets/images/blank.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { APPROVE_CLIENT_URL } from '../../../../constants';
import { useUser } from '../../../modules/auth/User-context';
import { Alert } from 'react-bootstrap';

interface IClientDetailsDataProps {
  clientData: clientType | undefined;
  setActiveTab: Function;
  activeTab: string;
  getClientData: Function;
}
export const ClientDetailsData = ({
  clientData,
  activeTab,
  setActiveTab,
  getClientData,
}: IClientDetailsDataProps) => {
  const { dispatch } = useUser();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  function approveUser() {
    axios({
      method: 'GET',
      url: APPROVE_CLIENT_URL + clientData?.id,
    })
      .then(({ data }) => {
        console.log(data);
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'User Approved Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
          getClientData();
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <React.Fragment>
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center">
        <div className="me-7 mb-4">
          <div className="symbol symbol-80px symbol-lg-160px symbol-fixed position-relative">
            <img src={clientData ? clientData.imageUrl : blank} />
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <span className="fs-1 fw-bold me-1">
                  {clientData?.fullName || 'Not Available'}
                </span>
              </div>
              <div className="d-flex flex-wrap mb-1 pe-2">
                <span className="d-flex align-items-center fs-2 me-5 mb-2 gap-2">
                  <AiOutlineMail className="fs-2" />
                  <span className="text-gray-400 fs-4">
                    {clientData?.email || 'Not Available'}
                  </span>
                </span>
                <span className="d-flex align-items-center me-5 mb-2 gap-2">
                  <BiPhone className="fs-2" />
                  <span className="text-gray-400 fs-4">
                    {clientData?.phoneNumber || 'Not Available'}
                  </span>
                </span>
              </div>
              <div className="d-flex flex-wrap mb-1 pe-2">
                <span className="d-flex align-items-center text-gray-400 fs-4 me-5 mb-2 gap-2">
                  <span className="fw-bold text-dark">Created Date:</span>
                  {new Date(clientData?.createdDate || '').toDateString()}
                </span>
                <span className="d-flex align-items-center text-gray-400 fs-4 mb-2 gap-2">
                  <span className="fw-bold text-dark">Deactivated Date:</span>
                  <span>
                    {new Date(
                      clientData?.lastDeactivatedDate || ''
                    ).toDateString()}
                  </span>
                </span>
              </div>
              <div className="d-flex flex-wrap pe-2">
                <span className="d-flex align-items-center me-5 gap-2 ">
                  <span className="fw-bold text-dark fs-4">Status:</span>
                  <BadgeStatus
                    status={
                      clientData?.status
                        ? clientData.status.toLowerCase()
                        : 'inactive'
                    }
                  />
                </span>
              </div>
            </div>
            {}
            <div className="d-flex my-4 gap-3">
              {clientData?.status.toLocaleLowerCase() ===
                'awaiting approval' && (
                <button
                  onClick={approveUser}
                  className="d-inline-flex btn btn-success btn-sm align-items-center art-btn gap-2"
                >
                  Approve User
                </button>
              )}
              {clientData?.id && (
                <Link
                  className="d-inline-flex btn btn-dark btn-sm align-items-center art-btn gap-2"
                  to={{
                    pathname: `/editclient/${clientData.id}`,
                  }}
                >
                  Edit
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex artdetails-wrapper-card mb-32">
        <div
          className="mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('suggestArtworks');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'suggestArtworks'}
            counts={clientData?.suggestedArtworkCount || 0}
            icon={<RiSurveyLine />}
            title={'Suggest Artworks'}
          />
        </div>
        <div
          className=" mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('likedArtworks');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'likedArtworks'}
            counts={clientData?.likedCount || 0}
            icon={<BiLike />}
            title={'Liked Artworks'}
          />
        </div>
        <div
          className=" mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('bookmarkedJournals');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'bookmarkedJournals'}
            counts={clientData?.bookmarkedJournalCount || 0}
            icon={<BsBookmarkCheck />}
            title={'Bookmarked Journal'}
          />
        </div>
        <div
          className="mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('followedArtists');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'followedArtists'}
            counts={clientData?.followArtistCount || 0}
            icon={<BiCommentAdd />}
            title={'Followed Artist'}
          />
        </div>
        <div
          className="mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('suggestedArtworks');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'suggestedArtworks'}
            counts={clientData?.suggestedArtworkCount || 0}
            icon={<FaRegLightbulb />}
            title={'Suggested Artworks'}
          />
        </div>
        <div
          className="mb-3 mb-md-0"
          onClick={() => {
            setActiveTab('surveyresponse');
          }}
        >
          <StatsCounter2
            isActive={activeTab === 'surveyresponse'}
            counts={clientData?.suggestedArtworkCount || 0}
            icon={<RiSurveyLine />}
            title={'Survey Response'}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
