import axios from 'axios';
import { useEffect, useState } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { VscBookmark } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { GET_CLIENTS } from '../../../../constants';

export const ClientActivities = () => {
  const [clients, setClients] = useState<clientType[]>([]);
  function getClients() {
    axios({
      method: 'POST',
      url: GET_CLIENTS,
    }).then(({ data }) => {
      setClients(data.data);
    });
  }
  useEffect(() => {
    getClients();
  }, []);
  return (
    <div className="card">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Top Client </span>
        </h3>
      </div>
      <div className="card-body pt-2">
        {clients.length > 0 ? (
          clients.map((item, index) => (
            <Link to={{ pathname: `/client/${item.clientId}` }}>
              <div className="d-flex align-items-center my-7">
                <div className="symbol symbol-50px me-5">
                  <img src={item.imageUrl} className="" alt="" />
                </div>
                <div className="flex-grow-1">
                  <span className="text-dark fw-bolder text-hover-primary fs-6">
                    {item.clientName}
                  </span>
                  <span className="text-muted d-block fw-bold">
                    {item.email}
                  </span>
                </div>
                <div className="d-flex gap-5 justify-content-center align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <AiOutlineHeart className="fs-2x text-muted" />
                    <span className=" fs-8 text-dark fw-bold">
                      {item.artworkBookmarkedCount}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <VscBookmark className="fs-2x text-muted" />
                    <span className="fs-8 text-dark fw-bold ">
                      {item.artistFollowedCount}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <FiUsers className="fs-2x text-muted" />
                    <span className=" fs-8 text-dark fw-bold">
                      {item.articleBookmarkedCount}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <h6>NO DATA</h6>
          </div>
        )}
      </div>
    </div>
  );
};
type clientType = {
  clientId: number;
  imageUrl: string;
  clientName: string;
  email: string;
  artistFollowedCount: number;
  artworkBookmarkedCount: number;
  articleBookmarkedCount: number;
};
