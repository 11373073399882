import React, { useEffect } from 'react';
import { useTable } from 'react-table';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { MdDragIndicator } from 'react-icons/md';
import axios from 'axios';
import { UPDATE_ARTWORK_QUEUE_ORDER } from '../../../../../constants';

const Table = ({ columns, data, setAlertData, setShowAlert }) => {
  const [records, setRecords] = React.useState(data);
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data: records,
      columns,
      getRowId,
    });

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };
  function saveQueueOrder() {
    let obj = records.map((item, index, recs) => {
      item.queueNumber = index + 1;
      return {
        queueNumber: item.queueNumber,
        artworkId: item.artworkId,
        userId: item.userId,
      };
    });
    axios({
      method: 'POST',
      url: UPDATE_ARTWORK_QUEUE_ORDER,
      data: { waitList: obj },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'Updated Successfully',
            heading: 'Successful',
          });
          setShowAlert(true);
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch(({ error }) => {
        setAlertData({
          variant: 'danger',
          message: error.message,
          heading: 'Failed',
        });
        setShowAlert(true);
      });
  }
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <table
          className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="fw-bolder" {...headerGroup.getHeaderGroupProps()}>
                <th></th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, index) =>
                prepareRow(row) || (
                  <Row
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                )
            )}
          </tbody>
        </table>
      </DndProvider>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-sm btn-dark me-3"
          onClick={saveQueueOrder}
          disabled={data.length <= 0}
        >
          Save Current Order
        </button>
      </div>
    </>
  );
};

const DND_ITEM_TYPE = 'row';

const Row = ({ row, index, moveRow }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef}>
        <MdDragIndicator className="icons-del" />
      </td>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
      })}
    </tr>
  );
};
const TableList = ({ data, setAlertData, setShowAlert }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Priority',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'clientName',
      },
      {
        Header: 'Email',
        accessor: 'clientEmail',
      },
      {
        Header: 'Reservation Time',
        accessor: 'reservationDate',
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      setAlertData={setAlertData}
      setShowAlert={setShowAlert}
    />
  );
};

export default TableList;
