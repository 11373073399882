import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { BadgeStatus } from '../../../../../_metronic/partials/content/badges/BadgeStatus';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useLocation } from 'react-router';
export const rowData = (
  userData: UserData[],
  makeArtistInactive: Function,
  location: string
): any => {
  // const location = useLocation();
  const data = userData.map((item: UserData) => ({
    fullName: (
      <Link to={{ pathname: `/client/${item.id}` }}>
        <div className="d-flex align-items-center  cursor-pointer">
          <div className="client-Avatar">
            <img src={item.imageUrl} alt="" />
          </div>
          <div className="d-flex justify-content-start">
            <span className="fw-bold text-dark fs-7">{item.fullName} </span>
          </div>
        </div>
      </Link>
    ),
    emailAddress: item.email,
    status: <BadgeStatus status={item.status} />,
    telephone: item.phoneNumber || 'Not Available',

    action:
      location === '/clients' ? (
        <div className="d-flex align-items-center gap-3">
          <Link to={{ pathname: '/editclient/' + item.id }}>
            <FiEdit className="icons-del" />{' '}
          </Link>
          <FiTrash2
            className="icons-del"
            onClick={() => {
              makeArtistInactive(item.id);
            }}
          />
        </div>
      ) : (
        <></>
      ),
  }));
  return data;
};
type UserData = {
  email: string;
  fullName: string;
  id: string;
  imageUrl: string;
  phoneNumber: string;
  status: string;
};
type UserColumn = {
  serialNo: string;
  fullName: any;
  emailAddress: any;
  action: any;
  telephone: any;
  status: any;
};
// if(location==='/clients')
export const columns: Column<UserColumn>[] = [
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'Email address',
    accessor: 'emailAddress',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Telephone',
    accessor: 'telephone',
  },
  {
    Header: 'Actions',
    accessor: 'action',
  },
];
