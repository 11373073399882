import { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
export const Art = ({
  artimage,
  title,
  hastitle,
  id,
  selectedArtworks,
  setSelectedArtworks,

  artistName,
  price,
 // dimenstion,
  artYear
}: ArtworksPropType) => {
  const [selected, setSelected] = useState(selectedArtworks.includes(id));
  return (
    <div
      className={`artcard ${selectedArtworks.includes(id) ? 'selected ' : ''}`}
      onClick={() => {
        setSelected(!selected);
        if (!selectedArtworks.includes(id)) {
          setSelectedArtworks([...selectedArtworks, id]);
        } else if (selectedArtworks.includes(id)) {
          setSelectedArtworks(selectedArtworks.filter((item) => item != id));
        }
      }}
    >
      <div className="card-body">
        <div className="ArtimageWrapper">
          <img src={artimage} alt="artwork" />
        </div>
        <div className="cardContent">
          {hastitle && (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="art-title manual-suggest-card-font-md font-weight-normal">{artistName !== '' && artistName}</div>
                <div className="art-title manual-suggest-card-font-sm font-weight-light"><i>{title !== '' && title.trim()}</i>{artYear !== '' && ', ' + artYear}</div>
                <div className="art-title manual-suggest-card-font-sm font-weight-light mt-3">{price !== '' && price}</div>
              </div>
            </div>
          )}
          <span className="art-action">
            <FiCheck />
          </span>
        </div>
      </div>
    </div>
  );
};
interface ArtworksPropType {
  artimage: any;
  title: string;
  hastitle: boolean;
  id: number;
  selectedArtworks: number[];
  setSelectedArtworks: Function;
  artistName: string;
  price: string;
  // dimenstion: string | null;
  artYear: string | null
}
