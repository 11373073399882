export const ArtworkDetailCrad = ({
  imageUrl,
  artistName,
  artworkTitle,
  price,
  medium,
  style,
  category,
}: ArtworkCardTypes) => {
  return (
    <div className="card ">
      <div className="card-header border-0">
        <div className="card-title m-0 n gap-3">
          <h3 className="fw-bolder m-0">Artwork Details</h3>
        </div>
      </div>
      <div className="card-body pt-0">
        {/* <h4 className="fw-bold fs-4 ">Add Artwork slider </h4> */}
        <img src={imageUrl} />
        <ul>
          <li>
            <span className="fw-bold fs-4">Artwork Artist:</span>
            <span className="fs-4 text-gray-400">{artistName}</span>
          </li>
          <li>
            <span className="fw-bold fs-4">Artwork Title:</span>
            <span className="fs-4 text-gray-400">{artworkTitle}</span>
          </li>
          <li>
            <span className="fw-bold fs-4">Price:</span>
            <span className="fs-4 text-gray-400">{price}</span>
          </li>
          <li>
            <span className="fw-bold fs-4">Medium:</span>
            <div className="d-flex flex-wrap gap-1">
              <span className="fs-4 text-gray-400">{medium}</span>
            </div>
          </li>
          <li>
            <span className="fw-bold fs-4">Style:</span>
            <div className="d-flex flex-wrap gap-1 select-items-view--wrapper">
              {/* <span className="fs-4 text-gray-400">
                {reservationData?.clientEmail}
              </span> */}
              <div className="select-items-view">
                {style?.map((style: any) => (
                  <span>{style}</span>
                ))}
              </div>
            </div>
          </li>
          <li>
            <span className="fw-bold fs-4">category:</span>
            <div className="d-flex flex-wrap gap-1 select-items-view--wrapper">
              {/* <span className="fs-4 text-gray-400 flex-wrap d-flex">
                {reservationData?.clientEmail}
              </span> */}
              <div className="select-items-view">
                {category?.map((style: any) => (
                  <span>{style}</span>
                ))}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

interface ArtworkCardTypes {
  // reservationData: reservationDataType | undefined;
  imageUrl: string;
  artistName: string;
  artworkTitle: string;
  price: string;
  medium: string;
  style: string[];
  category: string[];
}
