import React, { FC } from 'react';
import { useLayout } from '../../../core/LayoutProvider';
import { usePageData } from '../../../core/PageData';

export const DefaultTitle: FC = () => {
  return (
    <React.Fragment>
      <ol className="breadcrumb breadcrumb-line text-muted fs-6 fw-bold">
        <li className="breadcrumb-item pe-3">
          <a href="#" className="pe-3">
            Home
          </a>
        </li>
        {/* <li className="breadcrumb-item px-3 text-muted">?</li> */}
      </ol>
    </React.Fragment>
  );
};
