import React, { useEffect, useState } from 'react';
import './ArtDetails.scss';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  GET_ALL_USERS_URL,
  GET_ARTWORK_DETAILS_URL,
  GET_USER_FILTERS_URL,
} from '../../../../constants';
import { ArtworkDetailsData } from './ArtworkData';
import { columns } from '../Addartwork/utils/user-data';
import { ClientList } from '../../Clients/ClientsList';
import {
  filterData,
  userFilterType,
} from '../../../components/filters/UserFilters';
import { BiLike } from 'react-icons/bi';
import { FaRegLightbulb } from 'react-icons/fa';
import { Slider } from '../../../../_metronic/partials/content/Slider/Slider';
import { StatsCounter2 } from '../../../../_metronic/partials/content/StatsCounter/StatsCounter2';
import { breadcrumbType } from '../../../../_metronic/layout/MasterLayout';
import { useUser } from '../../../modules/auth/User-context';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const ArtDetails = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const [artWorkData, setArtWorkData] = useState<artworkType>();
  const { dispatch } = useUser();
  const { id }: any = useParams();
  const [activeTab, setActiveTab] = useState('likes');
  const [followingClientsData, setFollowingClientsData] = useState([]);
  const [followingTotalRecords, setFollowingTotalRecords] = useState(0);
  const [followingCurrentRecords, setFollowingCurrentRecords] = useState(0);
  const [suggestionClientsData, setSuggestionClientsData] = useState([]);
  const [suggestionTotalRecords, setSuggestionTotalRecords] = useState(0);
  const [suggestionCurrentRecords, setSuggestionCurrentRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [followingPageState, setFollowingPageState] = useState({
    filters: {
      text: '',
      categoryIds: [],
      styleIds: [],
      status: [],
      artworkId: id,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [suggestionPageState, setSuggestionPageState] = useState({
    filters: {
      text: '',
      categoryIds: [],
      styleIds: [],
      status: [],
      suggestedArtworkId: id,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [userFilters, setUserFilters] = useState<userFilterType>({
    categories: [],
    styles: [],
    status: [],
  });
  const [selectedUserFilters, setSelectedUserFilters] = useState({
    categories: [],
    styles: [],
    status: [],
  });
  function updateUserFilters(key: string, value: any) {
    setSelectedUserFilters({ ...selectedUserFilters, [key]: [...value] });
  }
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Artworks', to: '/artworks' },
      { title: 'Details', to: null },
    ]);
    axios({
      method: 'POST',
      url: GET_USER_FILTERS_URL,
    })
      .then(({ data }) => {
        setUserFilters({
          categories: data.data.category.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          styles: data.data.style.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          status: data.data.status.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
      });
    axios({
      method: 'POST',
      url: GET_ARTWORK_DETAILS_URL + id,
    }).then(({ data }) => {
      setArtWorkData(data.data);
    });
  }, []);
  useEffect(() => {
    if (activeTab === 'likes') {
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_USERS_URL,
        data: followingPageState,
      }).then(({ data }) => {
        if (data) {
          setFollowingClientsData(data.data);
          setFollowingTotalRecords(data.totalRecords);
          setFollowingCurrentRecords(data.data.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, followingPageState]);
  useEffect(() => {
    if (activeTab === 'suggestions') {
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_USERS_URL,
        data: suggestionPageState,
      }).then(({ data }) => {
        if (data) {
          setSuggestionClientsData(data.data);
          setSuggestionTotalRecords(data.totalRecords);
          setSuggestionCurrentRecords(data.data.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, suggestionPageState]);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-8 col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
          <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="fs-1 fw-bold me-1 my-5 ">Artwork</h4>
                {artWorkData?.id && (
                  <Link
                    className="d-inline-flex btn btn-dark btn-sm align-items-center art-btn gap-2"
                    to={{
                      pathname: `/editartworks/${artWorkData?.id}`,
                    }}
                  >
                    Edit
                  </Link>
                )}
              </div>
              <Slider imageUrls={artWorkData?.imageUrls || []} />
            </div>
          </div>
          <div className="card mb-5 mb-xl-10">
            <div className="card-body ">
              <div className="d-flex artdetails-wrapper-card">
                <div
                  className=" mb-3 mb-md-0"
                  onClick={() => {
                    setActiveTab('likes');
                  }}
                >
                  <StatsCounter2
                    isActive={activeTab === 'likes'}
                    counts={artWorkData?.likeCount || 0}
                    icon={<BiLike />}
                    title={'likes'}
                  />
                </div>
                <div
                  className="mb-3 mb-md-0"
                  onClick={() => {
                    setActiveTab('suggestions');
                  }}
                >
                  <StatsCounter2
                    isActive={activeTab === 'suggestions'}
                    counts={artWorkData?.suggestionCount || 0}
                    icon={<FaRegLightbulb />}
                    title={'Suggestions'}
                  />
                </div>
              </div>
            </div>
            {activeTab === 'likes' && (
              <ClientList
                selectedUserFilters={selectedUserFilters}
                updateUserFilters={updateUserFilters}
                userFilters={userFilters}
                currentRecords={followingCurrentRecords}
                userData={followingClientsData}
                columns={columns}
                setIsLoading={setIsLoading}
                totalRecords={followingTotalRecords}
                pageState={followingPageState}
                setPageState={setFollowingPageState}
                getAllClients={undefined}
              />
            )}
            {activeTab === 'suggestions' && (
              <ClientList
                selectedUserFilters={selectedUserFilters}
                updateUserFilters={updateUserFilters}
                userFilters={userFilters}
                currentRecords={suggestionCurrentRecords}
                userData={suggestionClientsData}
                columns={columns}
                setIsLoading={setIsLoading}
                totalRecords={suggestionTotalRecords}
                pageState={suggestionPageState}
                setPageState={setSuggestionPageState}
                getAllClients={undefined}
              />
            )}
          </div>
        </div>
        <ArtworkDetailsData
          artWorkData={artWorkData}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      </div>
    </React.Fragment>
  );
};
export type artworkType = {
  artist: {
    artworkCount: number;
    artworks: artworkType[];
    description: string;
    id: number;
    imageUrl: string;
    isFollowed: boolean;
    name: string;
  };
  availability: string;
  buyerInfo: any;
  reserverInfo: any;
  categories: { id: number; name: string }[];
  dimensions: string;
  edition: string;
  height: number;
  framed: string;
  id: number;
  imageUrl: string;
  imageUrls: string[];
  likeCount: number;
  location: string;
  medium: string;
  name: string;
  price: string;
  styles: { id: number; name: string }[];
  suggestionCount: number;
  tags: string;
  width: number;
  depth: number;
  year: string;
  framingDetail: string;
  isPrivate: boolean;
  isFeatured: boolean;
  description: string;
  privateNotes: string;
  unit: string;
};
