import { useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useUser } from '../User-context';
import { loginSchema, initialValues } from './utils/FormikUtils';
import { sendAxios } from '../../../../setup/axios/SetupAxios';
import { LOGIN_REQUEST_URL } from '../../../../constants';
import { FormLabel } from '../../../../_metronic/partials/content/Label/FormLabel';
export function Login() {
  const [loginError, setLoginError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useUser();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      const obj = { email: values.email, password: values.password };
      sendAxios({
        method: 'post',
        url: LOGIN_REQUEST_URL,
        data: obj,
      })
        .then(({ data }) => {
          // handle success
          if (!data.isSuccess) {
            setLoginError(data.message);
            setLoading(false);
          } else {
            localStorage.token = data.data.token;
            dispatch({
              type: 'add-user-data',
              payload: {
                email: data.data.user.email,
                fullName: data.data.user.fullName,
                id: data.data.user.id,
                imageUrl: data.data.user.imageUrl,
                phoneNumber: data.data.user.phoneNumber,
                status: data.data.user.status,
                isLogin: true,
              },
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          // handle error
          setLoginError(error.message);
          setLoading(false);
        });
    },
  });
  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign In to Artvisor</h1>
      </div>
      <div className="fv-row mb-10">
        <FormLabel label={' Email'} />
        <input
          placeholder="Email"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            { 'is-valid': formik.touched.email && !formik.errors.email }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-32">
        <FormLabel label={'Password'} />
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.password && formik.errors.password },
            { 'is-valid': formik.touched.password && !formik.errors.password }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-dark w-100 mb-5"
        >
          {!loading && <span className="indicator-label">LOG IN</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {loginError && (
        <div className="text-danger">
          <p>{loginError}</p>
        </div>
      )}
    </form>
  );
}
