import React from 'react';

export const StatsCounter2 = ({
  counts,
  icon,
  title,
  isActive,
}: StatsPropTypes) => {
  return (
    <div
      className={
        'statscard-view rounded px-6  ' + (isActive ? ' active-stats-tab' : '')
      }
    >
      <span className=" svg-icon-drak  icon line-height-1">{icon}</span>

      <span className=" fw-bold fs-5 mx-2">{title}</span>
      {!(title == 'Survey Response' || title == 'Suggest Artworks') && (
        <span className="counts mx-3">({counts})</span>
      )}
    </div>
  );
};
interface StatsPropTypes {
  counts: number;
  icon: any;
  title: string;
  isActive: boolean;
}
