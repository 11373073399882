import { useEffect, useState } from 'react';
import { Art } from '../../../../app/pages/Clients/Survey/Art';
import { useUser } from '../../../../app/modules/auth/User-context';
import { useLayout } from '../../../layout/core';
import { artwork } from '../../../../app/pages/artworks/utils';
import {
  ArtworkFilter,
  artworkFilterType,
  filterData,
  selectedArtworkFilterType,
  sizeType,
} from '../../../../app/components/filters/ArtworkFilters';
import axios from 'axios';
import {
  GET_ALL_ARTISTS_INFO_URL,
  GET_ARTWORK_FILTERS_URL,
  GET_MANUAL_SUGGESTION_ARTWORKS,
  MANUAL_SUGGEST_ARTWORKS_URL,
} from '../../../../constants';
import { Artworks, handleArtworkFiltersProps } from '../../../../app/pages/artworks/Artworks';
import { SearchBar } from '../SearchBar/SearchBar';
import { Pagination } from '../pagination/Pagination';
import { Alert, Button } from 'react-bootstrap';
type propType = {
  userId: number;
  getData: Function;
};
export const SuggestArt = ({ userId, getData }: propType) => {
  const { dispatch } = useUser();
  const [artWorksData, setArtWorksData] = useState<artwork[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [selectedArtworks, setSelectedArtworks] = useState<number[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const [pageState, setPageState] = useState<pageStateType>({
    filters: {
      text: '',
      categoryIds: [],
      styleIds: [],
      artists: [],
      status: [],
      price: [0, 125000],
      size: [],
      year: [1940, 2020],
      currentUserId: userId,
    },
    pageInfo: {
      pageSize: 100,
      pageNumber: 1,
    }, //,
  });
  const [artWorkFilters, setArtWorkFilters] = useState<artworkFilterType>({
    categories: [],
    styles: [],
    status: [],
    artists: [],
    price: [],
    size: [],
  });
  const [selectedArtWorkFilters, setSelectedArtWorkFilters] =
    useState<selectedArtworkFilterType>({
      categories: [],
      styles: [],
      artists: [],
      status: [],
      price: [],
      size: [],
      year: [],
    });
  function updateArtworkFilters(key: string, value: any) {
    setSelectedArtWorkFilters({ ...selectedArtWorkFilters, [key]: [...value] });
  }
  async function getAllFilters() {
    let filters: any = await axios({
      method: 'POST',
      url: GET_ARTWORK_FILTERS_URL,
    });
    let filtersData = {
      categories: filters.data.data.category.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      styles: filters.data.data.style.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      status: filters.data.data.status.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      price: filters.data.data.price.map((item: filterData) => {
        return { label: item.value, value: item.key };
      }),
      size: filters.data.data.size.map((item: sizeType) => {
        return {
          label: item.value.key + ' (' + item.value.value + ')',
          value: item.key,
        };
      }),
    };
    // });
    filters = await axios({
      method: 'POST',
      url: GET_ALL_ARTISTS_INFO_URL,
    });

    let artists = filters.data.data.map((item: any) => {
      return { label: item.name, value: item.id };
      // }),
    });
    // console.log(filtersData, artists);
    setArtWorkFilters({
      categories: filtersData.categories,
      price: filtersData.price,
      size: filtersData.size,
      status: filtersData.status,
      styles: filtersData.styles,
      artists: artists,
    });
  }
  useEffect(() => {
    getAllFilters();
  }, []);
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }
  function handleFiltersChange({
    categories,
    styles,
    status,
    price,
    size,
    year,
  }: handleArtworkFiltersProps) {
    setPageState({
      ...pageState,
      filters: {
        ...pageState.filters,
        styleIds: styles.map((style) => style.value),
        categoryIds: categories.map((category) => category.value),
        status: status.map((item) => item.value),
        price,
        size: size.map((item) => item.value),
        year,
      },
    });
  }
  function handleSearchClick(search: string): void {
    setPageState({
      filters: {
        text: search,
        categoryIds: [],
        artists: [],
        styleIds: [],
        status: [],
        price: [0, 125000],
        size: [],
        year: [1940, 2020],
        currentUserId: userId,
      },
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
      }, //,
    });
  }
  function suggestNewArtworks() {
    axios({
      method: 'POST',
      url: MANUAL_SUGGEST_ARTWORKS_URL,
      data: { lstArtworkId: selectedArtworks, userId },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'Artwork Suggested Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
          getData();
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    let data = {
      filters: {
        currentUserId: userId,
        artistId: pageState.filters.artists,
        text: pageState.filters.text,
        categoryIds: pageState.filters.categoryIds,
        styleIds: pageState.filters.styleIds,
        status: pageState.filters.status,
        size: pageState.filters.size,
        startPrice:
          pageState.filters.price[0] && pageState.filters.price[0] == 0
            ? -1
            : pageState.filters.price[0],
        endPrice:
          pageState.filters.price[1] && pageState.filters.price[1] === 125000
            ? -1
            : pageState.filters.price[1] === 0
              ? -2
              : pageState.filters.price[1],
        startYear:
          pageState.filters.year[0] && pageState.filters.year[0] <= 1950
            ? -1
            : pageState.filters.year[0] >= 2020
              ? -2
              : pageState.filters.year[0],
        endYear:
          pageState.filters.year[1] && pageState.filters.year[1] == 2020
            ? -1
            : pageState.filters.year[1] <= 1950
              ? -2
              : pageState.filters.year[1],
      },
      pageInfo: {
        pageSize: pageState.pageInfo.pageSize,
        pageNumber: pageState.pageInfo.pageNumber,
      }, //,
    };
    axios({
      method: 'POST',
      url: GET_MANUAL_SUGGESTION_ARTWORKS,
      data: data,
    }).then(({ data }) => {
      if (data?.data) {
        setArtWorksData(
          data.data?.map((item: any) => {
            return {
              ...item,
              key: item.id,
            };
          })
        );
        setTotalRecords(data.totalRecords);
        setCurrentRecords(data.data?.length);
      }
      setIsLoading(false);
    });
  }, [pageState]);
  return (
    <div className="c">
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="row">
        <div className="card-header d-flex justify-content-between  border-1 align-items-center ">
          <div className="card-title align-items-start flex-column">
            <h3 className="fw-bolder m-0">Artworks</h3>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            <SearchBar handleSearchClick={handleSearchClick} />
            <ArtworkFilter
              handleFiltersChange={handleFiltersChange}
              selectedArtWorkFilters={selectedArtWorkFilters}
              artWorkFilters={artWorkFilters}
              updateArtworkFilters={updateArtworkFilters}
            />
            <Button
              variant="dark"
              onClick={suggestNewArtworks}
              disabled={selectedArtworks.length <= 0}
            >
              Suggest Selected
            </Button>
          </div>
        </div>
        {selectedArtWorkFilters &&
          (selectedArtWorkFilters.categories.length > 0 ||
            selectedArtWorkFilters.styles.length > 0 ||
            selectedArtWorkFilters.status.length > 0 ||
            selectedArtWorkFilters.price.length > 0 ||
            selectedArtWorkFilters.size.length > 0) && (
            <div className="select-items-view--wrapper">
              <div className="select-items-view">
                <h6>Selected Filters</h6>
                {selectedArtWorkFilters.categories.length > 0
                  ? selectedArtWorkFilters.categories.map((category: any) => (
                    <span>{category.label}</span>
                  ))
                  : ''}
                {selectedArtWorkFilters.styles.length > 0
                  ? selectedArtWorkFilters.styles.map((category: any) => (
                    <span>{category.label}</span>
                  ))
                  : ''}
                {selectedArtWorkFilters.status.length > 0
                  ? selectedArtWorkFilters.status.map((category: any) => (
                    <span>{category.label}</span>
                  ))
                  : ''}
                {selectedArtWorkFilters.price.length > 0
                  ? selectedArtWorkFilters.price.map((category: any) => (
                    <span>{category.label}</span>
                  ))
                  : ''}
                {selectedArtWorkFilters.size.length > 0
                  ? selectedArtWorkFilters.size.map((category: any) => (
                    <span>{category.label}</span>
                  ))
                  : ''}
              </div>
            </div>
          )}

        <div className='select-artwork-container'>
          {artWorksData.map((item) => (
            <div className="suggestArtworksCrad">
              <Art
                selectedArtworks={selectedArtworks}
                setSelectedArtworks={setSelectedArtworks}
                artimage={item.imageUrl}
                title={item.name}
                hastitle={true}
                id={item.id || 0}
                artistName={item.artistName}
                artYear={item.year}
                price={item.price}
              // dimenstion = {item.width + 'x' + item.height}
              />
            </div>
          ))}
        </div>

      </div>
      <Pagination
        currentRecords={currentRecords}
        setIsLoading={setIsLoading}
        totalRecords={totalRecords}
        pageSize={pageState.pageInfo.pageSize}
        pageNumber={pageState.pageInfo.pageNumber}
        handlePageSize={handlePageSize}
        handlePageNumber={handlePageNumber}
      />
    </div>
  );
};
type pageStateType = {
  filters: {
    artists: any[];
    text: string;
    categoryIds: {}[];
    styleIds: {}[];
    status: {}[];
    price: number[];
    year: number[];
    size: {}[];
    currentUserId: number;
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  }; //,
};
