import React, { useEffect, useState } from 'react';
import { columns } from './User-Data';
import user from '../../../assets/Artwork/user.png';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { GET_ALL_ADMIN_USERS_URL } from '../../../constants';
import axios from 'axios';
import { Spinner } from 'react-bootstrap-v5';
import { UsersList } from './UsersList';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const Users = ({ breadcrumbData, setBreadcrumbData }: propType) => {
  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [pageState, setPageState] = useState<pageStateType>({
    filters: {
      text: '',
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  useEffect(() => {
    setBreadcrumbData([{ title: 'Users', to: null }]);
  }, []);
  function getAllUsers() {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: GET_ALL_ADMIN_USERS_URL,
      data: pageState,
    }).then(({ data }) => {
      if (data) {
        setUserData(data.data);
        setTotalRecords(data.totalRecords);
        setCurrentRecords(data.data.length);
      }
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getAllUsers();
  }, [pageState]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <UsersList
          getAllUsers={getAllUsers}
          currentRecords={currentRecords}
          userData={userData}
          columns={columns}
          setIsLoading={setIsLoading}
          totalRecords={totalRecords}
          pageState={pageState}
          setPageState={setPageState}
        />
      </React.Fragment>
    );
  }
};
export interface pageStateType {
  filters: {
    text: string;
  };
  pageInfo: {
    pageSize: number;
    pageNumber: number;
  };
}
