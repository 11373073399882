import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import axios from 'axios';
import {
  DEACTIVATE_CLIENT_URL,
  GET_ALL_ARTICLES_URL,
  GET_ALL_USERS_URL,
  GET_ARTICLE_DETAILS_URL,
  GET_USER_FILTERS_URL,
} from '../../../constants';
import { columns } from '../artworks/Addartwork/utils/user-data';
import { ClientList } from '../Clients/ClientsList';
import JournalsList from './journal-listing/JournalsList';
import {
  filterData,
  userFilterType,
} from '../../components/filters/UserFilters';
import { StatsCounter2 } from '../../../_metronic/partials/content/StatsCounter/StatsCounter2';
import { BsBookmarkCheck, BsImages } from 'react-icons/bs';
import { breadcrumbType } from '../../../_metronic/layout/MasterLayout';
import { useUser } from '../../modules/auth/User-context';
type propType = {
  breadcrumbData: breadcrumbType[];
  setBreadcrumbData: Function;
};
export const JournalDetails = ({
  breadcrumbData,
  setBreadcrumbData,
}: propType) => {
  const { dispatch } = useUser();
  const { id }: any = useParams();
  const [activeTab, setActiveTab] = useState('bookmarks');
  const [isLoading, setIsLoading] = useState(false);
  const [journalsData, setJournalsData] = useState<journalsType>();
  const [bookmarksData, setBookmarksData] = useState([]);
  const [bookmarksTotalRecords, setBookmarksTotalRecords] = useState(0);
  const [bookmarksCurrentRecords, setBookmarksCurrentRecords] = useState(0);
  const [bookmarksPageState, setBookmarksPageState] = useState({
    filters: {
      text: '',
      categoryIds: [],
      styleIds: [],
      status: [],
      articleId: parseInt(id),
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [relatedData, setRelatedData] = useState([]);
  const [relatedTotalRecords, setRelatedTotalRecords] = useState(0);
  const [relatedCurrentRecords, setRelatedCurrentRecords] = useState(0);
  const [relatedPageState, setRelatedPageState] = useState({
    filters: {
      text: '',
      notIncludeArticleId: parseInt(id),
      categoryId: 0,
    },
    pageInfo: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  function makeArtistInactive(id: number) {
    axios({
      method: 'GET',
      url: DEACTIVATE_CLIENT_URL + id,
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'User Deactivated Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  useEffect(() => {
    setBreadcrumbData([
      { title: 'Journal', to: '/journals' },
      { title: 'Details', to: null },
    ]);
    axios({
      method: 'GET',
      url: GET_ARTICLE_DETAILS_URL + id,
    }).then(({ data }) => {
      setJournalsData(data?.data);
      setRelatedPageState({
        ...relatedPageState,
        filters: {
          ...relatedPageState.filters,
          categoryId: data.data?.categoryId,
        },
      });
    });
  }, []);
  useEffect(() => {
    if (activeTab === 'bookmarks') {
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_USERS_URL,
        data: bookmarksPageState,
      }).then(({ data }) => {
        if (data) {
          setBookmarksData(data.data);
          setBookmarksTotalRecords(data.totalRecords);
          setBookmarksCurrentRecords(data.data.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, bookmarksPageState]);
  useEffect(() => {
    if (activeTab === 'relatedArticles') {
      setIsLoading(true);
      axios({
        method: 'POST',
        url: GET_ALL_ARTICLES_URL,
        data: relatedPageState,
      }).then(({ data }) => {
        if (data) {
          setRelatedData(
            data.data.map((item: any) => {
              return {
                ...item,
                key: item.id,
              };
            })
          );
          setRelatedTotalRecords(data.totalRecords);
          setRelatedCurrentRecords(data.data.length);
        }
        setIsLoading(false);
      });
    }
  }, [activeTab, relatedPageState]);
  const [userFilters, setUserFilters] = useState<userFilterType>({
    categories: [],
    styles: [],
    status: [],
  });
  const [selectedUserFilters, setSelectedUserFilters] = useState({
    categories: [],
    styles: [],
    status: [],
  });
  function updateUserFilters(key: string, value: any) {
    setSelectedUserFilters({ ...selectedUserFilters, [key]: [...value] });
  }
  useEffect(() => {
    axios({
      method: 'POST',
      url: GET_USER_FILTERS_URL,
    })
      .then(({ data }) => {
        setUserFilters({
          categories: data.data.category.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          styles: data.data.style.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
          status: data.data.status.map((item: filterData) => {
            return { label: item.value, value: item.key };
          }),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
      });
  }, []);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-8 col-lg-12 col-sm-12 col-md-12 col-xs-12">
          <div className="card mb-5 mb-xl-10">
            <div className="card-body  ">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="fs-1 fw-bold me-1 mb-5">Article</h4>

                {journalsData?.id && (
                  <Link
                    className="d-inline-flex btn btn-dark btn-sm align-items-center art-btn gap-2"
                    to={{
                      pathname: `/editarticle/${journalsData.id}`,
                    }}
                  >
                    Edit
                  </Link>
                )}
              </div>
              {journalsData &&
              // journalsData.description == null &&
              journalsData.articleLink?.length > 0 &&
              journalsData.articleLinkHtml?.length > 0 ? (
                <span
                  contentEditable="false"
                  className="h-400px scroll-y d-block"
                  dangerouslySetInnerHTML={{
                    __html: journalsData?.articleLinkHtml,
                  }}
                >
                  {/* {journalsData?.articleLinkHtml} */}
                  {/* <iframe
                      className="h-100 w-100"
                      src={journalsData.articleLink}
                    /> */}
                </span>
              ) : (
                journalsData &&
                journalsData?.description?.length > 0 && (
                  <span
                    contentEditable="false"
                    className="h-400px scroll-y d-block"
                  >
                    <CKEditor
                      disabled={true}
                      editor={DecoupledEditor}
                      data={journalsData?.description}
                    />
                  </span>
                )
              )}
            </div>
          </div>
          <div className="card mb-5 mb-xl-10 journal-cards">
            <div className="card-body border-bottom pt-9 pb-9">
              <div className="d-flex  artdetails-wrapper-card  mb-32">
                <div
                  onClick={() => {
                    setActiveTab('bookmarks');
                  }}
                >
                  <StatsCounter2
                    isActive={activeTab === 'bookmarks'}
                    counts={
                      journalsData?.bookmarkCount
                        ? journalsData?.bookmarkCount
                        : 0
                    }
                    icon={<BsBookmarkCheck />}
                    title={'Bookmarks'}
                  />
                </div>
                <div
                  onClick={() => {
                    setActiveTab('relatedArticles');
                  }}
                >
                  <StatsCounter2
                    isActive={activeTab === 'relatedArticles'}
                    counts={
                      journalsData?.relatedArticleCount
                        ? journalsData?.relatedArticleCount
                        : 0
                    }
                    icon={<BsImages />}
                    title={'Related Articles'}
                  />
                </div>
              </div>
              {activeTab === 'bookmarks' && (
                <ClientList
                  selectedUserFilters={selectedUserFilters}
                  updateUserFilters={updateUserFilters}
                  userFilters={userFilters}
                  currentRecords={bookmarksCurrentRecords}
                  userData={bookmarksData}
                  columns={columns}
                  setIsLoading={setIsLoading}
                  totalRecords={bookmarksTotalRecords}
                  pageState={bookmarksPageState}
                  setPageState={setBookmarksPageState}
                  getAllClients={undefined}
                />
              )}
              {activeTab === 'relatedArticles' && (
                <JournalsList
                  articlesData={relatedData}
                  pageState={relatedPageState}
                  setPageState={setRelatedPageState}
                  setIsLoading={setIsLoading}
                  totalRecords={relatedTotalRecords}
                  currentRecords={relatedCurrentRecords}
                  getAllArticles={undefined}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-12 col-sm-12 col-md-12 col-xs-12 order-first order-lg-last">
          <div className="card mb-5 mb-xl-10">
            <div className="card-body border-bottom pt-9 pb-9">
              <div className="card-body p-0">
                <h4 className="fs-1 fw-bold me-1 mb-5">Cover Image</h4>
                <div className="article-cover mb-32">
                  <img src={journalsData?.imageUrl} />
                </div>
                <div className=" mt-5">
                  <h4 className="fs-1 fw-bold me-1 my-5 ">Article Details</h4>
                  <ul>
                    <li>
                      <span className="fw-bold fs-4">Title:</span>
                      <span className="fs-4 text-gray-400 text-end">
                        {journalsData?.title}
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold fs-4">Artist Name:</span>
                      <span className="fs-4 text-gray-400 text-end">
                        {journalsData?.author}
                      </span>
                    </li>
                    <li>
                      <span className="fw-bold fs-4">Publish date:</span>
                      <span className="fs-4 text-gray-400 text-end">
                        {journalsData?.publishDate
                          ? new Date(journalsData?.publishDate).toDateString()
                          : 'Not Available'}
                      </span>
                    </li>
                  </ul>
                  <div className="row">
                    <div className="col-lg-12 fv-row select-items-view--wrapper">
                      <span className="fw-bold fs-4 mb-2 d-block">
                        Categories:
                      </span>
                      <div className="select-items-view">
                        {journalsData?.articleCategory &&
                          journalsData?.articleCategory?.map(
                            (category: any) => <span>{category.name}</span>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-12 fv-row select-items-view--wrapper">
                      <span className="fw-bold fs-4 mb-2 d-block">Tags:</span>
                      <div className="select-items-view">
                        {journalsData?.tags.split(',').map((item) => (
                          <span>{item}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export type journalsType = {
  id: number;
  title: string;
  imageUrl: string;
  publishDate: string;
  author: string;
  description: string;
  tags: string;
  artistId: number;
  bookmarkCount: number;
  relatedArticleCount: number;
  isBookmarked: boolean;
  articleCategory: [];
  articleStyle: [];
  articleLink: string;
  articleLinkHtml: string;
};
