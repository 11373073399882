import axios from 'axios';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { HiPlus } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { DELETE_ADMIN_USER_URL } from '../../../constants';
import { LinkTag } from '../../../_metronic/partials/content/Button/LinkTag';
import { Pagination } from '../../../_metronic/partials/content/pagination/Pagination';
import { SearchBar } from '../../../_metronic/partials/content/SearchBar/SearchBar';
import ListTable from '../../components/listing-table';
import { useUser } from '../../modules/auth/User-context';
import { rowData } from './User-Data';

type UserListProps = {
  currentRecords: any;
  userData: any;
  columns: any;
  setIsLoading: any;
  totalRecords: any;
  pageState: any;
  setPageState: any;
  getAllUsers: Function | undefined;
};
export const UsersList = ({
  currentRecords,
  userData,
  columns,
  setIsLoading,
  totalRecords,
  pageState,
  setPageState,
  getAllUsers,
}: UserListProps) => {
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    variant: '',
    message: '',
    heading: '',
  });
  const { dispatch } = useUser();
  function handlePageSize(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageSize: size },
    });
  }
  function handlePageNumber(size: number) {
    setPageState({
      ...pageState,
      pageInfo: { ...pageState.pageInfo, pageNumber: size },
    });
  }
  function handleSearchClick(search: string): void {
    setPageState({
      filters: {
        text: search,
      },
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
      },
      // ...pageState,
      // filters: { ...pageState.filters, text: search },
    });
  }
  function makeArtistInactive(id: number) {
    axios({
      method: 'DELETE',
      url: DELETE_ADMIN_USER_URL + id,
      data: { artistId: id },
    })
      .then(({ data }) => {
        if (data.isSuccess) {
          setAlertData({
            variant: 'success',
            message: 'User Deactivated Successfully',
            heading: 'Success',
          });
          setShowAlert(true);
          if (getAllUsers) getAllUsers();
        } else {
          setAlertData({
            variant: 'danger',
            message: data.message,
            heading: 'Failed',
          });
          setShowAlert(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          localStorage.clear();
          dispatch({ type: 'remove-user' });
        }
        setAlertData({
          variant: 'danger',
          heading: 'Failed',
          message: error.message,
        });
        setShowAlert(true);
      });
  }
  return (
    <div className="card ">
      {showAlert && (
        <Alert
          variant={alertData.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertData.heading}</Alert.Heading>
          <p>{alertData.message}</p>
        </Alert>
      )}
      <div className="card-header border-1 pt-5">
        <div className="card-title align-items-start flex-column">
          <h3 className="fw-bolder m-0"> Users</h3>
        </div>
        <div>
          <div className="d-flex gap-3 flex-wrap">
            <SearchBar handleSearchClick={handleSearchClick} />{' '}
            {location.pathname == '/users' && (
              <LinkTag
                To={'/edituser'}
                icon={<HiPlus />}
                text={'Create User'}
                hasIcon={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          {userData?.length <= 0 ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-30 data-not-found">
              <h1>No Data Found</h1>
            </div>
          ) : (
            <ListTable
              columns={columns}
              userData={rowData(userData, makeArtistInactive)}
            />
          )}
          <Pagination
            currentRecords={currentRecords}
            setIsLoading={setIsLoading}
            totalRecords={totalRecords}
            pageSize={pageState.pageInfo.pageSize}
            pageNumber={pageState.pageInfo.pageNumber}
            handlePageSize={handlePageSize}
            handlePageNumber={handlePageNumber}
          />
        </div>
      </div>
    </div>
  );
};
