import { artworkType } from './ArtDetails';
import blank from '../../../../_metronic/assets/images/blank.png';
import { AiOutlineMail } from 'react-icons/ai';
import { BadgeStatus } from '../../../../_metronic/partials/content/badges/BadgeStatus';

interface IArtworkDetailsComponentProps {
  artWorkData: artworkType | undefined;
  setActiveTab: Function;
  activeTab: string;
}

export const ArtworkDetailsData = ({
  artWorkData,
}: IArtworkDetailsComponentProps) => {
  return (
    <div className="col-xl-4 col-lg-12 col-sm-12 col-md-12 col-xs-12  order-first order-lg-last ">
      <div className="card  mb-xl-10">
        <div className="card-body border-bottom pb-9">
          {artWorkData?.buyerInfo && (
            <div>
              <h4 className="fs-1 fw-bold me-1 mb-5">Buyer's information</h4>
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center">
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-100px symbol-fixed position-relative">
                    <img
                      src={
                        artWorkData.buyerInfo
                          ? artWorkData.buyerInfo.imageUrl
                          : blank
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-1">
                        <span className="fs-2 fw-bold me-1">
                          {artWorkData.buyerInfo.fullName || 'Not Available'}
                        </span>
                      </div>
                      <div className="d-flex flex-wrap mb-1 pe-2">
                        <span className="d-flex align-items-center fs-2 me-5 mb-2 gap-2">
                          <AiOutlineMail className="fs-2" />
                          <span className="text-gray-400 fs-4">
                            {artWorkData.buyerInfo.email || 'Not Available'}
                          </span>
                        </span>
                      </div>
                      <div className="d-flex flex-wrap mb-1 pe-2">
                        <span className="d-flex align-items-center text-gray-400 fs-4 me-5 mb-2 gap-2">
                          <span className="fw-bold text-dark"> Buy Date:</span>
                          {artWorkData.buyerInfo.soldDate || 'Not Available'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {artWorkData?.reserverInfo && (
            <div>
              <h4 className="fs-1 fw-bold me-1 mb-5">Buyer's information</h4>
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center">
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-100px symbol-fixed position-relative">
                    <img
                      src={
                        artWorkData.reserverInfo
                          ? artWorkData.reserverInfo.imageUrl
                          : blank
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-1">
                        <span className="fs-2 fw-bold me-1">
                          {artWorkData.reserverInfo.fullName || 'Not Available'}
                        </span>
                      </div>
                      <div className="d-flex flex-wrap mb-1 pe-2">
                        <span className="d-flex align-items-center fs-2 me-5 mb-2 gap-2">
                          <AiOutlineMail className="fs-2" />
                          <span className="text-gray-400 fs-4">
                            {artWorkData.reserverInfo.email || 'Not Available'}
                          </span>
                        </span>
                      </div>
                      <div className="d-flex flex-wrap mb-1 pe-2">
                        <span className="d-flex align-items-center text-gray-400 fs-4 me-5 mb-2 gap-2">
                          <span className="fw-bold text-dark"> Buy Date:</span>
                          {artWorkData.reserverInfo.date || 'Not Available'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-5">
            <h4 className="fs-1 fw-bold me-1 my-5 ">Artwork Details</h4>
            <ul>
              <li>
                <span className="fw-bold fs-4">Artist Name:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.artist.name || 'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Title:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.name || 'Not Available'}
                </span>
              </li>

              <li>
                <span className="fw-bold fs-4">Year:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.year || 'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Medium:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.medium || 'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Price:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.price || 'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Location:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.location || 'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Dimensions:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.height + ' x ' + artWorkData?.width + ' x ' + artWorkData?.depth + ' ' + artWorkData?.unit ||
                    'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Status:</span>
                <span className="fs-4 text-gray-400 text-end">
                  <BadgeStatus
                    status={
                      artWorkData?.availability.toLowerCase() || 'available'
                    }
                  />
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Featured:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.isFeatured ? 'Yes' : 'No'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Private:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.isPrivate ? 'Yes' : 'No'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Description:</span>
              </li>
              <li>
                <span className="fs-4 text-gray-400">
                  {artWorkData?.description}
                </span>
              </li>
            </ul>
            <h4 className="fs-1 fw-bold me-1 my-5 ">Framing Details</h4>
            <ul>
              <li>
                <span className="fw-bold fs-4">Framed:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.framed ? 'Yes' : 'No'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Dimensions:</span>
                <span className="fs-4 text-gray-400 text-end">
                  {artWorkData?.dimensions || 'Not Available'}
                </span>
              </li>
              <li>
                <span className="fw-bold fs-4">Type:</span>
                <span className="fs-4 text-gray-400 text-wrap text-end">
                  {artWorkData?.framingDetail || 'Not Available'}
                </span>
              </li>
            </ul>
            <div className="row">
              <div className="col-lg-12 fv-row select-items-view--wrapper">
                {/* <span className="fw-bold fs-4 mb-2 d-block">Categories:</span> */}
                <h4 className="fs-1 fw-bold me-1 my-5 ">Categories</h4>
                <div className="select-items-view">
                  {artWorkData?.categories?.map((category) => (
                    <span>{category.name}</span>
                  ))}
                </div>
              </div>
              <div className="col-lg-12 mb-6 select-items-view--wrapper">
                <h4 className="fs-1 fw-bold me-1 my-5 ">Styles</h4>
                <div className="select-items-view">
                  {artWorkData?.styles?.map((style) => (
                    <span>{style.name}</span>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-12 fv-row select-items-view--wrapper">
              {/* <span className="fw-bold fs-4 mb-2 d-block">Tags:</span> */}
              <h4 className="fs-1 fw-bold me-1 my-5 ">Private Notes</h4>
              <div className="select-items-view">
                {artWorkData?.privateNotes || 'Not Available'}
              </div>
            </div>

            <div className="col-lg-12 fv-row select-items-view--wrapper">
              {/* <span className="fw-bold fs-4 mb-2 d-block">Tags:</span> */}
              <h4 className="fs-1 fw-bold me-1 my-5 ">Tags</h4>
              <div className="select-items-view">
                {artWorkData?.tags?.split(',').map((item) => (
                  <span>{item}</span>
                )) || 'Not Available'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
