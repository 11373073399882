import React from 'react';
import { VscDebugStackframeDot } from 'react-icons/vsc';
import { DotIcon } from '../../../../assets/SVG/svg';

export const BadgeStatus = (Props: BadgeStatusPropsType) => {
  return (
    <React.Fragment>
      <span
        className={`badge 
        ${
          Props.status.toLocaleLowerCase() == 'available'
            ? 'badge-light-primary'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'active'
            ? 'badge-light-success'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'reserved'
            ? 'badge-light-success'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'sold' ? 'badge-light-danger' : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'inactive'
            ? 'badge-light-danger'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'resolved'
            ? 'badge-light-success'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'unresolved'
            ? 'badge-light-danger'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'de-activate' || Props.status.toLocaleLowerCase() == 'de-activated'
            ? 'badge-light-danger'
            : ''
        }
        ${
          Props.status.toLocaleLowerCase() == 'awaiting approval'
            ? 'badge-light-warning'
            : ''
        }
        `}
      >
        <DotIcon color={'#12B76A'} /> {Props.status}
      </span>
    </React.Fragment>
  );
};
interface BadgeStatusPropsType {
  status: string;
}
