import axios from 'axios';
export default function setupAxios() {
  const accessToken: string | null = localStorage.getItem('token');
  axios.interceptors.request.use(
    (req: any) => {
      if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
      }
      return req;
    },
    (error: any) => {}
  );
}
export const sendAxios = (req: object) => {
  return axios(req);
};
