import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { IoFilter } from "react-icons/io5";
import { Option } from "react-multi-select-component/dist/types/lib/interfaces";
import { useLocation } from "react-router-dom";
import { GET_USER_FILTERS_URL } from "../../../constants";
import { ArtButton } from "../../../_metronic/partials/content/Button/ArtButton";
import { SelectDropdown } from "../../../_metronic/partials/content/SelectDropdown/SelectDropdown";
export const UserFilters = ({
  handleFiltersChange,
  userFilters,
  selectedUserFilters,
  updateUserFilters,
}: propType) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <ArtButton
        buttonText={"Filters"}
        styleclass={"d-inline-flex align-items-center"}
        variant={"outline-dark"}
        icon={<IoFilter />}
        hasIcon={true}
        onClick={handleShow}
      />
      <Modal className="right" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-filter">
          <SelectDropdown
            filters={selectedUserFilters.categories}
            options={userFilters.categories}
            setFilters={updateUserFilters}
            title={"Categories"}
          />
          <SelectDropdown
            filters={selectedUserFilters.styles}
            options={userFilters.styles}
            setFilters={updateUserFilters}
            title={"Styles"}
          />
          <SelectDropdown
            filters={selectedUserFilters.status}
            options={userFilters.status}
            setFilters={updateUserFilters}
            title={"Status"}
          />
          {/* <UserFilters handleFiltersChange={handleFiltersChange} /> */}
        </Modal.Body>
        <Modal.Footer>
          <ArtButton
            buttonText={"Cancel"}
            styleclass={"w-120"}
            variant={"light"}
            icon={""}
            hasIcon={false}
            onClick={handleClose}
          />
          <ArtButton
            buttonText={"Apply Filters"}
            styleclass={""}
            variant={"dark"}
            icon={undefined}
            hasIcon={false}
            onClick={() => {
              handleFiltersChange(selectedUserFilters);
              setShow(false);
            }}
          />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export interface userFilterType {
  categories: Option[];
  styles: Option[];
  status: Option[];
}
export interface filterData {
  value: string;
  key: number;
}
interface propType {
  handleFiltersChange: Function;
  selectedUserFilters: any;
  userFilters: userFilterType;
  updateUserFilters: Function;
}
