import React, { useEffect, useState } from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
type propType = {
  priceData: any;
  setPrice: Function;
};
export const PriceRange = ({ priceData, setPrice }: propType) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<number[]>([]);
  var rangeValues: any = [];
  const [value, setValue] = useState(
    priceData?.price ? parseInt(priceData?.price) : min
  );
  useEffect(() => {
    setValue(parseInt(priceData?.price));
    // setImagesData(priceData?.options);
    rangeValues = priceData.surveyOptions?.map(
      (item: { price: any }) => item.price
    );
    rangeValues = [...new Set(rangeValues)];
    rangeValues.splice(rangeValues.indexOf(-1), 1);
    setValues(rangeValues);
    setMin(values[0]);
    setMax(values[values.length - 1]);
    setStep(values[1] - values[0]);
    setValue(priceData?.price ? parseInt(priceData?.price) : min);
  }, []);

  useEffect(() => {
    setMin(values[0]);
    setMax(values[values.length - 1]);
    setStep(values[1] - values[0]);
    setValue(priceData?.price ? parseInt(priceData?.price) : min);
  }, [values]);

  let rangeVal;

  const rangeMaps = ["5000", "10000", "25000", "50000", "100000", "-1"];
  function getSliderVal() {
    var val = 0;
    for (var i = 0; i < 6; i++) {
      if (priceData?.price === parseInt(rangeMaps[i])) {
        val = i;
      }
    }
    return val;
  }

  function getSliderText(txt: number) {
    return txt == -1 ? "No Limit" : txt.toLocaleString('en');
  }

  return (
    <div className="mb-32">
      <h2 className="fs-2 fw-bold ">{priceData.question}</h2>
      <p className="ms-1">{priceData.questionDescription}</p>
      <div className="slider-pos">
        <RangeSlider
          step={1}
          max={5}
          min={0}
          tooltip='on'
          tooltipPlacement="top"
          variant="dark"
          // value={value}
          value={getSliderVal()}
          tooltipLabel={currentValue => `${getSliderText(parseInt(priceData?.price))}`}
          onChange={(changeEvent, val) => {
            setValue(val);
            rangeVal = val == 5 ? -1 : parseInt(rangeMaps[val]);
            setPrice(rangeVal);
            
            // setValue(parseInt(changeEvent.target.value));
            // setPrice(value);
          }}
        />
      </div>
    </div>
  );
};
