import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import artwork from '../../../../../assets/Artwork/art3.png';
import user from '../../../../../assets/Artwork/user.png';
import { rowData, columns } from './QueueData';
import axios from 'axios';
import { GET_ARTWORK_RESERVATION_WAITLIST } from '../../../../../constants';
// import ListTable from './WaitListTable';
import { Spinner } from 'react-bootstrap-v5';
import TableList from './WaitQueue';
import { reservationDataType } from '../ReservationDetails';

type propType = {
  artworkData: reservationDataType;
  setShowAlert: Function;
  setAlertData: Function;
};
export const QueueTable = ({
  artworkData,
  setAlertData,
  setShowAlert,
}: propType) => {
  const [reservationData, setReservationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (artworkData.artworkId) {
      axios({
        method: 'POST',
        url: GET_ARTWORK_RESERVATION_WAITLIST,
        data: {
          pageInfo: {
            pageSize: 100,
            pageNumber: 1,
          },
          filters: {
            waitListArtworkId: artworkData.artworkId,
          },
        },
      }).then(({ data }) => {
        setReservationData(
          data.data?.map((item: any) => {
            return { ...item, id: item.queueNumber };
          })
        );
        setIsLoading(false);
      });
    }
  }, []);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <div className="card">
        <div className="card-header border-1 bg-white mb-5 align-items-center">
          <div className="card-title card-title align-items-start flex-column">
            <h3 className="fw-bolder m-0">Queue</h3>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <TableList
              data={reservationData}
              setAlertData={setAlertData}
              setShowAlert={setShowAlert}
            />
            {/* columns={columns} userData={rowData(reservationData)} /> */}
          </div>
        </div>
      </div>
    );
  }
};
